import { Feather } from '@expo/vector-icons'
import { StyleSheet, View, Text, TextInput, Button, TouchableOpacity, TouchableWithoutFeedbackComponent } from 'react-native'
import { colors } from '../../config/globalStyle'
import { Spacer } from './Spacer'
export const InputField = ({icon, placeholder, inputState, setInputState, passwordEntry=false, keyboardType="default"}) => {
    return (
      <View style={{
        flexDirection: 'row', 
        elevation: 3,
        width: '80%', 
        padding: 9, 
        alignItems: 'center', 
        backgroundColor: 'white', 
        borderRadius: 7, 
        shadowColor: 'black', 
        shadowOpacity: 0.3, 
        shadowOffset: {width: 0, height: 1}, 
        shadowRadius: 3, 
        }}>
      <Feather name={icon} size={20} color={colors.grey} style={{marginTop: 0}}/>
      {
      icon ? <Spacer width={15}/> : <></>
      }
      <TextInput value={inputState} onChangeText={setInputState} style={styles.textInput} placeholder={placeholder} placeholderTextColor="rgba(0,0,0,0.3)" secureTextEntry={passwordEntry} keyboardType={keyboardType}/>
    </View>
    )
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: 'rgba(0,0,0,1)',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 16,
      height: 30,
      flex: 1
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    }
  });
  