import {View, Text} from 'react-native'
import { useEffect, useState } from 'react'

import { Spacer } from './Spacer'

export const Circle = ({color, radius, styles}) => {
    return (
      <View style={[{backgroundColor: color, borderRadius: 300, width: radius * 2, height: radius * 2}, styles]}>

      </View>
    )
  }
export const TempCircle = ({small=false, label, tempFahr=-1, tempCels=-1, color="grey"}) => {
    const [tempF, setTempF] = useState(0)
    const [tempC, setTempC] = useState(0)
    const [clr, setColor] = useState('grey')

    useEffect(() => {
       
      if (tempFahr !== -1) {
        let tempCelsius = Math.floor(((tempFahr - 32) * 5) / 9)
        
        setTempC(tempCelsius)
        setTempF(tempFahr)
      } else if (tempCels !== -1) {
        let tempFahrenheit = Math.floor(((9 * tempCels) / 5) + 32)
        setTempF(tempFahrenheit)
        setTempC(tempCels)
      }

  
    }, [])

    return (
      <View style={{ flex: 1, alignItems: 'center'}}>
      <View style={{alignItems: 'center', justifyContent: 'center'}}>
        <Circle color={color} radius={small ? 40 : 50}/>
        <Circle color={"white"} radius={small ? 37 : 45} styles={{position: 'absolute'}}/>
        <View style={{position: 'absolute', alignItems: 'center'}}>
          <Text style={{padding: 5, fontSize: 15}}>{tempC == 0 ? "—" : tempC}°C</Text>
          <View style={{height: 1, width: '100%', backgroundColor: '#c2c2c2'}}></View>
          <Text style={{padding: 5, fontSize: 15}}>{tempC == 0 ? "—" : tempF}°F</Text>
        </View>
      </View>
      <Spacer height={7}/>
      <Text style={{color: tempC == 0 ? "#c2c2c2" : "black"}}>{label}</Text>
      </View>
    )
  }