import { useState } from "react"
import { Text, View,} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";
import RangeSlider from "../../../atoms/RangeSlider";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";
import { colors } from "../../../../config/globalStyle";




export const SpectrumSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [spectrumWindowSlider, setSpectrumSlider] = useState()
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
   
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    console.log(settings)
    
    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            if (spectrumWindowSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SpectrumNoise.Lower`] = spectrumWindowSlider.min 
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SpectrumNoise.Upper`] = spectrumWindowSlider.max
            }

            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{paddingBottom: 10}}>
            <RangeSlider min={0} max={200} step={1} onValueChange={setSpectrumSlider} units="" label="Spectrum Window Size (MHz)" defaultValue={{min: (settings.SpectrumNoise.Lower) / 200, max: (settings.SpectrumNoise.Upper) / 200}} colors={["grey", "blue", "grey"]} thumb2mirror={true}/>

            </View>
        </View>
    )
}