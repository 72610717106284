import { useState } from "react"
import { Text, View,} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";

import RangeSlider from "../../../atoms/RangeSlider";
import { Spacer } from "../../../atoms/Spacer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";


export const SigQSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [MERSlider, setMERSlider] = useState()
    const [CNRSlider, setCNRSlider] = useState()
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)

    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    const [merSliderAlarmMajor, setmerSliderAlarmMajor] = useState(settings.SIGQMER.MajorAlarm);
    const [merSliderAlarmMinor, setmerSliderAlarmMinor] = useState(settings.SIGQMER.MinorAlarm);
    const [cnrSliderAlarmMajor, setcnrSliderAlarmMajor] = useState(settings.SIGQCNiR.MajorAlarm);
    const [cnrSliderAlarmMinor, setcnrSliderAlarmMinor] = useState(settings.SIGQCNiR.MinorAlarm);

    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            if (MERSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SIGQMER.Lower`] = MERSlider.min
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SIGQMER.Upper`] = MERSlider.max
            }

            if (CNRSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SIGQCNiR.Lower`] = CNRSlider.min
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SIGQCNiR.Upper`] = CNRSlider.max
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SIGQMER.MajorAlarm`] = merSliderAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SIGQMER.MinorAlarm`] = merSliderAlarmMinor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SIGQCNiR.MajorAlarm`] = cnrSliderAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SIGQCNiR.MinorAlarm`] = cnrSliderAlarmMinor

            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{paddingBottom: 10}}>

            <RangeSlider min={15} max={50} step={1} onValueChange={setMERSlider} units="" label="MER" defaultValue={{min: (settings.SIGQMER.Lower - 15) / 35, max: (settings.SIGQMER.Upper - 15) / 35}} colors={["red", "orange", "green"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={merSliderAlarmMajor} onValueChange={(val) => setmerSliderAlarmMajor(val)} style={{borderWidth: 1, borderColor: 'orange', backgroundColor: 'white'}}/>
            <ToggleLabel title="Major Alarm" value={merSliderAlarmMinor} onValueChange={(val) => setmerSliderAlarmMinor(val)} style={{borderWidth: 1, borderColor: 'red', backgroundColor: 'white'}}/>
            </View>
            <Spacer height={15}/>
            </View>

            <RangeSlider min={15} max={50} step={1} onValueChange={setCNRSlider} units="" label="CNR" defaultValue={{min: (settings.SIGQCNiR.Lower - 15) / 35, max: (settings.SIGQCNiR.Upper - 15) / 35}} colors={["red", "orange", "green"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={cnrSliderAlarmMajor} onValueChange={(val) => setcnrSliderAlarmMajor(val)} style={{borderWidth: 1, borderColor: 'orange', backgroundColor: 'white'}}/>
            <ToggleLabel title="Major Alarm" value={cnrSliderAlarmMinor} onValueChange={(val) => setcnrSliderAlarmMinor(val)} style={{borderWidth: 1, borderColor: 'red', backgroundColor: 'white'}}/>

            </View>

        </View>
    )
}
