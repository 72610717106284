import { StyleSheet, View } from "react-native"
import { colors } from "../../config/globalStyle"

export const ProgressBar = ({percent = 0, disabled=false, color='grey'}) => {
    if (disabled) return <View style={[styles.container, {backgroundColor: 'grey', opacity: 0.5}]}></View>
    return (
        <View style={[styles.container, {backgroundColor: colors[color] + '33'}]}>
            <View style={[styles.foreground, {flex: percent, backgroundColor: colors[color],}]}>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: 15,
        flexDirection: 'row',
        borderRadius: 15,
        overflow: 'hidden',
    },
    background: {

    },
    foreground: {
        backgroundColor: 'green',
        borderRadius: 15,
    }
})