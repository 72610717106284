import { useState } from "react"
import { Text, View} from "react-native"
import { Switch } from 'react-native-paper';
import ToggleLabel from "../../../atoms/ToggleLabel";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import hardware from "../../../../config/hardware";
import { UpdateSiteSettings } from "../../../../Firebase";
import { mergeDeep } from "../../../../Utilities";

export const HardwareStatusSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()
    
    const settings = sectionData.settings
    
    const [StateAlarm, setStateAlarm] = useState(settings.StateAlarm);
    const [uptimeAlarm, setUptimeAlarm] = useState(settings.UptimeAlarm);

    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.StateAlarm`] = StateAlarm
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.UptimeAlarm`] = uptimeAlarm
            
            if (Object.keys(obj).length > 0) UpdateSiteSettings({id: sectionData.lincrID, setObj: obj})
        }
    }, [settingsSaved])
    return (
        <View>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <ToggleLabel title="When this device is not reachable" value={StateAlarm} onValueChange={(val) => setStateAlarm(val)} />
            <ToggleLabel title="When the uptime resets" value={uptimeAlarm} onValueChange={(val) => setUptimeAlarm(val)} />
        </View>
    )
}