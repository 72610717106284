import { View, Text, TouchableOpacity, KeyboardAvoidingView } from "react-native";
import { Btn } from "../components/atoms/Btn";
import { TWLogo } from "../components/atoms/TelaWatchLogo";
import {Spacer} from '../components/atoms/Spacer'
import { useSelector } from "react-redux";
import { ReloadUser, SendPasswordReset } from "../Firebase";
import { useState } from "react";
import { colors } from "../config/globalStyle";
import { TextEntry } from "../components/atoms/TextEntry";
import {FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons'

export function PasswordReset({ navigation, setSignedIn }) {
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const behavior = Platform.OS == 'android' ? "" : "padding"
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
             
            <KeyboardAvoidingView behavior={behavior} style={{flex: 1, width: '80%', justifyContent: 'center', alignItems: 'center'}}>
           
            <TWLogo size={30} style={{paddingBottom: 70}}/>

            <TextEntry icon="mail" placeholder={"Email"} inputState={email} onChangeText={setEmail}/>
            <Spacer height={10}/>
            <Btn label="Send Password Reset" fillWidth={true} onPress={async () => {
                let msg = await SendPasswordReset(email)

                setMessage(msg)
            }}/>
            <Text style={{width: '100%', padding: 3}}>{message}</Text>
            </KeyboardAvoidingView>

            <TouchableOpacity onPress={() => { navigation.navigate("Login") }} style={{ position: 'absolute', top: 10, left: 10, padding: 7, flexDirection: 'row', alignItems: 'center', borderRadius: 7, borderWidth: 1, borderColor: '#eeeeee' }}>
                <FontAwesome name="chevron-left" size={17} color="rgba(0,0,0,0.5)" />
                <Spacer width={10} />
                <Text>Back</Text>
            </TouchableOpacity>
        </View>
    );
  }