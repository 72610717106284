import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import {FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons'
import { colors } from "../../../../config/globalStyle"


const UtilRow = ({entry, settings}) => {
    let color = entry.avgUtil >= settings.PortUtil.Upper * 100 ? "rgba(255,0,0,0.3)" : entry.avgUtil >= settings.PortUtil.Lower * 100 ? colors.orange : colors.green
    return <View style={{borderRadius: 5, flexDirection: 'row', shadowColor: 'black', shadowOffset: {width: 0, height: 1}, shadowOpacity: 0.3, backgroundColor: 'white', padding: 5, margin: 5, paddingVertical: 13}}>
      <Text style={{flex: 1, textAlign: 'center'}}>{entry.card}/{entry.port}</Text>
      <Text style={{flex: 1, textAlign: 'center'}}>{entry.minFreq}-{entry.maxFreq}</Text>
      <Text style={{flex: 1, textAlign: 'center'}}>{Math.round(entry.totalBW)}</Text>
      <Text style={{flex: 1, textAlign: 'center', backgroundColor: color, borderRadius: 5, overflow: 'hidden'}}>{Math.round(entry.avgUtil)}%</Text>
      <Text style={{flex: 1, textAlign: 'center'}}>{Math.round(entry.maxUtil)}%</Text>
    </View>
  }

  
export const USDSModal = ({dismiss, usds, data, settings}) => {

  return <>
  <View style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.2)'}} onStartShouldSetResponder={() => dismiss("")}>
  
  </View>
  <View  behavior="position" style={{ position: 'absolute', bottom: 0, width: '100%' }} shadowOffset={{width: 0, height: -1}} shadowOpacity={0.3} shadowColor="black">
      <View style={{flex: 1, width: '100%', backgroundColor: '#ffffff', minHeight: 150, maxHeight: 500, paddingHorizontal: 10, paddingVertical: 5, paddingBottom:20, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
  <View style={{flexDirection: 'row', alignItems: 'center', width: '100%', borderBottomWidth: 1, borderColor: '#c2c2c2', marginBottom: 10, paddingBottom: 2, marginTop: 3}} >
    <Text style={{flex: 1, fontSize: 17, fontWeight: '500', textAlign: 'center', marginLeft: 0, paddingBottom: 3}}>{usds} Utilization</Text>
    <TouchableOpacity onPress={() => { dismiss("") }} style={{position: 'absolute', right: 7, top: 0}}>
      <MaterialCommunityIcons name="window-close" size={21} color="black" />
    </TouchableOpacity>

    </View>
    

    <ScrollView>
      <View style={{flexDirection: 'row', padding: 5}}>
      <Text style={{flex: 1, textAlign: 'center'}}>DS Ports</Text>
      <Text style={{flex: 1, textAlign: 'center'}}>Freq (Mhz)</Text>
      <Text style={{flex: 1, textAlign: 'center'}}>Total        BW</Text>
      <Text style={{flex: 1, textAlign: 'center'}}>Avg %</Text>
      <Text style={{flex: 1, textAlign: 'center'}}>Max %</Text>
      </View>
      {
        data[usds.toLowerCase()].map((entry, i) => {
          return <UtilRow entry={entry} key={"usds " + i} settings={settings}/>
        })
      }
    </ScrollView>

      </View>
  </View>
  </>
}