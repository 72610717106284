import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"

import { Spacer } from "../../../atoms/Spacer"
import { ActiveModems } from "../../../molecules/ActiveModems"
import { TapabbleFan } from "../../../atoms/TappableFan"
import {FontAwesome} from '@expo/vector-icons'
import { Btn } from "../../../atoms/Btn"

export const PortUtilSection = ({setUtilModalVisible}) => {
    return <View style={{ flexDirection: 'row', justifyContent: 'center', padding: 7}}>
    <Btn label={"Downstream"} onPress={() => { setUtilModalVisible("DS") }}/>
    <Spacer width={15} />
    <Btn label={"Upstream"} onPress={() => { setUtilModalVisible("US") }}/>
  </View>
  }