import { StyleSheet, View, TouchableOpacity, Text, ScrollView, Animated} from 'react-native'
import { useState, useRef, useEffect } from 'react'
import { FontAwesome } from '@expo/vector-icons'
import { colors } from '../../config/globalStyle'


export const DropdownList = ({ items, selected, onItemSelect }) => {
  const [pressed, press] = useState(false)

  return <View style={{ width: 'auto', borderWidth: 0, borderRadius: 7, borderColor: '#cccccc', backgroundColor: 'white'}} elevation={3} shadowOpacity={0.2} shadowColor={"black"} shadowOffset={{width: 0, height: 1}}>
    <TouchableOpacity style={[{ padding: 7, alignItems: 'center', justifyContent: 'center', alignItems: 'center' }, styles.horiz]} onPress={() => press(!pressed)}>
      {
        !pressed ? <>
          <Text style={{ fontSize: 14 }}>{selected}</Text>
        </> : <Text>-</Text>
      }
      <FontAwesome name='chevron-down' size={10} color="grey" style={{ position: 'absolute', right: 10, transform: [{ rotate: pressed ? "180deg" : "0deg" }] }} />

    </TouchableOpacity>
    <ScrollView style={{ maxHeight: pressed ? 300 : 0, backgroundColor: 'rgba(255,255,255,1)', }}>
      {
        pressed ? items.map((dropdownItem, index) => {
          return <TouchableOpacity key={index} onPress={() => { onItemSelect(dropdownItem); press(false) }} style={[{
            padding: 7,
            alignItems: 'center',
            backgroundColor: selected == dropdownItem ? "rgba(139, 200, 247, 0.1)" : "rgba(0,0,0,0)",
            justifyContent: 'center'
          }, styles.horiz]}>
            <Text style={{fontSize: 13}}>{dropdownItem}</Text>
          </TouchableOpacity>
        }) : <></>
      }
    </ScrollView>
  </View>
}

export const Dropdown = ({ items, selected, onItemSelect }) => {
  const [pressed, press] = useState(false)

  useEffect(() => {
    let selectedInItems = items.filter(x => x.DisplayName == selected.DisplayName)[0]
    if (selectedInItems) {
    if (selectedInItems.DisplayName == selected.DisplayName) {
    if (JSON.stringify(selected) !== JSON.stringify(selectedInItems)) {
      onItemSelect(selectedInItems)
    }
  }
}
  }, [items])

    return <View style={{flex: 1, borderWidth: 1, borderRadius: 7, borderColor: '#cccccc', overflow: 'hidden' }}>
      <TouchableOpacity style={[{ padding: 7, alignItems: 'center', justifyContent: 'center', alignItems: 'center' }, styles.horiz]} onPress={() => {press(!pressed);}}>
        {
          !pressed ? <>
            <FontAwesome name='circle' color={colors[selected.color]} size={16} style={{ position: 'absolute', left: 10 }} />
            <Text style={{}}>{selected.DisplayName}</Text>
          </> : <Text>-</Text>
        }
        <FontAwesome name='chevron-down' size={10} color="grey" style={{ position: 'absolute', right: 10, transform: [{ rotate: pressed ? "180deg" : "0deg" }] }} />
  
      </TouchableOpacity>
      <ScrollView style={{ maxHeight: pressed ? 300 : 0, backgroundColor: 'rgba(255,255,255,1)', }}>
        {
          pressed ? items.map((dropdownItem, index) => {
            return <TouchableOpacity key={index} onPress={() => { onItemSelect(dropdownItem); press(false) }} style={[{
              padding: 7,
              alignItems: 'center',
              backgroundColor: selected.DisplayName == dropdownItem.DisplayName ? "rgba(139, 200, 247, 0.1)" : "rgba(0,0,0,0)",
              justifyContent: 'center'
            }, styles.horiz]}>
              <FontAwesome name='circle' color={colors[dropdownItem.color]} size={16} style={{ position: 'absolute', left: 10 }} />
              <Text style={{fontSize: 13}}>{dropdownItem.DisplayName}</Text>
            </TouchableOpacity>
          }) : <></>
        }
      </ScrollView>
    </View>
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: '#000',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 18,
      lineHeight: 23,
      flex: 2
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    }
  });
  