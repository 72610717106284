
import { Text, TouchableOpacity, StyleSheet, View } from "react-native"
import { colors } from "../../config/globalStyle"

export const TWLogo = ({size, style}) => {
    return <View style={[{flexDirection: 'row'}, style]}>
        <Text style={{fontSize: size}}>Tela</Text>
        <Text style={{fontSize: size, color: colors.red}}>Watch</Text>
    </View>
}

const styles = StyleSheet.create({
})