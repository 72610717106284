import { View, Text, Image, TouchableOpacity } from "react-native"
import { Section } from "../../molecules/Section"
import { OverviewSection } from "../../molecules/OverviewSection"

import { TempCircle } from "../../atoms/TempCircle"
import {useState} from 'react'
import { Spacer } from "../../atoms/Spacer"
import { useSelector } from "react-redux"

import hardware from "../../../config/hardware"
import {FontAwesome} from '@expo/vector-icons'
import WebViewModal from "../../../screens/WebViewModalBandwidth"
import { colors } from "../../../config/globalStyle"
import { mergeDeep } from "../../../Utilities"

import ws_logger from '../../../logger'

const BandwidthRow = ({portnum, name, active, inBand, outBand, selected, select}) => {
    
    const Label = (props) => {
        return <View style={[props.style, {flexDirection: 'row', alignItems: 'center'}]}>
            <Text style={{color: '#616161', fontSize: 13}}>{props.label}</Text>
            <Spacer width={3}/>
            <Text style={{fontSize: 14, color: props.color ?? 'black'}}>{props.val}</Text>
        </View>
    }
    return (
        <>
        <TouchableOpacity style={{flexDirection: 'row', borderRadius: 7, borderWidth: 1, padding: 5, alignItems: 'center', borderColor: '#c3c3c3'}} onPress={select}>
            <View style={{flex: 1}}>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{flex: 1}}>Port {portnum}</Text>
                    
                    <Text style={{flex: 2}}>{name}</Text>
                </View>
                <Spacer height={5} />
                

                <View style={{flexDirection: 'row'}}>
                    <Label label={"Total"} val={Math.round(inBand + outBand)} style={{flex: 3}}/>
                    <Label label={"In"} val={Math.round(inBand)} color={'green'} style={{flex: 3}}/>
                    <Label label={"Out"} val={Math.round(outBand)} color={'blue'} style={{flex: 3}}/>
                </View>
            </View>

            <FontAwesome name={"bar-chart"} color={"black"} size={20} style={{paddingRight: 5}}/>
        </TouchableOpacity>
        <Spacer height={5} />
        </>
    )
}


export const MikrotikDisplay = ({metrics, data, lincrID, selectedOrder}) => {
    const user = useSelector((state) => state.user.user)
    const settings = metrics[user.uid] ? mergeDeep({}, hardware.Mikrotik.defaultSettings, metrics[user.uid]) : hardware.Mikrotik.defaultSettings
    const CtoF = (celsius) => {
        return ((celsius * 9) / 5) + 32
    }

    const splitFirmModel = (str) => {
        try {
        let spli = str.split(' on ')
        let firm = spli[0]
        let model = spli[1]
        return [firm, model]
    } catch (e) {
        return [str, ""]
    }
       
    }

    const [viewModal, setViewModal] = useState(false)

    const [selectedBandwidth, selectBandwidth] = useState("")
    const [selectedPort, selectPort] = useState(0)

    const Sections = {
        "Hardware Status": <Section title="Hardware Status" settings={settings} lincrID={lincrID}>
            <OverviewSection serial={metrics.serial} uptime={metrics.uptime} status={data.Active} type={data.Type} />
        </Section>,
        "Temperature": <Section title="Temperature" settings={settings} lincrID={lincrID}>
            <TempCircle label="Board Temperature" tempCels={parseInt(metrics.temperature ?? 0) / 10} color={CtoF(parseInt(metrics.temperature) / 10) > settings.BoardTemp.Upper ? colors.red : CtoF(parseInt(metrics.temperature) / 10) > settings.BoardTemp.Lower ? colors.orange : colors.green} />
        </Section>,
        "Device Info & Statistics": <Section title="Device Info & Statistics" hideSettings={true} settings={settings} lincrID={lincrID}>
            <View style={{flexDirection: 'row', alignItems: 'center', paddingVertical: 5, borderBottomWidth: 1, borderColor: colors.lightGrey}}>
                <Text style={{ flex: 1 }}>Voltage</Text>
                <Text style={{ flex: 2 }}>{metrics.voltage ? parseInt(metrics.voltage) / 10 : "—"} V</Text>
            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5, borderBottomWidth: 1, borderColor: colors.lightGrey }}>
                <Text style={{ flex: 1 }}>Current</Text>
                <Text style={{ flex: 2 }}>{metrics.current ? Math.round(metrics.current / 10) / 10 : "—"} A</Text>
            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5, borderBottomWidth: 1, borderColor: colors.lightGrey }}>
                <Text style={{ flex: 1 }}>Power</Text>
                <Text style={{ flex: 2 }}>{metrics.power ? metrics.power : "—"} W</Text>
            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5, borderBottomWidth: 1, borderColor: colors.lightGrey }}>
                <Text style={{ flex: 1 }}>Processor Frequency</Text>
                <Text style={{ flex: 2 }}>{metrics.cpuFreq}Hz</Text>

            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5, borderBottomWidth: 1, borderColor: colors.lightGrey }}>
                <Text style={{ flex: 1 }}>Firmware</Text>
                <Text style={{ flex: 2 }}>{splitFirmModel(metrics.firm_model)[0]}</Text>
            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5 }}>
                <Text style={{ flex: 1 }}>Model</Text>
                <Text style={{ flex: 2 }}>{splitFirmModel(metrics.firm_model)[1]}</Text>
            </View>
            
        </Section>,
        "Bandwidth in Mbps [5 min avg]": <Section title="Bandwidth in Mbps [5 min avg]" hideSettings={true} settings={settings} lincrID={lincrID}>
            <View style={{ paddingVertical: 10 }}>
                {
                    metrics.bandwidth ? metrics.bandwidth.map(val => { return { name: val.name, active: val.active, port: val.port, log: val.log[val.log.length - 1] } }).filter(val => val.active && (val.log.recieve > 0 || val.log.transmit > 0)).map(val => {
                        return <BandwidthRow key={val.port} portnum={val.port} name={val.name} active={val.active} inBand={val.log.recieve} outBand={val.log.transmit} select={() => {
                            ws_logger.log("Open Modal", {item: "Mikrotik Bandwidth", id: {port: val.port, name: val.name}})
                            selectBandwidth(val.name)
                            selectPort(val.port)
                            setViewModal(true);
                        }} selected={selectedBandwidth} />
                    }) : metrics.dailyBandwidth ? metrics.dailyBandwidth.map(val => { return { name: val.name, active: val.active, port: val.port, log: val.log[val.log.length - 1] } }).filter(val => val.active && (val.log.recieve > 0 || val.log.transmit > 0)).map(val => {
                        return <BandwidthRow key={val.port} portnum={val.port} name={val.name} active={val.active} inBand={val.log.recieve} outBand={val.log.transmit} select={() => {
                            ws_logger.log("Open Modal", {item: "Mikrotik Bandwidth", id: {port: val.port, name: val.name}})
                            selectBandwidth(val.name)
                            selectPort(val.port)
                            setViewModal(true);
                        }} selected={selectedBandwidth} />
                    }) : <Text style={{ flex: 1, textAlign: 'center' }}>No Data</Text>
                }
{
viewModal ? <WebViewModal onClose={() => {
    setViewModal(false)
    ws_logger.log("Close Modal", {item: "Mikrotik Bandwidth", id: selectedPort})
}} lincrID={lincrID} deviceName={data.DisplayName} portNumber={selectedPort} visible={true}/>  : <></>
}
</View>
</Section>

    }

 
    if (Object.keys(metrics).length == 0) return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
    <Text>No data reported by Lincr</Text>
  </View>
  
    return (
        <View style={{}}>
           {
            selectedOrder ? selectedOrder.map((section) => {
                return Sections[section]
            }) : Object.keys(Sections).map((section) => Sections[section])
           }
        </View>
    )
}