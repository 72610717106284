import {StyleSheet, View, TextInput, Text, Dimensions} from 'react-native';
import React from 'react';
import {PanGestureHandler, gestureHandlerRootHOC} from 'react-native-gesture-handler';

import * as gstyle from '../../config/globalStyle';


import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedGestureHandler,
  useAnimatedProps,
  runOnJS,
} from 'react-native-reanimated';


const RangeSlider = gestureHandlerRootHOC(({sliderWidth = Dimensions.get('window').width * 0.8, min, max, step, onValueChange, units, label, defaultValue={min: 0.5, max: 0.75}, colors=["red", "orange", "green"], thumb2mirror=false}) => {

  const position = useSharedValue(sliderWidth * defaultValue.min);
  const position2 = useSharedValue(sliderWidth * defaultValue.max);
  const opacity = useSharedValue(0);
  const opacity2 = useSharedValue(0);
  const zIndex = useSharedValue(0);
  const zIndex2 = useSharedValue(0);

  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.startX = position.value;
    },
    onActive: (e, ctx) => {
      opacity.value = 1;
      if (ctx.startX + e.translationX < 0) {
        position.value = 0;
      } else if (ctx.startX + e.translationX > position2.value - 10) {
        position.value = position2.value - 10;
        zIndex.value = 1;
        zIndex2.value = 0;
      } else {
        position.value = ctx.startX + e.translationX;
      }
    },
    onFinish: () => {
      runOnJS(onValueChange)({
        min:
          min +
          Math.floor(position.value / (sliderWidth / ((max - min) / step))) *
            step,
        max:
          min +
          Math.floor(position2.value / (sliderWidth / ((max - min) / step))) *
            step,
      });
    },
  });

  const gestureHandler2 = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.startX = position2.value;
    },
    onActive: (e, ctx) => {
      opacity2.value = 1;
      if (ctx.startX + e.translationX > sliderWidth) {
        position2.value = sliderWidth;
      } else if (ctx.startX + e.translationX < position.value + 10) {
        position2.value = position2.value;
        zIndex.value = 0;
        zIndex2.value = 1;
      } else {
        position2.value = ctx.startX + e.translationX;
      }
    },
    onFinish: () => {
      runOnJS(onValueChange)({
        min:
          min +
          Math.floor(position.value / (sliderWidth / ((max - min) / step))) *
            step,
        max:
          min +
          Math.floor(position2.value / (sliderWidth / ((max - min) / step))) *
            step,
      });
    },
  });

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{translateX: position.value}],
    zIndex: zIndex.value,
  }));

  const animatedStyle2 = useAnimatedStyle(() => ({
    transform: [{translateX: position2.value}],
    zIndex: zIndex2.value,
  }));

  const leftSliderStyle = useAnimatedStyle(() => ({
    width: position.value,
  }));

  const centerSliderStyle = useAnimatedStyle(() => ({
    transform: [{translateX: position.value}],
    width: position2.value - position.value,
  }));

  const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);
  const minLabelText = useAnimatedProps(() => {
    return {
      text: `${
        min +
        Math.round(position.value / (sliderWidth / ((max - min) / step))) * step
      }${units}`,
    };
  });
  const maxLabelText = useAnimatedProps(() => {
    return {
      text: `${
        min +
        Math.round(position2.value / (sliderWidth / ((max - min) / step))) *
          step
      }${units}`,
    };
  });


  return (
    <View style={{elevation: 3, marginHorizontal: 3, height: 140, justifyContent: 'space-between', paddingBottom: 20, backgroundColor: 'white', marginTop: 7, marginBottom: 7, borderRadius: 7}} shadowOffset={{width: 0, height: 2}} shadowColor="black" shadowOpacity={0.2} shadowRadius={5}>
        <Text style={{fontSize: 16, padding: 7}}>{label}</Text>
    <View style={[styles.sliderContainer, {width: sliderWidth + 20}]}>
      <Animated.View style={[styles.sliderRight, {backgroundColor: gstyle.colors[colors[2]]}]} />
      <Animated.View style={[centerSliderStyle, styles.sliderCenter, {backgroundColor: gstyle.colors[colors[1]]}]} />
      <Animated.View style={[leftSliderStyle, styles.sliderLeft, {backgroundColor: gstyle.colors[colors[0]]}]} />

      <PanGestureHandler onGestureEvent={gestureHandler}>
        <Animated.View style={[animatedStyle, styles.thumbLeft, {backgroundColor: gstyle.colors[colors[1]]}]}>
          <View style={[styles.label]}>
            <AnimatedTextInput
              style={styles.labelText}
              animatedProps={minLabelText}
              editable={false}
              defaultValue={`${
                min +
                Math.round(position.value / (sliderWidth / ((max - min) / step))) * step
              }${units}`}
            />
          </View>
        </Animated.View>
      </PanGestureHandler>
      <PanGestureHandler onGestureEvent={gestureHandler2}>
        <Animated.View style={[animatedStyle2, styles.thumbRight, {backgroundColor: gstyle.colors[colors[thumb2mirror ? 1 : 2]]}]}>
          <View style={[styles.labelLower]}>
            <AnimatedTextInput
              style={styles.labelText}
              animatedProps={maxLabelText}
              editable={false}
              defaultValue={`${
                min +
                Math.round(position2.value / (sliderWidth / ((max - min) / step))) * step
              }${units}`}
            />
          </View>
        </Animated.View>
      </PanGestureHandler>
    </View>
    </View>
  );
})

export default RangeSlider;

const styles = StyleSheet.create({
  sliderContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
  sliderRight: {
    height: 8,
    borderRadius: 20,
  },
  sliderCenter: {
    height: 8,
    borderRadius: 20,
    position: 'absolute',
  },
  sliderLeft: {
    height: 8,
    borderRadius: 20,
    position: 'absolute',
  },
  thumbLeft: {
    left: -10,
    width: 30,
    height: 30,
    position: 'absolute',
    backgroundColor: 'yellow',
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 2,
    shadowOpacity: 0.3,
    shadowColor: 'black',
    borderRadius: 40,
  },
  thumbRight: {
    left: -10,
    width: 30,
    height: 30,
    position: 'absolute',
    backgroundColor: 'red',
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 2,
    shadowOpacity: 0.3,
    shadowColor: 'black',
    borderRadius: 40,
  },
  label: {
    position: 'absolute',
    top: -65,
    bottom: 25,
    backgroundColor: '#c2c2c2',
    borderRadius: 5,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    marginBottom: 40,
  },
  labelLower: {
    position: 'absolute',
    top: -35,
    bottom: 20,
    backgroundColor: '#c2c2c2',
    borderRadius: 5,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
  },
  labelText: {
    color: 'black',
    textAlign: 'center',
    padding: 5,
    fontWeight: 'bold',
    fontSize: 16,
    width:  60,
  },
});