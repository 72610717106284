import UBRSectionSettings from '../components/Devices/UBR10012/SectionSettings'
import UBR7246SectionSettings from '../components/Devices/UBR7246VXR/SectionSettings'
import RFGWSectionSettings from '../components/Devices/RFGW1/SectionSettings'
import TCSectionSettings from '../components/Devices/TC1000/SectionSettings'
import MikrotikSectionSettings from '../components/Devices/Mikrotik/SectionSettings'
import C40GSettings from '../components/Devices/C40G/SectionSettings'
import C100GSettings from '../components/Devices/C100G/SectionSettings'


const ubr10012image = require('../../assets/UBR10k.png')
const tcImage = require('../../assets/TC1000.png')
const rfgw1Image = require('../../assets/RFGW-1.png')
const mikrotikImage = require('../../assets/Mikrotik.png')
const c40image = require('../../assets/C40G.png')
const ubr7246image = require('../../assets/ubr7246vxr.png')
const c100gimage = require('../../assets/casa.png')
const c10gimage = require('../../assets/C10G.png')

import { mergeDeep } from '../Utilities'


const removeSpectrum = (obj) => {
    let newObj = Object.assign({}, obj)
    if (Object.keys(obj).includes("spectrum")) { delete newObj.spectrum; return newObj}
    else return undefined
    
    }

const getSGModems = (obj) => {
    let newObj = Object.assign({}, obj)
    let sgs = []
    for (let c of Object.keys(newObj)) {
      for (let p of Object.keys(newObj[c])) {
        let rmspecObj = removeSpectrum(newObj[c][p])
        if (rmspecObj) {
        sgs.push({card: c, port: p, active: rmspecObj.activeModems, total: rmspecObj.totalModems, perc: Math.round((rmspecObj.activeModems / rmspecObj.totalModems) * 100) / 100} )
        }
        
      }
    }
    return sgs.filter(x => x)
    }


const hardware = {
    UBR10012: {
        fullDeviceName: "Cisco UBR10012",
        image: ubr10012image,
        defaultSettings: {
            DtccAlarm: true,
            StateAlarm: true,
            UptimeAlarm: true,
            TotalModemCount: {
                Lower: 0.4,
                MajorAlarm: true,
                Upper: 0.8,
                MinorAlarm: true
            },
            SGModemCount: {
                Lower: 0.4,
                MajorAlarm: true,
                Upper: 0.8,
                MinorAlarm: true
            },
            Temp: {
                FanAlarm: true,
                MajorAlarm: true,
                MinorAlarm: false,
                InletLower: 85,
                InletUpper: 100
            },
            SIGQMER:{
                MajorAlarm: true,
                MinorAlarm: true,
                Lower: 25,
                Upper: 30
            },
            SIGQCNiR:{
                MajorAlarm: true,
                MinorAlarm: true,
                Lower: 25,
                Upper: 30,
            }
        },
        getColor: (device, uid, deviceInfo) => {
            var lowestColor = "green"
            const getLowestColor = (color) => {
                if (lowestColor == "red") return "red"
                if (lowestColor == "orange" && color == "green") lowestColor =  lowestColor
                if (lowestColor == "orange" && color !== "green") lowestColor =  color
                if (lowestColor == "green" && color !== "green") lowestColor =  color
            }
            try {
            let settings = mergeDeep({}, hardware.UBR10012.defaultSettings, device[uid])
            
            

            const inletTempF = (device.inletTemp * (9 / 5)) + 32;
            if (inletTempF > settings?.Temp?.InletUpper) getLowestColor("red")
            if (inletTempF > settings?.Temp?.InletLower) getLowestColor("orange")

            const modemsPercentage = device?.ModemsByCard?.find(l => l.id === "Total")?.active / device?.ModemsByCard?.find(l => l.id === "Total")?.total;
            if (modemsPercentage) {
                if (modemsPercentage < settings?.TotalModemCount?.Upper) getLowestColor("orange")
                if (modemsPercentage < settings?.TotalModemCount?.Lower) getLowestColor("red")
            }

            let k = device?.ModemsByCard?.filter(crd => crd.id != "Total").map(card => {
                let perc = card.active / card.total
                if (perc < settings.SGModemCount.Upper) getLowestColor("orange")
                if (perc < settings.SGModemCount.Lower) getLowestColor("red")
                return card
            })

            const dtccs = device.Inventory?.filter(device => device.model === 'UBR10-DTCC').length;
            if (dtccs == 0) getLowestColor("red")
            if (dtccs < device.DtccCount) getLowestColor("orange")

            let j = device.SignalQuality ? device.SignalQuality.map(cardInfo => {
                if (cardInfo.avgCNIR){
                if (cardInfo.avgCNIR < settings.SIGQCNiR.Lower)getLowestColor("orange")
                else if (cardInfo.avgCNIR < settings.SIGQCNiR.Upper) getLowestColor("orange")
                }

                if (cardInfo.avgMER) {
                if (cardInfo.avgMER < settings.SIGQMER.Lower) getLowestColor("orange")
                else if (cardInfo.avgMER < settings.SIGQMER.Upper) getLowestColor("orange")
                }
                return 0
            }) : 0

            if (!deviceInfo.Active) getLowestColor('grey')

        

        } catch (e) {
            if (!deviceInfo.Active) getLowestColor('grey')
            console.log(e)
        }

            return lowestColor
        },
        sectionSettings: UBRSectionSettings
    },
    UBR7246VXR: {
        fullDeviceName: "Cisco UBR7246VXR",
        image: ubr7246image,
        defaultSettings: {
            StateAlarm: true,
            UptimeAlarm: true,
            TotalModemCount: {
                Lower: 0.4,
                MajorAlarm: true,
                Upper: 0.8,
                MinorAlarm: true
            },
            SGModemCount: {
                Lower: 0.4,
                MajorAlarm: true,
                Upper: 0.8,
                MinorAlarm: true
            },
            Temp: {
                FanAlarm: true,
                InletAlarm: true,
                InletLower: 85,
                InletUpper: 100
            },
            SIGQMER:{
                MajorAlarm: true,
                MinorAlarm: true,
                Lower: 25,
                Upper: 30
            },
            SIGQCNiR:{
                MajorAlarm: true,
                MinorAlarm: true,
                Lower: 25,
                Upper: 30,
            }
        },
        getColor: (device, uid, deviceInfo) => {
            var lowestColor = "green"
            const getLowestColor = (color) => {
                if (lowestColor == 'grey' || color == 'grey') lowestColor = 'grey'
                if (lowestColor == "red") return "red"
                if (lowestColor == "orange" && color == "green") lowestColor =  lowestColor
                if (lowestColor == "orange" && color !== "green") lowestColor =  color
                if (lowestColor == "green" && color !== "green") lowestColor =  color
            }
            try {
                if (!deviceInfo.Active) getLowestColor('grey')
            let settings = mergeDeep({}, hardware.UBR10012.defaultSettings, device[uid])

            

            const inletTempF = (device.inletTemp * (9 / 5)) + 32;
            if (inletTempF > settings?.Temp?.InletUpper) getLowestColor("red")
            if (inletTempF > settings?.Temp?.InletLower) getLowestColor("orange")

            const modemsPercentage = device?.ModemsByCard?.find(l => l.id === "Total")?.active / device?.ModemsByCard?.find(l => l.id === "Total")?.total;
            if (modemsPercentage) {
                if (modemsPercentage < settings?.TotalModemCount?.Upper) getLowestColor("orange")
                if (modemsPercentage < settings?.TotalModemCount?.Lower) getLowestColor("red")
            }

            let k = device?.ModemsByCard?.filter(crd => crd.id != "Total").map(card => {
                let perc = card.active / card.total
                if (perc < settings.SGModemCount.Upper) getLowestColor("orange")
                if (perc < settings.SGModemCount.Lower) getLowestColor("red")
                return card
            })

            const dtccs = device.Inventory?.filter(device => device.model === 'UBR10-DTCC').length;
            if (dtccs == 0) getLowestColor("red")
            if (dtccs < device.DtccCount) getLowestColor("orange")

            let j = device.SignalQuality ? device.SignalQuality.map(cardInfo => {
                if (cardInfo.avgCNIR < settings.SIGQCNiR.Lower) getLowestColor("red")
                else if (cardInfo.avgCNIR < settings.SIGQCNiR.Upper) getLowestColor("orange")

                if (cardInfo.avgMER < settings.SIGQMER.Lower) getLowestColor("red")
                else if (cardInfo.avgMER < settings.SIGQMER.Upper) getLowestColor("orange")
                return 0
            }) : 0

        

        } catch (e) {
            console.log(e)
        }

            return lowestColor
        },
        sectionSettings: UBR7246SectionSettings
    },
    HealthCheck: {
        fullDeviceName: "Health Checks",
        getColor: (device, settings) => {
            ////console.log(device, settings)
            return "green"
        }
    },
    RFGW1: {
        fullDeviceName: "RFGW1",
        image: rfgw1Image,
        defaultSettings: {
            StateAlarm: true,
            UptimeAlarm: true,

            GBE: {
                MajorAlarm: true,
                Lower: 0.6,
                Upper: 0.9,
                MinorAlarm: true,
            },
            QAMOutput: {
                MajorAlarm: true,
                MinorAlarm: true,
                Lower: 0.7,
                Upper: 0.85
            },
            QAMTemp: {
                Lower: 130,
                Upper: 180,
                MajorAlarm: true,
                MinorAlarm: true
            },
            FanAlarm: true,
        },
        getColor: (device, uid, deviceInfo) => {
            ////console.log(Object.keys(device), settings)
            //console.log(Object.keys(device))

            var lowestColor = "green"
            const getLowestColor = (color) => {
                if (lowestColor == 'grey' || color == 'grey') lowestColor = 'grey'
                if (lowestColor == "red") return "red"
                if (lowestColor == "orange" && color == "green") lowestColor =  lowestColor
                if (lowestColor == "orange" && color !== "green") lowestColor =  color
                if (lowestColor == "green" && color !== "green") lowestColor =  color
                return
            }
            try {
                if (!deviceInfo.Active) getLowestColor('grey')
            let settings = mergeDeep({}, hardware.RFGW1.defaultSettings, device[uid])

            
            let j = [device.gbeP1, device.gbeP2, device.gbeP3, device.gbeP4].map(x => x / 1000000000).map(gbe => {
                if (gbe >= settings.GBE.Upper) getLowestColor("red")
                else if (gbe >= settings.GBE.Lower) getLowestColor("orange")
                else getLowestColor("green")
                return gbe
            })

            function sliceIntoChunks(arr, chunkSize) {
                const res = [];
                for (let i = 0; i < arr.length; i += chunkSize) {
                    const chunk = arr.slice(i, i + chunkSize);
                    res.push(chunk);
                }
                return res;
            }

          
            let cardSesh = device.cardSessions
            let cardMaxBand = device.cardMaxBandwidth
            let cardBand = device.cardBandwidth

            let cards96 = cardSesh.map((sesh, i) => {
                let perc = cardMaxBand[i] == 0 ? 0 : (cardBand[i] / cardMaxBand[i])
                return {
                    perc: perc,
                    active: sesh == "1"
                }
            })

            let qamchunks = sliceIntoChunks(cards96, 8).map(cardport => {
                let reduction =  cardport.reduce((acc, curr, index) => {
                    let strfloat = isNaN(parseFloat(curr.perc)) ? 0 : parseFloat(curr.perc)
                    return {
                        sum: (acc.sum ?? 0) +strfloat, 
                        max: strfloat > acc.max ?? 0 ? strfloat : acc.max ?? 0, 
                        maxIndex: strfloat > acc.max ?? 0 ? index: acc.maxIndex ?? 0
                    }
                })
                reduction.sum = reduction.sum / 8
                return reduction
            }).map((itemgroup, i) => {
                let avgUtil = Math.round(itemgroup.sum * 100) / 100
                if (!avgUtil || isNaN(avgUtil)) return 0
                return getLowestColor(avgUtil < settings.QAMOutput.Lower ? "green" : avgUtil < settings.QAMOutput.Upper ? "orange" : "red")
            })

            let temps = [device.qam1Temp, device.qam2Temp, device.qam3Temp, device.qam4Temp, device.qam5Temp, device.qam6Temp].map(x => ((parseInt(x) * 9) / 5) + 32 ).map((temp, i) => {
                if (temp > settings.QAMTemp.Upper) {
                    getLowestColor("red")
                } else if (temp > settings.QAMTemp.Lower) {
                   getLowestColor("orange")
                }
            })

        } catch (e) {
            console.log(e)
        }

            return lowestColor
        },
        sectionSettings: RFGWSectionSettings
    },
    TC1000: {
        fullDeviceName: "Time Creator 1000",
        image: tcImage,
        defaultSettings: {
            StateAlarm: true,
            UptimeAlarm: true,
            TODAlarm: false,
        },
        getColor: (device, uid, deviceInfo) => {
            ////console.log(device, uid)
            const settings = mergeDeep({}, hardware.TC1000.defaultSettings, device[uid])

            var lowestColor = "green"
            const getLowestColor = (color) => {
                if (lowestColor == 'grey' || color == 'grey') lowestColor = 'grey'
                if (lowestColor == "red") return "red"
                if (lowestColor == "orange" && color == "green") lowestColor =  lowestColor
                if (lowestColor == "orange" && color !== "green") lowestColor =  color
                if (lowestColor == "green" && color !== "green") lowestColor =  color
                return
            }

            try {
                if (!deviceInfo.Active) getLowestColor('grey')
            let todmap = {
                "1": "RTC",
                "2": "NTP",
                "3": "DTI"
            }
            
            if (device.ConfiguredSource !== todmap[device.tod]) {
                getLowestColor("red")
            }
        } catch (e) {
            console.log(e)
        }

            return lowestColor
        },
        sectionSettings: TCSectionSettings
    },
    E6000: {
        fullDeviceName: "Casa E6000",
        getColor: (device, settings) => {
            ////console.log(device, settings)
            return "green"
        }
    },
    OsInfo: {
        fullDeviceName: "Environment",
    },
    Mikrotik: {
        fullDeviceName: "Mikrotik",
        image: mikrotikImage,
        defaultSettings: {
            StateAlarm: true,
            UptimeAlarm: true,
            BoardTemp: {
                MajorAlarm: true,
                MinorAlarm: true,
                Lower: 100,
                Upper: 120,
            },
            Bandwidth: {
                MaxBandwidth: 1000,
            }
        },
        getColor: (device, uid, deviceInfo) => {
            ////console.log(device, settings)
            const settings = mergeDeep({}, hardware.Mikrotik.defaultSettings, device?[uid] : {})
            
            var lowestColor = "green"
            const getLowestColor = (color) => {
                if (lowestColor == 'grey' || color == 'grey') lowestColor = 'grey'
                if (lowestColor == "red") return "red"
                if (lowestColor == "orange" && color == "green") lowestColor =  lowestColor
                if (lowestColor == "orange" && color !== "green") lowestColor =  color
                if (lowestColor == "green" && color !== "green") lowestColor =  color
            }

            try {
                if (!deviceInfo.Active) getLowestColor('grey')
            let boardTemp = parseInt(device.temperature) / 10
            if (!isNaN(boardTemp)) {
                    let inletF = ((parseInt(boardTemp) * 9) / 5) + 32
                    //console.log(boardTemp, settings.BoardTemp)
                    if (inletF >= settings.BoardTemp.Upper) {
                        getLowestColor("red")
                    } else if (inletF >= settings.BoardTemp.Lower) {
                        getLowestColor("orange")
                    }
            }

        } catch (e) {
            console.log(e)
        }

            return lowestColor
        },
        sectionSettings: MikrotikSectionSettings
    },
    C100G: {
        fullDeviceName: "Casa C100G",
        image: c100gimage,
        defaultSettings: {
            SpectrumNoise: {
                Lower: 0,
                Upper: 110
            },
            ModuleTemp:{
                Lower: 115,
                Upper: 127,
                MinorAlarm: true,
                MajorAlarm: true,
            },
            moduleStatusAlarm: true,
            pemTemp: {
                Lower: 100,
                Upper: 130,
                MinorAlarm: true,
                MajorAlarm: true
            },
            fanTemp:{
                Lower: 100,
                Upper: 130,
                MinorAlarm: true,
                MajorAlarm: true,
            },
            fanAlarm: true,
            BurstNoise:{
                Lower: 1500,
                Upper: 1800,
                MinorAlarm: true,
                MajorAlarm: true
            },
            StateAlarm: true,
            UptimeAlarm: true,
            TotalModemCount:{
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            SGModemCount: {
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            PortUtil: {
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            usSnr:{
                Lower: 27,
                Upper: 32,
                MinorAlarm: true,
                MajorAlarm: true
            },
            powerSupply: 2,
            NonBondingModems:{
                Lower: 0.25,
                Upper: 0.35,
                MinorAlarm: true,
                MajorAlarm: false
            },
        },
        getColor: (device, uid, deviceInfo) => {
            var lowestColor = "green"
            const getLowestColor = (color) => {
                if (lowestColor == "red") return "red"
                if (lowestColor == "orange" && color == "green") lowestColor =  lowestColor
                if (lowestColor == "orange" && color !== "green") lowestColor =  color
                if (lowestColor == "green" && color !== "green") lowestColor =  color
            }
            try {
            let settings = mergeDeep({}, hardware.UBR10012.defaultSettings, device[uid])

            if (!deviceInfo.Active) getLowestColor('grey')


            //Modem Count

            var modemsPercentage = device?.cardSummary?.Total?.activeModems / device?.cardSummary?.Total?.totalModems;
            if (modemsPercentage) {
                if (modemsPercentage < settings?.TotalModemCount?.Upper) getLowestColor("orange")
                if (modemsPercentage < settings?.TotalModemCount?.Lower) getLowestColor("red")
            }

            let sgModems = getSGModems(device?.cardSummary)
            sgModems.map(sg => {
                if (sg.perc < settings?.SGModemCount.Upper) getLowestColor("orange")
                if (sg.perc < settings?.SGModemCount.Lower) getLowestColor("red")
            })

            // Module Temps
            let moduleTemps = device?.modules.map(module => module.temp)
            moduleTemps.map(temp => {
                if (temp > settings?.ModuleTemp?.Lower) getLowestColor("orange")
                if (temp > settings?.ModuleTemp?.Upper) getLowestColor("red")
            })

        } catch(e) {
                console.log(e)
            }
            return lowestColor
        },
        sectionSettings: C100GSettings
    },
    C10G: {
        fullDeviceName: "Casa C10G",
        image: c10gimage,
        defaultSettings: {
            SpectrumNoise: {
                Lower: 0,
                Upper: 110
            },
            ModuleTemp:{
                Lower: 115,
                Upper: 127,
                MinorAlarm: true,
                MajorAlarm: true,
            },
            moduleStatusAlarm: true,
            pemTemp: {
                Lower: 100,
                Upper: 130,
                MinorAlarm: true,
                MajorAlarm: true
            },
            fanTemp:{
                Lower: 100,
                Upper: 130,
                MinorAlarm: true,
                MajorAlarm: true,
            },
            fanAlarm: true,
            BurstNoise:{
                Lower: 1500,
                Upper: 1800,
                MinorAlarm: true,
                MajorAlarm: true
            },
            StateAlarm: true,
            UptimeAlarm: true,
            TotalModemCount:{
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            SGModemCount: {
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            PortUtil: {
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            usSnr:{
                Lower: 27,
                Upper: 32,
                MinorAlarm: true,
                MajorAlarm: true
            },
            powerSupply: 2,
            NonBondingModems:{
                Lower: 0.25,
                Upper: 0.35,
                MinorAlarm: true,
                MajorAlarm: false
            },
        },
        getColor: (device, uid, deviceInfo) => {
            var lowestColor = "green"
            const getLowestColor = (color) => {
                if (lowestColor == "red") return "red"
                if (lowestColor == "orange" && color == "green") lowestColor =  lowestColor
                if (lowestColor == "orange" && color !== "green") lowestColor =  color
                if (lowestColor == "green" && color !== "green") lowestColor =  color
            }
            try {
            let settings = mergeDeep({}, hardware.UBR10012.defaultSettings, device[uid])

            if (!deviceInfo.Active) getLowestColor('grey')


            //Modem Count

            var modemsPercentage = device?.cardSummary?.Total?.activeModems / device?.cardSummary?.Total?.totalModems;
            if (modemsPercentage) {
                if (modemsPercentage < settings?.TotalModemCount?.Upper) getLowestColor("orange")
                if (modemsPercentage < settings?.TotalModemCount?.Lower) getLowestColor("red")
            }

            let sgModems = getSGModems(device?.cardSummary)
            sgModems.map(sg => {
                if (sg.perc < settings?.SGModemCount.Upper) getLowestColor("orange")
                if (sg.perc < settings?.SGModemCount.Lower) getLowestColor("red")
            })

            // Module Temps
            let moduleTemps = device?.modules.map(module => module.temp)
            moduleTemps.map(temp => {
                if (temp > settings?.ModuleTemp?.Lower) getLowestColor("orange")
                if (temp > settings?.ModuleTemp?.Upper) getLowestColor("red")
            })

        } catch(e) {
                console.log(e)
            }
            return lowestColor
        },
        sectionSettings: C100GSettings
    },
    C40G: {
        fullDeviceName: "Casa C40G",
        image: c40image,
        defaultSettings: {
            SpectrumNoise: {
                Lower: 0,
                Upper: 110
            },
            ModuleTemp:{
                Lower: 115,
                Upper: 127,
                MinorAlarm: true,
                MajorAlarm: true,
            },
            moduleStatusAlarm: true,
            pemAlarm: true,
            FanTemp:{
                low: 100,
                high: 130,
                lowerAlarm: true,
                upperAlarm: true,
            },
            fanAlarm: true,
            BurstNoise:{
                Lower: 1500,
                Upper: 1800,
                MinorAlarm: true,
                MajorAlarm: true
            },
            StateAlarm: true,
            UptimeAlarm: true,
            TotalModemCount:{
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            SGModemCount: {
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            PortUtil: {
                Lower: 0.7,
                Upper: 0.8,
                MinorAlarm: true,
                MajorAlarm: true
            },
            usSnr:{
                Lower: 27,
                Upper: 32,
                MinorAlarm: true,
                MajorAlarm: true
            },
            powerSupply: 2,
            NonBondingModems:{
                Lower: 0.25,
                Upper: 0.35,
                MinorAlarm: true,
                MajorAlarm: false
            },
        },
        getColor: (device, uid, deviceInfo) => {
            var lowestColor = "green"
            const getLowestColor = (color) => {
                if (lowestColor == "red") return "red"
                if (lowestColor == "orange" && color == "green") lowestColor =  lowestColor
                if (lowestColor == "orange" && color !== "green") lowestColor =  color
                if (lowestColor == "green" && color !== "green") lowestColor =  color
            }
            try {
            let settings = mergeDeep({}, hardware.UBR10012.defaultSettings, device[uid])

            if (!deviceInfo.Active) getLowestColor('grey')


            //Modem Count

            var modemsPercentage = device?.cardSummary?.Total?.activeModems / device?.cardSummary?.Total?.totalModems;
            if (modemsPercentage) {
                if (modemsPercentage < settings?.TotalModemCount?.Upper) getLowestColor("orange")
                if (modemsPercentage < settings?.TotalModemCount?.Lower) getLowestColor("red")
            }

            let sgModems = getSGModems(device?.cardSummary)
            sgModems.map(sg => {
                if (sg.perc < settings?.SGModemCount.Upper) getLowestColor("orange")
                if (sg.perc < settings?.SGModemCount.Lower) getLowestColor("red")
            })

            // Module Temps

            let moduleTemps = device?.modules.map(module => module.temp)
            moduleTemps.map(temp => {
                if (temp > settings?.ModuleTemp?.Lower) getLowestColor("orange")
                if (temp > settings?.ModuleTemp?.Upper) getLowestColor("red")
            })

        } catch(e) {
            console.log(e)
        }

            // Pems


            //let settings = device[uid] ? device[uid] : this.defaultSettings
            //delete device.cardSummary
            ////console.log(device, settings)
            ////console.log(settings, JSON.stringify(device,0,2))
            return lowestColor
        },
        sectionSettings: C40GSettings
    }
}

export default hardware