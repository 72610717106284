import { View, Text } from "react-native"
import {Section} from  "../../molecules/Section"
import { OverviewSection } from "../../molecules/OverviewSection"
import { colors } from "../../../config/globalStyle"
import hardware from "../../../config/hardware"

import { useSelector } from "react-redux"
import { mergeDeep } from "../../../Utilities"

const TODItem = ({label, selected, configuredFor="DTI"}) => {
    return (
        <View style={{borderWidth: selected ? 1 : 0, padding: 10, borderRadius: 5, flex: 1, alignItems: 'center', borderColor: configuredFor ? colors.green : colors.red}}>
            <Text>{label}</Text>
        </View>
    )
}
const TODSection = ({tod, configuredFor}) => {
    return (
        <View style={{flexDirection: 'row', padding: 10}}>
            <TODItem label="RTC" selected={tod == "1"} configuredFor={configuredFor == "RTC"}/>
            <TODItem label="NTP" selected={tod == "2"} configuredFor={configuredFor == "NTP"}/>
            <TODItem label="DTI" selected={tod == "3"} configuredFor={configuredFor == "DTI"}/>
        </View>
    )
}

export const TC1000Display = ({metrics, data, lincrID, selectedOrder}) => {
    const user = useSelector((state) => state.user.user)
    const settings = metrics[user.uid] ? mergeDeep({}, hardware.TC1000.defaultSettings, metrics[user.uid]) : hardware.TC1000.defaultSettings

    const Sections = {
        "Hardware Status": <Section title="Hardware Status" settings={settings} lincrID={lincrID}>
        <OverviewSection serial={metrics.serial} uptime={metrics.uptime} status={data.Active} type={data.Type}/>
    </Section>,
    "TOD Source":  <Section title="TOD Source" settings={settings} lincrID={lincrID} extraOpts={metrics.ConfiguredSource ?? "DTI"}>
    <TODSection tod={metrics.tod} configuredFor={metrics.ConfiguredSource ? metrics.ConfiguredSource : "DTI"}/>
</Section>
    }

    if (Object.keys(metrics).length == 0) return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
    <Text>No data reported by Lincr</Text>
  </View>
  
    return (
        <View style={{flex: 1, width: '100%'}}>

{
             selectedOrder ? selectedOrder.map((section) => {
              return Sections[section]
          }) : Object.keys(Sections).map((section) => Sections[section])
           }
        </View>
    )
}