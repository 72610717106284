import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, StyleSheet } from "react-native"
import { useState } from "react";
import { Spacer } from "../../../atoms/Spacer"
import {FontAwesome} from '@expo/vector-icons'
import { colors } from "../../../../config/globalStyle";
import { UpstreamName } from "../../../atoms/UpstreamName";
import { useEffect } from "react";


function groupBy(objectArray, property, modifier=() => {}) {
  return objectArray.reduce((acc, obj) => {
     const key = modifier(obj[property]);
     if (!acc[key]) {
        acc[key] = [];
     }
     // Add object to list for given key's value
     acc[key].push(obj);
     return acc;
  }, {});
}



const BurstNoiseRow = ({bn, port, name = "", color}) => {
  return <View style={{flexDirection: 'row', flex: 1, padding: 3, alignItems: 'center', borderBottomWidth: 1, borderColor: colors.lightGrey, maxWidth: 250}}>
    <Text style={{flex: 1}}>Channel {port.split('.')[1].split("/")[0]}</Text>
    <Text style={{flex: 1, backgroundColor: color, padding: 2, borderRadius: 5, overflow: 'hidden', textAlign: 'center'}}>{bn}</Text>
  </View>
}

const BurstNoiseGroup = ({card, group, names, settings}) => {
  const [opened, open] = useState(true)
  const name = names[card] == "" ? "Port " + card : names[card]
  return <TouchableOpacity style={{padding: 7, borderWidth: 0, marginBottom: 5, shadowColor: 'black', shadowOffset: {width: 0, height: 0}, shadowOpacity: 0.3, backgroundColor: 'white', borderRadius: 5, elevation: 3, }} onPress={() => {open(!opened)}}>
    
    {
    opened ? <View style={{flexDirection: 'row'}}>
      <UpstreamName name={name} port={card}/>
      <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}}>
      {
        group.map((item) => {
          let color = item.bn >= settings.BurstNoise.Upper ? colors.red : item.bn >= settings.BurstNoise.Lower ? colors.orange : colors.green
          return <>
          <FontAwesome name="circle" size={15} color={color} key={item.channel}/>
          <Spacer width={2} key={item.channel + "spacer"}/>
          </>
        })
      }
      </View>
    </View> : <View>
    <View style={{flexDirection: 'row'}}>
    <UpstreamName name={name} port={card}/>
    <View style={{flex: 1}}/>
    </View>
    <Spacer height={5}/>
    <View style={{alignItems: 'center'}}>
    {
      group.map(item => {
        let color = item.bn >= settings.BurstNoise.Upper ? colors.red : item.bn >= settings.BurstNoise.Lower ? colors.orange : colors.green
        return <BurstNoiseRow bn={item.bn} port={item.channel} name={names[card]} key={item.channel} color={color}/>
      })
    }
    </View>
      </View>
    }

    <View style={styles.triangleCorner}/>
    
    </TouchableOpacity>
}

export const BurstNoiseSection = ({burstNoise, names = [], settings}) => {
  const [grouping, setgrouping] = useState(groupBy(burstNoise, 'channel', (val) => {return val.split('.')[0]}))

  useEffect(() => {
    setgrouping(groupBy(burstNoise, 'channel', (val) => {return val.split('.')[0]}))
  }, [burstNoise])

  if (Object.keys(grouping).length == 0) return <View style={{alignItems: 'center', width: '100%'}}><Text>No Burst Noise Info</Text></View>
  return <View>
    <Spacer height={7}/>
    {
      Object.keys(grouping).map(cardport => {
        return <BurstNoiseGroup card={cardport} group={grouping[cardport]} names={names} key={cardport} settings={settings}/>
      })
    }
  </View>
}

const styles = StyleSheet.create({
  triangleCorner: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 15,
    borderRightWidth: 0,
    borderBottomWidth: 15,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: colors.blue,
     position: 'absolute',
     right: 0,
     bottom: 0,
  },
})