import { PEMSettings } from "./PemSettings";
import { FanSettings } from "./FanSettings";
import { HardwareStatusSettings } from "./HardwareStatus";
import { ActiveModemSettings } from "./ActiveModems";
import { PortUtilSettings } from "./Utilization";
import { SNRSettings } from "./upstreamSigQ";
import { BurstNoiseSettings } from "./BurstNoise";
import { NonBondingSettings } from "./NonBondingModems";
import { TemperatureSettings } from "./Temperature";
import { SpectrumSettings } from "./SpectrumNoise";

const router =  {
    "PEMS (Power Entry Modules)": <PEMSettings/>,
    "Fans": <FanSettings/>,
    "Hardware Status": <HardwareStatusSettings/>,
    "Active Modems": <ActiveModemSettings/>,
    "DS and US Port Utilization": <PortUtilSettings/>,
    "Upstream SNR by Service Group": <SNRSettings/>,
    "Burst Noise per US Channel": <BurstNoiseSettings/>,
    "Module Summary": <TemperatureSettings/>,
    "Non-Bonding Modem Summary": <NonBondingSettings/>,
    "Upstream Spectrum Noise": <SpectrumSettings/>,
};

export default router