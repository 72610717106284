import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { StyleSheet, Image, View, Text, TouchableOpacity, Button, Dimensions} from 'react-native'
import {FontAwesome, Entypo, Octicons, Feather} from '@expo/vector-icons'

import {Section} from  "../../molecules/Section"
import hardware from "../../../config/hardware"
import { getColorfromDF, mergeDeep, secondsToDateTime } from "../../../Utilities"
import { colors } from "../../../config/globalStyle"
import { uuidv4 } from "@firebase/util"
import { Spacer } from "../../atoms/Spacer"
import * as reducers from '../../../redux_slices/reducers'
import { ProgressBar } from "../../atoms/ProgressBar"
const HCColors = ["lightGrey", "green", "yellow", "red"]


const MemoryDisplay = ({util, max, units}) => {
    return <View style={{justifyContent: 'center'}}>
        <Text style={{justifyContent: 'center', textAlign: 'center'}}>{Math.round(util)}%</Text>
        <View style={{height: 1, width: 40, backgroundColor: 'black', marginVertical: 3}}></View>
        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <Text>{max}</Text>
        <Text>{units}</Text>
        </View>
    </View>
}

export const LincrDisplay = ({ metrics, data, lincrID, selectedOrder }) => {
    const [showBreakdown, setShowBreakdown] = useState(false)
    const user = useSelector((state) => state.user.user)
    const settings = metrics[user.uid] ? mergeDeep({}, hardware.UBR10012.defaultSettings, metrics[user.uid]) : hardware.UBR10012.defaultSettings
    const dispatch = useDispatch()
    const Sections = {
        "Status": <Section title="Hardware Status" hideSettings={true}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingVertical: 10, maxWidth: '60%'}}>
                <View style={{flex: 0.5, justifyContent: 'center', alignItems: 'center'}}>
                <FontAwesome name={metrics.version.toLowerCase().includes("windows") ? "windows" : 'linux'} size={40} color={'black'}/>
            </View>

            <View style={{flex: 1, alignItems: 'center'}}>
            
            
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <FontAwesome name="circle" color={getColorfromDF(metrics.df)} size={15}/>
                <Spacer width={5}/>
                <Text>{getColorfromDF(metrics.df) == 'red'  ? "Not Reachable" : "Active"}</Text>
            </View>
            <Spacer height={5}/>
            <Text>UP: {secondsToDateTime(metrics.uptime)}</Text>
            </View>
            </View>
            </View>
        </Section>,
        "Usage": <Section title="Usage" hideSettings={true}>
            <View style={{flexDirection: 'row', alignItems: 'center', paddingVertical: 10}}>
                <Text style={{flex: 0.3}}>CPU</Text>
                <Text style={{flex: 0.4, textAlign: 'center'}}>{metrics.cpuUsage}%</Text>
            <ProgressBar percent={metrics.cpuUsage / 100} disabled={metrics.memUsage == 0} color={metrics.memUsage < 60 ? "green" : metrics.memUsage < 80 ? "yellow" : 'green'}/>
            </View>

            <View style={{flexDirection: 'row', alignItems: 'center', paddingBottom: 10}}>
                <Text style={{flex: 0.3}}>Memory</Text>
                <View style={{flex: 0.4, justifyContent: 'center', alignItems: 'center'}}>
                <MemoryDisplay util={metrics.memUsage} max={Math.round(metrics.totalMem)} units={metrics.totalMemUnits}/>
                </View>
            <ProgressBar percent={metrics.memUsage / 100} disabled={metrics.memUsage == 0} color={metrics.memUsage < 60 ? "green" : metrics.memUsage < 80 ? "yellow" : 'green'}/>
            </View>
        </Section>,
        "Environment": <Section title="Environment" hideSettings={true}>
            <View style={{flexDirection: 'row', borderBottomWidth: 1, borderColor: colors.lightGrey, paddingVertical: 3}}>
            <Text style={{flex: 1}}>OS Version</Text>
            <Text style={{flex: 2}}>{metrics.version}</Text>
            </View>
            

            <View style={{flexDirection: 'row', borderBottomWidth: 1, borderColor: colors.lightGrey, paddingVertical: 3}}>
            <Text style={{flex: 1}}>Lincr Version</Text>
            <Text style={{flex: 2}}>{metrics.lincrversion}</Text>
            </View>

            <View style={{flexDirection: 'row', borderBottomWidth: 1, borderColor: colors.lightGrey, paddingVertical: 3}}>
            <Text style={{flex: 1}}>Last Boot</Text>
            <Text style={{flex: 2}}>{metrics.lastBoot}</Text>
            
            </View>
                </Section>
    }

    if (Object.keys(metrics).length == 0) return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
    <Text>No data reported by Lincr</Text>
  </View>
  
    return (
      <View>
        {
             selectedOrder ? selectedOrder.map((section) => {
              return Sections[section]
          }) : Object.keys(Sections).map((section) => Sections[section])
           
        }
  
      </View>
    )
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: '#000',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 18,
      lineHeight: 23,
      flex: 2
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    },
    appButtonContainer: {
      elevation: 8,
      backgroundColor: "#009688",
      borderRadius: 10,
      paddingVertical: 10,
      paddingHorizontal: 12
    },
    appButtonText: {
      fontSize: 18,
      color: "#fff",
      fontWeight: "bold",
      alignSelf: "center",
      textTransform: "uppercase"
    },
    spaceEvenly: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    bodyText: {
      fontSize: 14,
      color: "grey"
    },
    greenHeader: {
      fontSize: 16,
      color: "green"
    },
    redHeader: {
      fontSize: 16,
      color: "red"
    },
    greyHeader: {
      fontSize: 16,
      color: "grey"
    },
    error: {
      color: 'red',
      fontSize: 16
    },
    activeModems: {
      justifyContent: 'center',
      flexDirection: 'row'
    },
    modemData: {
      justifyContent: 'space-evenly',
      marginLeft: 10
    }
  });
  