import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet, Modal, TouchableOpacity, ActivityIndicator, SafeAreaView, Platform } from 'react-native'
import { WebView } from 'react-native-webview';

import { MaterialCommunityIcons } from '@expo/vector-icons';


import { useSelector } from 'react-redux';
import { Spacer } from '../components/atoms/Spacer';


import moment from 'moment-timezone';

function WebPageLoading(){
    return (
        <View style={styles.loadingIndicator}>
            
            <ActivityIndicator size="small" style={{transform: [{scale: 1.5}]}} color="black"/>
            <Spacer height={15}/>
            <Text style={{ marginBottom: 5}}>Loading Graphs</Text>
        </View>
    )
}
export default function WebViewModal({ lincrID, deviceName, portNumber, visible, onClose }) {
    const user = useSelector((state) => state.user.user);
    const timezone = moment.tz.guess();

    // Conditional component based on platform
    const RenderComponent = Platform.OS === 'web' ? 'iframe' : WebView;
    const uri = `https://telawatch-next.herokuapp.com/charts/1?token=${user.stsTokenManager.accessToken}&lincrID=${lincrID}&deviceName=${deviceName}&portNumber=${portNumber}&timezone=${timezone}`;

    if (Platform.OS == "web") return <Modal
        visible={visible}
        transparent={true}
        presentationStyle='overFullScreen'
    >
        <View onStartShouldSetResponder={() => onClose()}  style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)'
        }}>
        <View style={{
            backgroundColor: 'white',
            width: '40%',
            height: '100%'
        }}>
            <TouchableOpacity style={styles.header} onPress={onClose}>
                <View style={styles.closeButton}>
                    <MaterialCommunityIcons name="window-close" size={20} color="black" />
                </View>
            </TouchableOpacity>
            <RenderComponent
                onShouldStartLoadWithRequest={(r) => true}
                startInLoadingState={true}
                renderLoading={WebPageLoading}
                originWhitelist={['*']}
                source={{ uri }} // For WebView
                src={uri} // For iframe
                style={styles.webview}
            />
        </View>
        </View>
    </Modal>

    return (
        <Modal
            visible={visible}
            transparent={true}
            presentationStyle='overFullScreen'
        >
            <>
                <View style={styles.page} onStartShouldSetResponder={() => onClose()} />
                <View style={styles.safeAreaView}>
                    <View style={styles.webviewContainer}>
                        <TouchableOpacity style={styles.header} onPress={onClose}>
                            <View style={styles.closeButton}>
                                <MaterialCommunityIcons name="window-close" size={20} color="black" />
                            </View>
                        </TouchableOpacity>
                        <RenderComponent
                            onShouldStartLoadWithRequest={(r) => true}
                            startInLoadingState={true}
                            renderLoading={WebPageLoading}
                            originWhitelist={['*']}
                            source={{ uri }} // For WebView
                            src={uri} // For iframe
                            style={styles.webview}
                        />
                    </View>
                </View>
            </>
        </Modal>
    );
}

const styles = StyleSheet.create({
    page:{
        height: '100%',
        backgroundColor:'#4747479e'
    },
    safeAreaView:{
        height: '80%',
        marginTop: 'auto',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        overflow: 'hidden',
        backgroundColor:'white'
    },
    header:{
        padding: 10,
    },
    closeButton:{
        marginLeft:'auto'
    },
    loadingIndicator:{
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        height:'100%',
        width:'100%',
    },
    webviewContainer:{
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        position: 'relative',
    },
    webview:{
        flex: 1
    }
})
