import { useState, useEffect } from "react"
import { Text, View } from "react-native";

import { secondsToDateTime } from "../../Utilities";

export const LastUpdatedBottomView = ({ lastUpdated }) => {
    const [timer, setTimer] = useState(0);
    useEffect(() => {
      const interval = setInterval(() => setTimer(Date.now()), 1000);
  
      return () => {
        clearInterval(interval);
      }
    }, []);
  
    return (
      <View style={{flexDirection: 'row', width: '100%'}}>
      <Text style={{ width: '100%', textAlign: 'center', color: "#a5a5a5", paddingVertical: 2 }}>Last Updated: {secondsToDateTime((Date.now() - lastUpdated) / 1000)} ago</Text>
      </View>
      )
  }