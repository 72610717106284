import { useState } from "react"
import { Text, View,} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";
import RangeSlider from "../../../atoms/RangeSlider";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";
import { colors } from "../../../../config/globalStyle";


export const FanSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
   
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    const [tempAlarmMajor, setTempAlarmMajor] = useState(settings.fanTemp.MajorAlarm);
    const [tempAlarmMinor, setTempAlarmMinor] = useState(settings.fanTemp.MinorAlarm);
    const [tempSlider, setTempSlider] = useState()

    const tempHandler = (value) => {
        
        setTempSlider(value)
    }
    
    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            if (tempSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.fanTemp.Lower`] = tempSlider.min
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.fanTemp.Upper`] = tempSlider.max
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.fanTemp.MajorAlarm`] = tempAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.fanTemp.MinorAlarm`] = tempAlarmMinor

            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{paddingBottom: 10}}>
            <RangeSlider min={60} max={140} step={1} onValueChange={tempHandler} units="˚F" label="Fan Temperature Thresholds" defaultValue={{min: (settings.fanTemp.Lower - 60) / 80, max: (settings.fanTemp.Upper - 60) / 80}} colors={["green", "orange", "red"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={tempAlarmMajor} onValueChange={(val) => setTempAlarmMajor(val)} style={{borderColor: "grey"}} level={2}/>
            <ToggleLabel title="Major Alarm" value={tempAlarmMinor} onValueChange={(val) => setTempAlarmMinor(val)} style={{borderColor: "grey"}} level={1}/>
            </View>

            </View>
        </View>
    )
}
