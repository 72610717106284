import { useState } from "react"
import { Text, View,} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";
import RangeSlider from "../../../atoms/RangeSlider";

import { Spacer } from "../../../atoms/Spacer"
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";

export const ActiveModemSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [totalModemSlider, setTMslider] = useState()
    const [serviceGroupSlider, setSGslider] = useState()
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
   
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings

    const [tmSliderAlarmMajor, settmSliderAlarmMajor] = useState(settings.TotalModemCount.MajorAlarm);
    const [tmSliderAlarmMinor, settmSliderAlarmMinor] = useState(settings.TotalModemCount.MinorAlarm);
    const [sgSliderAlarmMajor, setsgSliderAlarmMajor] = useState(settings.SGModemCount.MajorAlarm);
    const [sgSliderAlarmMinor, setsgSliderAlarmMinor] = useState(settings.SGModemCount.MinorAlarm);


    useEffect(() => {
        if (settingsSaved){
            

            let obj = {}
            if (totalModemSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.TotalModemCount`] = {
                    Lower: totalModemSlider.min / 100,
                    Upper: totalModemSlider.max / 100,
                }
            }

            if (serviceGroupSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SGModemCount`] = {
                    Lower: serviceGroupSlider.min / 100,
                    Upper: serviceGroupSlider.max / 100,
                }
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.TotalModemCount.MajorAlarm`] = tmSliderAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.TotalModemCount.MinorAlarm`] = tmSliderAlarmMinor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SGModemCount.MajorAlarm`] = sgSliderAlarmMajor

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.SGModemCount.MinorAlarm`] = sgSliderAlarmMinor

            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))
        }
    }, [settingsSaved])

    return (
        <View style={{}}>
            <View style={{paddingBottom: 10}}>
            <RangeSlider min={0} max={100} step={1} onValueChange={setTMslider} units="%" label="Total Modem Outage Levels" defaultValue={{min: settings.TotalModemCount.Lower * 1, max: settings.TotalModemCount.Upper * 1}} colors={["red", "orange", "green"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Major Alarm" value={tmSliderAlarmMinor} onValueChange={(val) => settmSliderAlarmMinor(val)} style={{ borderColor: 'grey',}} level={2}/>
            <ToggleLabel title="Minor Alarm" value={tmSliderAlarmMajor} onValueChange={(val) => settmSliderAlarmMajor(val)} style={{ borderColor: 'grey',}} level={1}/>
            
            </View>
            <Spacer height={15}/>
            </View>

            <RangeSlider min={0} max={100} step={1} onValueChange={setSGslider} units="%" label="Service Group Outage Levels" defaultValue={{min: settings.SGModemCount.Lower * 1, max: settings.SGModemCount.Upper * 1}} colors={["red", "orange", "green"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Major Alarm" value={sgSliderAlarmMinor} onValueChange={(val) => setsgSliderAlarmMinor(val)} style={{ borderColor: 'grey'}} level={2}/>
            <ToggleLabel title="Minor Alarm" value={sgSliderAlarmMajor} onValueChange={(val) => setsgSliderAlarmMajor(val)} style={{ borderColor: 'grey'}} level={1}/>
            
            </View>
        </View>
    )
}