import { useEffect, useState } from "react"
import { View, Text, Button, ScrollView, KeyboardAvoidingView } from "react-native"
import { useSelector, useDispatch } from "react-redux"

import * as reducers from '../redux_slices/reducers'
import hardware from "../config/hardware"
import { Btn } from "../components/atoms/Btn"

import ws_logger from '../logger'

export const SectionSettings = ({device}) => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const dispatch = useDispatch()

    return (
        <>
        <View style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.2)'}} onStartShouldSetResponder={() => {
            dispatch(reducers.nav.setSectionSettings(false))
            ws_logger.log("Dismiss Tile Settings", {"tileName": sectionData.title})
        }}>
        
        </View>
        <View  behavior="position" style={{ position: 'absolute', bottom: 0, width: '100%' }} elevation={3} shadowOffset={{width: 0, height: -1}} shadowOpacity={0.3} shadowColor="black">
            <View style={{flex: 1, width: '100%', backgroundColor: '#ffffff', minHeight: 150, paddingHorizontal: 10, paddingVertical: 5, paddingBottom:20, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
        <View style={{flexDirection: 'row', alignItems: 'center', width: '100%', borderBottomWidth: 1, borderColor: '#c2c2c2', marginBottom: 10, paddingBottom: 2}} >
          <Text style={{flex: 1, fontSize: 17, fontWeight: '500', textAlign: 'center', marginLeft: 40}}>{sectionData.title} Settings</Text>
          <Btn label="Save" onPress={() => { 
            dispatch(reducers.nav.saveSettings(true))
            ws_logger.log("Save Tile Settings", {"tileName": sectionData.title})
            }} />
          </View>
          <ScrollView style={{paddingHorizontal: 5}} showsVerticalScrollIndicator={false}>
          {
            device ? hardware[device.Type].sectionSettings[sectionData.title] : <></>
          }
</ScrollView>
            </View>
        </View>
        </>
    )
}