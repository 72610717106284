import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"

import { Spacer } from "../../../atoms/Spacer"
import { colors } from "../../../../config/globalStyle"
import {AntDesign} from '@expo/vector-icons'

const InputRow = ({input}) => {
    return <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}}>
        <Text style={{flex: 1}}>Input {input.id}</Text>
        {
            input.present ? <View style={{flex: 1, alignItems: 'center'}}><AntDesign name="check" size={16}/></View> : <></>
        }
        <View style={{flex: 0.75, height: 16, backgroundColor: input.fuse == "Good" ? colors.green : colors.red}}></View>
    </View>
}

const SinglePem = ({ pem, pemNum, tempColor }) => {
    let message = pem.present == "YES" ? pem.status : "Not Present"
    return (
        <View style={{flex: 1}}>
            <Text style={{textAlign: 'center'}}>PEM {pemNum}</Text>
            <View style={{flexDirection: 'row', flex: 1, alignItems: 'center', paddingVertical: 3}}>
                <Text style={{flex: 1}}/>
                <Text style={{flex: 1, fontSize: 14,}}>Present</Text>
                <Text style={{flex: 0.75, fontSize: 14, textAlign: 'center'}}>Fuse</Text>
                </View>
            {
                pem.inputs.map((input) => {
                    return <InputRow key={input.id} input={input} />
                })
            }

<View style={{flexDirection: 'row', flex: 1, alignItems: 'center', paddingVertical: 3}}>
    <Text style={{flex: 1}}>Temp</Text>
    <Text style={{flex: 1, fontSize: 14,}}></Text>
    <Text style={{flex: 0.75, textAlign: 'center', backgroundColor: tempColor, padding: 2}}>{pem.temp}°F</Text>
                    </View>
        </View>
    )
}


export const PEMSection = ({ pems, settings }) => {
    return (
        <View style={{ flexDirection: 'row', padding: 3, paddingVertical: 5}}>
            <SinglePem pem={pems[0]} pemNum="A" tempColor={pems[0].temp < settings.pemTemp.Lower ? colors.green : pems[0].temp < settings.pemTemp.Upper ? colors.orange : colors.red}/>
            <Spacer width={15} />
            <SinglePem pem={pems[1]} pemNum="B" tempColor={pems[1].temp < settings.pemTemp.Lower ? colors.green : pems[1].temp < settings.pemTemp.Upper ? colors.orange : colors.red}/>
        </View>
    )
}