import { useState } from "react"
import { Text, View,} from "react-native"
import { Switch } from 'react-native-paper';
import ToggleLabel from "../../../atoms/ToggleLabel";

import SegmentedControl from '@react-native-segmented-control/segmented-control';

import { Spacer } from "../../../atoms/Spacer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { mergeDeep } from "../../../../Utilities";
import * as reducers from '../../../../redux_slices/reducers'
import hardware from "../../../../config/hardware";
import { UpdateSiteSettings } from "../../../../Firebase";

export const TODSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [todsource, selectSource] = useState()
    const [todsourceindex, selectSourceindex] = useState()

    const setTOD = (event, val) => {
        if (event) {
        switch (event.nativeEvent.selectedSegmentIndex) {
           
            case 0:
                selectSource("RTC"); selectSourceindex(0); break
            case 1:
                selectSource("NTP"); selectSourceindex(1); break
            case 2:
                selectSource("DTI"); selectSourceindex(2); break
            default:
                break;
        }
        } else {
            switch (val) {
           
                case "RTC":
                    selectSource("RTC"); selectSourceindex(0); break
                case "NTP":
                    selectSource("NTP"); selectSourceindex(1); break
                case "DTI":
                    selectSource("DTI"); selectSourceindex(2); break
                default:
                    break;
            }
        }
    }

    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    const configuredSource = sectionData.extra

    const [sourceChangeAlarm, setSourceChangeAlarm] = useState(settings.TODAlarm);
    
    useEffect(() => {
        setTOD(null, configuredSource)
    }, [])

    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))
            //console.log("Saving TC TOD Settings - ") 

            let obj = {}
            
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.TODAlarm`] = sourceChangeAlarm
            obj[`${JSON.parse(selectedDevice).DisplayName}.ConfiguredSource`] = todsource
            if (Object.keys(obj).length > 0) UpdateSiteSettings({setObj: obj, id: sectionData.lincrID})
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                <Text style={{fontSize: 16, paddingLeft: 5}}>Source configured for</Text>
                <Spacer width={5}/>
                <SegmentedControl
                        selectedIndex={todsourceindex}
                        values={['RTC', 'NTP', 'DTI']}
                        onChange={setTOD}
                        style={{ flexGrow: 1, maxWidth: 200 }}
                        tintColor="rgba(0,0,0,0.4)"
                        backgroundColor="white"
                        // tintColor="black"
                    />
            </View> 
            <Spacer height={10}/>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <ToggleLabel title="When the timing source changes" value={sourceChangeAlarm} onValueChange={(val) => setSourceChangeAlarm(val)} />
        </View>
    )
}