import { TouchableOpacity, View, Text, Image, StyleSheet } from 'react-native'
import { useState } from 'react'
import { colors } from '../../config/globalStyle'

import ws_logger from '../../logger'

export const TapabbleFan = ({rpm = 0, color=colors.green, c100g=false, fan=null, position="", settings={Lower: 85, Upper: 100}}) => {
    const [tapped, setTapped] = useState(false)

    if (c100g) {
        let clr = fan.present ? colors.green : colors.red
        return <View style={{flexDirection: 'row', flex: 1, justifyContent: 'space-around', width: '90%', paddingBottom: 10, alignItems: 'center'}}>
            <Text style={{flex: 0.5}}>{position}</Text>
            <View onPress={() => setTapped(!tapped)} style={{backgroundColor: 'white', shadowColor: "black", shadowOffset: {width: 0, height: 2}, shadowOpacity: 0.3, shadowRadius: 4, borderRadius: 7}}>
        
            <>
            <View>
                <Image source={require('../../../assets/fan.png')} style={{ width: 60, height: 60, tintColor: clr }} />
                </View>
            </>
            
        
    </View>
    <View style={{justifyContent: 'space-around'}}>
    <Text style={{borderColor: fan.temp < settings.Lower ? colors.green : fan.temp < settings.Upper ? colors.orange : colors.red, borderWidth: 1, borderRadius: 5, paddingHorizontal: 5}}>Temp {"\t" + fan.temp}°F</Text>
    <View style={{borderWidth: 1, padding: 3, borderRadius: 5, marginVertical: 8, paddingTop: 7}}>
        <Text style={{position: 'absolute', top: -8, left: 3, backgroundColor: 'white', paddingHorizontal: 3}}>RPM</Text>
    <Text>Front {"\t" + fan.frontRPM}</Text>
    <Text>Back{"\t" + fan.backRPM}</Text>
    </View>
    </View>
    </View>
    }

    return <TouchableOpacity onPress={() => {
        setTapped(!tapped)
        ws_logger.log("Tap", {item: "Fan"})
    }} style={{backgroundColor: 'white', shadowColor: "black", shadowOffset: {width: 0, height: 2}, shadowOpacity: 0.3, shadowRadius: 4, borderRadius: 7}}>
        {
            tapped ? <><View style={{ backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, borderRadius: 7, borderWidth: 1, borderColor: color }}>
                <Text style={{ textAlign: 'center' }}>{rpm} rpm</Text>

            </View><View style={styles.triangleCorner}></View></> : <>
                <Image source={require('../../../assets/fan.png')} style={{ width: 60, height: 60, tintColor: color }} />
                <View style={styles.triangleCorner}></View>
            </>
        }
        
    </TouchableOpacity>
}

const styles = StyleSheet.create({
    triangleCorner: {
        width: 0,
       height: 0,
       backgroundColor: 'transparent',
       borderStyle: 'solid',
       borderTopWidth: 0,
       borderRightWidth: 0,
       borderBottomWidth: 15,
       borderLeftWidth: 15,
       borderTopColor: 'transparent',
       borderRightColor: 'transparent',
       borderBottomColor: colors.blue,
       borderLeftColor: 'transparent',
         position: 'absolute',
         right: 0,
         bottom: 0,
      },
})