import { useState } from "react"
import { Text, View,} from "react-native"
import { Switch } from 'react-native-paper';
import ToggleLabel from "../../../atoms/ToggleLabel";

import SegmentedControl from '@react-native-segmented-control/segmented-control';

import { Spacer } from "../../../atoms/Spacer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";



export const FanSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    
    const [fanAlarm, setFanAlarm] = useState(settings.FanAlarm);
    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))
            //console.log("Saving rfgw fan Settings - ") 

            let obj = {}

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.FanAlarm`] = fanAlarm
            
            if (Object.keys(obj).length > 0) UpdateSiteSettings({id: sectionData.lincrID, setObj: obj})
        }
    }, [settingsSaved])
    return (
        <View>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <ToggleLabel title="When a fan has a problem" value={fanAlarm} onValueChange={(val) => setFanAlarm(val)} />
        </View>
    )
}