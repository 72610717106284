import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import {FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons'
import { colors } from "../../../../config/globalStyle"


const SortableHitData = ({data}) => {
  return <View>
    <View style={{flexDirection: 'row', borderBottomWidth: 1, borderColor: colors.lightGrey, marginVertical: 5, paddingVertical: 5}}>
      <View style={{flex: 0.5}}/>
    <Text style={{flex: 1}}>Port</Text>
    <Text style={{flex: 1}}>Hits</Text>
    </View>

    {
      Object.keys(data).reduce((acc, cur) => {
        acc.push({id: cur, hits: data[cur]})
        return acc
      }, []).sort((a, b) => a.hits < b.hits).map((obj, i) => {
        return <View style={{flexDirection: 'row'}} key={obj.id}>
          <View style={{flex: 0.5}}/>
          <Text style={{flex: 1}}>{obj.id}</Text>
          <Text style={{flex: 1}}>{obj.hits}</Text>
          </View>
      })
    }
  </View>
}

export const PartialServiceModal = ({dismiss, usds, data}) => {
  let hitData = usds == 'us' ? data.hitsByUs : data.hitsByDs

  return <>
  <View style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.2)'}} onStartShouldSetResponder={() => dismiss("")}>
  
  </View>
  <View  behavior="position" style={{ position: 'absolute', bottom: 0, width: '100%' }} shadowOffset={{width: 0, height: -1}} shadowOpacity={0.3} shadowColor="black">
      <View style={{flex: 1, width: '100%', backgroundColor: '#ffffff', minHeight: 150, maxHeight: 500, paddingHorizontal: 10, paddingVertical: 5, paddingBottom:20, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
  <View style={{flexDirection: 'row', alignItems: 'center', width: '100%', borderBottomWidth: 1, borderColor: '#c2c2c2', marginBottom: 10, paddingBottom: 2, marginTop: 3}} >
    <Text style={{flex: 1, fontSize: 17, fontWeight: '500', textAlign: 'center', marginLeft: 0, paddingBottom: 3}}>{`${usds}`.toUpperCase()} Utilization</Text>
    <TouchableOpacity onPress={() => { dismiss("") }} style={{position: 'absolute', right: 7, top: 0}}>
      <MaterialCommunityIcons name="window-close" size={21} color="black" />
    </TouchableOpacity>

    </View>
    

    <ScrollView>
      <SortableHitData data={hitData}/>
    </ScrollView>

      </View>
  </View>
  </>
}