import { useEffect, useState } from "react"
import { View, Text, Button, ScrollView, KeyboardAvoidingView, TouchableOpacity, Alert, Keyboard, Dimensions } from "react-native"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "../RootNavigation"
import {FontAwesome5, Feather} from '@expo/vector-icons'

import { Spacer } from "../components/atoms/Spacer"
import packageJSON from '../../package.json'
import { auth, DeleteAccount, signOutUser, updateUserProfile, ReloadUser, postFeedback } from "../Firebase"
import { updateProfile } from "firebase/auth"
import { InputField } from "../components/atoms/InputField"
import { Btn } from "../components/atoms/Btn"
import * as reducers from '../redux_slices/reducers'
import { confirmAction, mergeDeep } from "../Utilities"
import { TextInput } from "react-native-paper"
import { colors } from "../config/globalStyle"
import { TWLogo } from "../components/atoms/TelaWatchLogo"


export const UserSettingsPage = ({dismiss}) => {
    const user = useSelector((state) => state.user.user)
    const [showAbout, setShowAbout] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false)
    const [showUserEdit, setShowUserEdit] = useState(false)
    const [DisplayName, setDisplayName] = useState(user.displayName)
    const [email, setEmail] = useState(user.email)
    const [feedback, setFeedback] = useState("");

    const dispatch = useDispatch()

    
    return <>
        <View style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.2)'}} onStartShouldSetResponder={() => dismiss(false)}>
        
        </View>
        <KeyboardAvoidingView behavior="position"  style={{ elevation: 3, position: 'absolute', bottom: 0, width: '100%' }} shadowOffset={{width: 0, height: -1}} shadowOpacity={0.3} shadowColor="black">
            <View style={{flex: 1, width: '100%', backgroundColor: '#ffffff', minHeight: 150, paddingHorizontal: 10, paddingVertical: 5, paddingBottom:40, borderTopLeftRadius: 10, borderTopRightRadius: 10, paddingTop: 10}} onStartShouldSetResponder={() => Keyboard.dismiss()}>
                {showFeedback ? <View>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <TouchableOpacity onPress={() => {setShowFeedback(false)}} style={{flex: 1}}>
                        <Feather name="chevron-left" size={25} color={"black"} style={{padding: 5}}/>
                    </TouchableOpacity>
                    <Text style={{flex: 1, textAlign: 'center', paddingTop: 4, fontSize: 18}}>Feedback</Text>
                    <View style={{flex: 1}}/>
                    </View>
                    <Spacer height={15}/>
                    <Text style={{textAlign: 'center', paddingHorizontal: 10}}>Have a suggestion for a feature? Found something not working right? Let us know below.</Text>
                    <Spacer height={15}/>
                    <TextInput
                    style={{maxHeight: Dimensions.get('window').height * 0.35}}
      value={feedback}
      textColor={colors.black}
      mode={'outlined'}
      underlineColor={colors.blue}
      activeOutlineColor={colors.blue}
      multiline={true}
      onChangeText={text => setFeedback(text)}
    />
    <Spacer height={15}/>
    <Btn label={"Submit"} fillWidth={true} onPress={() => {
        postFeedback(feedback)
        Alert.alert("Thank you for your submission!", "Our team is always looking to improve the functionality and experience of TelaWatch. We may contact you by email for more information.", [
            {
                text: "OK",
                onPress: () => {setShowFeedback(false); setFeedback("")}
            }
        ])
        
    }}/>
                    </View> : showAbout ? <View>
                        
                    <TouchableOpacity onPress={() => {setShowAbout(false)}}>
                        <Feather name="chevron-left" size={25} color={"black"} style={{padding: 5}}/>
                    </TouchableOpacity>
                    <View style={{height: 200, justifyContent: 'center', alignItems: 'center'}}>
                        <TWLogo size={20}/>
                        <Spacer height={15}/>
                        <Text>Copyright © 2011-2023, Cablesight, Inc.</Text>
                        </View>
                </View> : showUserEdit ? <View style={{justifyContent: 'center',}}>
                <TouchableOpacity onPress={() => {setShowUserEdit(false)}}>
                        <Feather name="chevron-left" size={25} color={"black"} style={{padding: 5}}/>
                    </TouchableOpacity>
                    
                    <View   style={{ justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{padding: 10, fontSize: 20}}>User Info</Text>
                    <InputField placeholder={"Name"} icon={"user"} inputState={DisplayName} setInputState={setDisplayName}/>
                    <Spacer height={10}/>
                    {
                        /*
                        <InputField placeholder={"Email"} icon={"mail"} inputState={email} setInputState={setEmail}/>
                    <Spacer height={10}/>*/
                    }
                    <Btn label="Update Info" onPress={async () => {
                        dispatch(reducers.user.setUser(Object.assign({}, user, {displayName: DisplayName})))
                        updateUserProfile(DisplayName, email).then(async () => {
                            
                            setShowUserEdit(false)
                        }).catch((e) => {
                            setShowUserEdit(false)
                        })
                        
                    }}/>

                    </View>

                </View> : <>
                <Text style={{fontSize: 23, paddingVertical: 10}}>Settings</Text>


                <Text>User Info</Text>
                <Spacer height={5}/>
                <TouchableOpacity onPress={() => setShowUserEdit(true)} style={{flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'space-around', backgroundColor: 'white', borderRadius: 7, paddingVertical: 10, elevation: 3, shadowOffset: {width: 0, height: 1}, shadowColor: 'black', shadowOpacity: 0.2}}>
                    <FontAwesome5 name="user-circle" size={30} color={"black"}/>
                    <View>
                    <Text>{DisplayName}</Text>
                    <Text>{user.email}</Text>
                    </View>
                    <Feather name="chevron-right" size={30} color={"black"}/>
                </TouchableOpacity>

                <Spacer height={20}/>

                <Text>Telawatch</Text>
                <Spacer height={5}/>
                <View shadowColor="Black" shadowOpacity={0.2} shadowRadius={3} shadowOffset={{elevation: 3, width: 0, height: 1}} style={{backgroundColor: 'white', padding: 7, borderRadius: 5}}>
                    <Text>Version -  {packageJSON.version}</Text>
                </View>

                <Spacer height={10}/>
                <TouchableOpacity onPress={() => {
                    setShowAbout(true)
                }} style={{elevation: 3, backgroundColor: 'white', padding: 7, borderRadius: 5, flexDirection: 'row', alignItems: 'center', shadowColor: 'black', shadowOpacity: 0.3, shadowOffset: {width: 0, height: 1}, shadowRadius: 3}}>
                    <Text style={{flex: 1}}>About</Text>
                    <Feather name="chevron-right" size={20} color={"black"}/>
                </TouchableOpacity>

                <Spacer height={10}/>

                <TouchableOpacity onPress={() => {
                    setShowFeedback(true)
                }} style={{elevation: 3, backgroundColor: 'white', padding: 7, borderRadius: 5, flexDirection: 'row', alignItems: 'center', shadowColor: 'black', shadowOpacity: 0.3, shadowOffset: {width: 0, height: 1}, shadowRadius: 3}}>
                    <Text style={{flex: 1}}>Feedback</Text>
                    <Feather name="chevron-right" size={20} color={"black"}/>
                </TouchableOpacity>

                <Spacer height={10}/>

                <TouchableOpacity onPress={async () => {confirmAction(async () => {
                    dispatch(reducers.sites.setSites(null))
                    dispatch(reducers.user.setUser(null))
                    dispatch(reducers.user.setUserLoaded(false))
                    await DeleteAccount()
                    signOutUser()
                    navigate("Landing")
                    dismiss(false)
                }
                )
                }} style={{elevation: 3, backgroundColor: 'white', padding: 7, borderRadius: 5, flexDirection: 'row', alignItems: 'center', shadowColor: 'black', shadowOpacity: 0.3, shadowOffset: {width: 0, height: 1}, shadowRadius: 3}}>
                    <Text style={{color: 'red', flex: 1}}>Delete Account</Text>
                    <Feather name="chevron-right" size={20} color={"black"}/>
                </TouchableOpacity>

                <Spacer height={15}/>

                <Button title="Sign Out" color={"red"} onPress={() => {
                    dispatch(reducers.sites.setSites(null))
                    
                    dismiss(false)
                    signOutUser()
                }}/>
                </>}
            </View>
        </KeyboardAvoidingView>
        </>
}