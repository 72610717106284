import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import { colors } from "../../../../config/globalStyle"

import { Spacer } from "../../../atoms/Spacer"
import { TapabbleFan } from "../../../atoms/TappableFan"

//TapabbleFan rpm={parseInt(rpm)} key={"c40fan" + i} />


export const FanSection = ({ fans, settings }) => {
    return <View style={{ flex: 1, justifyContent: 'space-around', paddingVertical: 10, alignItems: 'center' }}>
        <TapabbleFan fan={fans.LEFT} c100g={true} position="Left"  color={fans.LEFT.temp < settings.fanTemp.Lower ? colors.green : fans.LEFT.temp < settings.fanTemp.Upper ? colors.orange : colors.red} settings={settings.fanTemp}/>
        <TapabbleFan fan={fans.MIDDLE} c100g={true} position="Center" color={fans.LEFT.temp < settings.fanTemp.Lower ? colors.green : fans.LEFT.temp < settings.fanTemp.Upper ? colors.orange : colors.red} settings={settings.fanTemp}/>
        <TapabbleFan fan={fans.RIGHT} c100g={true} position="Right" color={fans.LEFT.temp < settings.fanTemp.Lower ? colors.green : fans.LEFT.temp < settings.fanTemp.Upper ? colors.orange : colors.red} settings={settings.fanTemp}/>
    </View>
}