import { StyleSheet } from "react-native";


export const colors = {
    red: '#ef3b33',
    lightred: '#FFBEBD',

    yellow: '#ffd002',
    lightyellow: '#f3eeb6',

    green: `#27ae60`,
    lightgreen: '#B6F3CD',

    grey: `#808080`,
    lightGrey: '#DCDCDD',

    blue: '#0379fb',
    lightblue: '#48bbb9',

    orange: '#ffd002',


    white: '#ffffff',
    black: '#000000'

}


export const globalStyle = StyleSheet.create({

})