import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { StyleSheet, Image, View, Text, TouchableOpacity, Button, Dimensions, Platform} from 'react-native'
import {FontAwesome, Entypo, Octicons, Feather} from '@expo/vector-icons'

import {Section} from  "../../molecules/Section"
import {OverviewSection} from '../../molecules/OverviewSection'
import {ActiveModems} from '../../molecules/ActiveModems'
import { Spacer } from "../../atoms/Spacer"
import dtccSrc from '../../../../assets/DTCC.png'

import { TempCircle } from "../../atoms/TempCircle"
import hardware from "../../../config/hardware"
import { mergeDeep } from "../../../Utilities"
import { UpstreamName } from "../../atoms/UpstreamName"
import { Btn } from "../../atoms/Btn"

import { colors } from "../../../config/globalStyle"
import * as reducers from '../../../redux_slices/reducers'

import ws_logger from '../../../logger'

const ModemSection = ({ cardSummary, showBreakdown, settings=hardware.UBR10012.defaultSettings}) => {
  const [color, setColor] = useState('grey')
    const getTotal = (cards) => {
      if (!cards) return { id: "Total", active: 0, total: 0 }
      let totalobj = cards.filter(card => card.id == "Total")
      if (totalobj.length == 0) {
        let total = 0
        let active = 0
        for (let card of cards) {
          total += card.total
          active += card.active
        }
        return { id: "Total", active: active, total: total }
      } else {
        return totalobj[0]
      }
    }

    useEffect(() => {
      let perc = Math.round(getTotal(cardSummary).active / getTotal(cardSummary).total * 100) / 100
      if (isNaN(perc)) {

      } else {
        let sets =  mergeDeep({}, hardware.UBR10012.defaultSettings, settings)
        if (sets.TotalModemCount.Lower > perc) setColor(colors.red)
        else if (sets.TotalModemCount.Upper > perc) setColor(colors.orange)
        else if (sets.TotalModemCount.Upper <= perc) setColor(colors.green)
      }
    }, [settings])
  
    return (
      <View style={{ alignItems: 'center', paddingTop: 15 }}>
        <ActiveModems activeModems={getTotal(cardSummary).active} totalModems={getTotal(cardSummary).total} ringColor={color}/>
        <Spacer height={7} />
        <Text style={{ fontSize: 16 }}>Service Group Summary</Text>
        <Spacer height={7} />
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
          {
            cardSummary ? cardSummary.map(card => {
              if (card.id == "Total") return <></>
              let perc = Math.round((card.active / card.total) * 100) / 100
              let color = perc < settings.SGModemCount.Lower ? colors.red : perc < settings.SGModemCount.Upper ? colors.orange : colors.green
              return <View style={{ flexDirection: 'row' }} key={card.id}>
                <FontAwesome name="circle" size={20} color={color}  />
                <Spacer width={5} key={card.id + "spacer"} />
              </View>
            }) : <></>
          }
        </View>
        <Spacer height={7} />
        <Btn label="Breakdown" onPress={() => { showBreakdown(true); ws_logger.log("Navigate", {pageName: "Modem Breakdown"}) }} />
      </View>
    )
  }
  

  const ServiceGroupRow = ({name, group, active, total, settings}) => {
    let perc = Math.round((active / total) * 100) / 100
    let color = perc < settings.SGModemCount.Lower ? colors.red : perc < settings.SGModemCount.Upper ? colors.orange : colors.green
    return <>
      <View elevation={3} style={{width: Platform.OS == "web" ? '60%' : '100%', elevation: 3, marginHorizontal: 3, flexDirection: 'row', flex: 1, padding: 7, borderRadius: 7,  backgroundColor: '#ffffff', shadowOffset: {height: 2, width: 0}, shadowColor: 'black', shadowOpacity: 0.13, shadowRadius:3 }}>
           <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', padding: 5}}>
          <FontAwesome name="circle" size={20} color={color}/>
          <Spacer width={9} />
          <View>
            {
              group === "Total" ? <Text>Total</Text> :  <UpstreamName name={name} port={group.substring(1)} />
            }
          
         
          {
            group !== "Total" ? <>
          <Spacer height={5}/>
          <Text>{group}</Text>
          </> : <></>
          }
          </View>
        </View>
        <ActiveModems activeModems={active} totalModems={total} ringColor={color} small={true}/>
        <Spacer width={5}/>
      </View>
      <Spacer height={7} />
      </>
  }


  const ModemBreakdown = ({ showBreakdown, cardSummary = [], names, usNames={}, settings=hardware.UBR10012.defaultSettings }) => {

    const getTotal = (cards) => {
      if (!cards) return { id: "Total", active: 0, total: 0 }
      let totalobj = cards.filter(card => card.id == "Total")
      if (totalobj.length == 0) {
        let total = 0
        let active = 0
        for (let card of cards) {
          total += card.total
          active += card.active
        }
        return { id: "Total", active: active, total: total }
      } else {
        return totalobj[0]
      }
    }

    if (!cardSummary) return <View>
      <Text>{JSON.stringify(cardSummary)}</Text>
    </View>
    return (
      <View>
        <TouchableOpacity onPress={() => {showBreakdown(false); ws_logger.log("Navigate", {pageName: "Device Home"})}} style={{padding: 7, flexDirection: 'row', alignItems: 'center', borderRadius: 7, borderWidth: 1, borderColor: '#eeeeee'}}>
            <FontAwesome name="chevron-left" size={15} color="rgba(0,0,0,0.5)"/>
            <Spacer width={10}/>
            <Text>Device Home</Text>
          </TouchableOpacity>
          <Spacer height={5}/>
          <View style={{justifyContent: 'center', alignItems: 'center', flex: 1}}>
        <Text style={{fontSize: 18}}>Service Group Breakdown</Text>
        <Spacer height={15}/>

        <ServiceGroupRow name={"Total"} group={"Total"} active={getTotal(cardSummary).active} total={getTotal(cardSummary).total} settings={settings}/>
        <Spacer height={15}/>

        {
          cardSummary.map((card, index) => {
            if (card.id == "Total") return <></>
            return <ServiceGroupRow name={usNames[card.id.substring(1)] ? usNames[card.id.substring(1)] : card.id.substring(1)} group={card.id} active={card.active} total={card.total} settings={settings} key={card.id + Math.round(Math.random() * 100)}/>
          })
        }
        </View>
      </View>
    )
  }
  
  const FanStatusSection = ({fanStatus}) => {
    let color;
    let message;

    if (fanStatus == 1) {
        color = colors.green;
        message = "Normal";
    }
    else if (fanStatus == 2) {
        color = colors.orange;
        message = "Warning";
    }
    else if (fanStatus == 3) {
        color = colors.red;
        message = "Critical";
    }
    else if (fanStatus == 4) {
        color = colors.red;
        message = "Shutdown";
    }
    else if (fanStatus == 5) {
        color = 'grey';
        message = "No fan installed";
    }
    else {
        color = colors.red;
        message = "Not functioning"
    }
    
    return (
      <View style={[styles.horiz, { alignItems: 'center', paddingVertical: 10}]}>
        <View style={{flex: 1, flexDirection: 'row-reverse'}}>
        <Image source={require('../../../../assets/fan.png')} style={[{maxWidth: 75, maxHeight: 75,tintColor: color, width: 75, height: 75}]} resizeMode="contain"/>
        </View>
        <Spacer width={20} />
        <Text style={{flex: 1.5, fontSize: 16, textAlign: 'center'}}>{message}</Text>
      </View>
    )
  }

  const ChassisTempSection = ({inlet, outlet, settings}) => {
    const inletF = ((inlet * 9) / 5) + 32
    const color = inletF < settings.Temp.InletLower ? colors.green : inletF < settings.Temp.InletUpper ? colors.orange : colors.red

    return (
      <View style={[styles.horiz, {paddingTop: 10, paddingBottom: 5}]}>
        <TempCircle label="Inlet" tempCels={inlet} color={color}/>
        <TempCircle label="Outlet" tempCels={outlet}/>
      </View>
    )
  }
  
  const DTCCUnit = ({active, description, serial, version, inactive}) => {
    const [pressed, press] = useState(false)
    return (
      <View style={{padding: 10, flex: 1, alignItems: 'center'}}>
      <TouchableOpacity onPress={() => {press(!pressed); ws_logger.log(!pressed ? "Expand" : "Contract", {item: "DTCC", id: serial})}} style={{maxWidth: 300, alignItems: 'center', borderWidth: 1, padding: 10, paddingTop: 15, borderRadius: 7, borderColor: inactive ? "grey" : active ? colors.green : colors.red}} disabled={inactive}>
        <Image style={{height: Dimensions.get('window').height / 15, width: Dimensions.get('window').width / 3.5}} source={dtccSrc} resizeMode="contain"/>
        
        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 7}}> 
        <FontAwesome name="circle" color={inactive ? "grey" : active ? colors.green : colors.red} size={10}/>
        <Spacer width={5}/>
        <Text>{inactive ? "Inactive" : active ? "Active" : "Error"}</Text>
        </View>

        {
        pressed ? <View style={{paddingTop: 7, alignItems: 'center'}}>
          <Text>{description}</Text>
          <Text>{serial}</Text>
          <Text>{version !== "-" ? `Version ${version}` : "-"}</Text>
          </View> : <></>
      }
      {
        inactive ? <></> : <View style={styles.triangleCorner}></View>
      }
      
      </TouchableOpacity>
      
      </View>
    )
  }

  const DTCCSection = ({data, DtccCount}) => {
    const [dtcc1, setdtcc1] = useState()
    const [dtcc2, setdtcc2] = useState()

    useEffect(() => {
      switch (data.length) {
        case 0: break;
        case 1:
          setdtcc1(data[0])
          break;
        case 2:
          data.map((dtcc, i) => {
            if (dtcc.description == "module 1/1") setdtcc1(dtcc)
            if (dtcc.description == "module 2/1") setdtcc2(dtcc)
          })
          break;
        default:
          break;
      }
      
    }, [])
    return (
      <View style={{flexDirection: 'row', transform: [{scale: Platform.OS == "web" ? 0.8 : 1}]}}>
        <DTCCUnit active={dtcc1 !== undefined} description={dtcc1 ? dtcc1.model : "-"} serial={dtcc1 ? dtcc1.serial : "-"} version={dtcc1 ? dtcc1.version : "-"}/>
        <DTCCUnit active={dtcc2 !== undefined} inactive={DtccCount == "1"} description={dtcc2 ? dtcc2.model : "-"} serial={dtcc2 ? dtcc2.serial : "-"} version={dtcc2 ? dtcc2.version : "-"}/>
      </View>
    )
  }


const SigQRow = ({ name, port, channelCount, card, cnr, mer, settings }) => {
  const getColor=(value, lower, upper, cnr=false) => {
    if (!value) return 'grey'
    return value < lower ? colors.red : value < upper ? colors.orange : colors.green
  }
  return (
    <>
      <View elevation={3} style={{shadowOpacity: 0.4, shadowRadius: 3, shadowColor: 'black', shadowOffset: {width: 0, height: 1}, marginHorizontal: 3, padding: 5, flexDirection: 'row', borderRadius: 5, backgroundColor: 'white', alignItems: 'center',  }}>
      
      <Spacer width={5}/>
        <View style={{flex: 2}}>
        <UpstreamName name={name} port={port} fontsize={17}/>
          <Text style={{fontSize: 12, color: "#c2c2c2"}}>{port} {card} {channelCount}ch</Text>

        </View>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View style={{ flex: 0.2 }}></View>
          
          <View style={{ flex: 1,}}>
            <View style={{ backgroundColor: getColor(mer, settings.SIGQMER.Lower, settings.SIGQMER.Upper), flex: 1, justifyContent: 'center', alignItems: 'center', borderRadius: 5 }}>
              <Text>{mer}</Text>
            </View>
          </View>
          <Spacer width={5} />

          <View style={{ flex: 1, }}>
            <View style={{ backgroundColor: getColor(cnr, settings.SIGQCNiR.Lower, settings.SIGQCNiR.Upper), flex: 1, justifyContent: 'center', alignItems: 'center', borderRadius: 5 }}>
              <Text>{cnr}</Text>
            </View>
          </View>


         
        </View>

      </View>
      <Spacer height={5} />
    </>
  )
}

const UpstreamQualitySection = ({sigQ, names, settings, usNames={}}) => {
  if (sigQ == "nodata") return <Text style={{ width: '100%', textAlign: 'center' }}>No Data</Text>
  const dispatch = useDispatch()
  return (
    <View style={{ marginTop: 5, }}>

      <View style={{ flexDirection: 'row', padding: 5, marginHorizontal: 3, paddingBottom: 10 }}>
        <View style={{ flex: 2 }}></View>
        <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }} onPress={() => {
          dispatch(reducers.nav.setShowInfo("MERCNR"))
        }}>
        <Feather name="info" size={15} color={colors.blue} />

          <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'center' }}>
            <Text style={{ textAlign: 'center' }}>MER</Text>
          </View>

          <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'center' }}>
            <Text style={{ textAlign: 'center' }}>CNR</Text>
          </View>
        </TouchableOpacity>
      </View>
      {
        sigQ.map((row, i) => {
          return <SigQRow settings={settings} name={usNames[row.card + "/" + row.port] ? usNames[row.card + "/" + row.port] : row.card + "/" + row.port} port={row.card + "/" + row.port} channelCount={row.chNum} card={row.model ? row.model : ""} cnr={row.avgCNIR} mer={row.avgMER} key={i + row.card}/>
        })
      }
    </View>
  )
}


const InventoryItem = ({entry, index}) => {
  const [tapped, tap] = useState(false)
  return <TouchableOpacity onPress={() => {
    tap(!tapped)
    ws_logger.log(!tapped ? "Expand" : "Contract", {item: "Inventory Item", id: entry})
  }} style={{ marginBottom: 5 }}>
  <View style={{ shadowOpacity: 0.3, shadowRadius: 3, shadowColor: 'black', shadowOffset: {width: 0, height: 1}, elevation: 3, backgroundColor: 'white', padding: 7, borderRadius: 5 }}>

    <View style={{ flexDirection: 'row', flex: 1 }}>
      <Text style={{ fontSize: 13, width: 20 }}>{index + 1}.</Text>
      <Text style={{ flex: 1, fontSize: 13 }}>{entry.model}</Text>
      <Spacer width={3}/>
      <Text style={{ flex: 1, fontSize: 13 }}>{entry.description}</Text>
    </View>
    {tapped ? <>
      <View style={{ flexDirection: 'row', flex: 1, paddingTop: 3 }}>
        <Spacer width={20} />
        <Text style={{ flex: 1, fontSize: 13 }}>{entry.serial}</Text>

        <Text style={{ flex: 1, fontSize: 13 }}>{entry.version}</Text>
      </View>
    </> : <></>
    }

    <View style={styles.triangleCorner}></View>
  </View>
</TouchableOpacity>
}


const InventorySection = ({ data }) => {


  return <View style={{ paddingVertical: 10 }}>
    {
      data ? data.map((entry, index) => {
        if (entry.description == "module 1/1" || entry.description == "module 2/1") return <></>
        else return <InventoryItem key={entry.description + index + "inv"} entry={entry} index={index}/>
      }) : <></>
    }
  </View>
}

export const UBR10012Display = ({ metrics, data, lincrID, selectedOrder}) => {
    if (!metrics) return <></>
    const [showBreakdown, setShowBreakdown] = useState(false)
    const user = useSelector((state) => state.user.user)
    const settings = metrics[user.uid] ? mergeDeep({}, hardware.UBR10012.defaultSettings, metrics[user.uid]) : hardware.UBR10012.defaultSettings

    const Sections = {
      "Hardware Status": <Section title="Hardware Status" settings={settings} lincrID={lincrID}>
      <OverviewSection serial={metrics.serial} uptime={metrics.uptime} status={data.Active} type={data.Type} />
    </Section>,
    "Active Modems": <Section title="Active Modems" settings={settings} lincrID={lincrID}>
    <ModemSection cardSummary={metrics.ModemsByCard} showBreakdown={setShowBreakdown} settings={settings}/>
  </Section>,
"DTCC / TCC+-T1 Status": <Section title="DTCC / TCC+-T1 Status" settings={settings} lincrID={lincrID} extraOpts={metrics.DtccCount ?? "2"}>
<DTCCSection data={metrics.Inventory ? metrics.Inventory.filter(item => item.model === 'UBR10-DTCC' || item.model === 'UBR10-TCC+-T1') : []} DtccCount={metrics.DtccCount ?? "2"}/>
</Section>,
"Chassis Temperature": <Section title="Chassis Temperature" settings={settings} lincrID={lincrID}>
<ChassisTempSection inlet={metrics.inletTemp} outlet={metrics.outletTemp} settings={settings}/>
</Section>,
"Fan Status": <Section title="Fan Status" settings={settings} lincrID={lincrID}>
<FanStatusSection fanStatus={metrics.fanStatus}/>
</Section>,
"Upstream Signal Quality MER & CNR":  <Section title="Upstream Signal Quality MER & CNR" settings={settings} lincrID={lincrID}>
<UpstreamQualitySection settings={settings} sigQ={metrics.SignalQuality ? metrics.SignalQuality.map(sig => {
let j = Object.assign({}, sig)
let inventoryItem = metrics.Inventory.filter(item => item.description.includes(sig.card))
if (inventoryItem) j.model = inventoryItem[0].model
return j
}) : "nodata"} usNames={metrics.usPortNames} names={metrics.ModemsByCardNames ? metrics.ModemsByCardNames : []} inventory={metrics.Inventory}/>
</Section>,
"Inventory":  <Section title="CMTS Components" hideSettings={true} settings={settings} lincrID={lincrID}>
<InventorySection data={metrics.Inventory ? metrics.Inventory : []}/>
</Section>
    }

    if (Object.keys(metrics).length == 0) return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
    <Text>No data reported by Lincr</Text>
  </View>

    return (
      <View>
        {
          showBreakdown ? <ModemBreakdown showBreakdown={setShowBreakdown}  cardSummary={metrics.ModemsByCard} names={metrics.ModemsByCardNames} usNames={metrics.usPortNames} settings={settings}/> : <>
            
           {
             selectedOrder ? selectedOrder.map((section) => {
              return Sections[section]
          }) : Object.keys(Sections).map((section) => Sections[section])
           }
          </>
        }
  
      </View>
    )
  }

  const styles = StyleSheet.create({
    triangleCorner: {
      width: 0,
     height: 0,
     backgroundColor: 'transparent',
     borderStyle: 'solid',
     borderTopWidth: 0,
     borderRightWidth: 0,
     borderBottomWidth: 15,
     borderLeftWidth: 15,
     borderTopColor: 'transparent',
     borderRightColor: 'transparent',
     borderBottomColor: colors.blue,
     borderLeftColor: 'transparent',
       position: 'absolute',
       right: 0,
       bottom: 0,
    },
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: '#000',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 18,
      lineHeight: 23,
      flex: 2
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    },
    appButtonContainer: {
      elevation: 8,
      backgroundColor: "#009688",
      borderRadius: 10,
      paddingVertical: 10,
      paddingHorizontal: 12
    },
    appButtonText: {
      fontSize: 18,
      color: "#fff",
      fontWeight: "bold",
      alignSelf: "center",
      textTransform: "uppercase"
    },
    spaceEvenly: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    bodyText: {
      fontSize: 14,
      color: "grey"
    },
    greenHeader: {
      fontSize: 16,
      color: "green"
    },
    redHeader: {
      fontSize: 16,
      color: "red"
    },
    greyHeader: {
      fontSize: 16,
      color: "grey"
    },
    error: {
      color: 'red',
      fontSize: 16
    },
    activeModems: {
      justifyContent: 'center',
      flexDirection: 'row'
    },
    modemData: {
      justifyContent: 'space-evenly',
      marginLeft: 10
    }
  });
  