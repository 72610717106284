import { useState } from "react"
import { Text, View,} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";
import RangeSlider from "../../../atoms/RangeSlider";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";
import { colors } from "../../../../config/globalStyle";




export const TemperatureSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [tempSlider, setTempSlider] = useState()
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
   
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    const [tempAlarmMajor, setTempAlarmMajor] = useState(settings.ModuleTemp.MajorAlarm);
    const [tempAlarmMinor, setTempAlarmMinor] = useState(settings.ModuleTemp.MinorAlarm);
    
    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            if (tempSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.ModuleTemp.Lower`] = tempSlider.min 
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.ModuleTemp.Upper`] = tempSlider.max
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.ModuleTemp.MajorAlarm`] = tempAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.ModuleTemp.MinorAlarm`] = tempAlarmMinor

            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{paddingBottom: 10}}>
            <RangeSlider min={60} max={140} step={1} onValueChange={setTempSlider} units="˚F" label="Module Temperature Thresholds" defaultValue={{min: (settings.ModuleTemp.Lower - 60) / 80, max: (settings.ModuleTemp.Upper - 60) / 80}} colors={["green", "orange", "red"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={tempAlarmMajor} onValueChange={(val) => setTempAlarmMajor(val)} style={{borderColor: "grey"}} level={2}/>
            <ToggleLabel title="Major Alarm" value={tempAlarmMinor} onValueChange={(val) => setTempAlarmMinor(val)} style={{borderColor: "grey"}} level={1}/>
            </View>

            </View>
        </View>
    )
}