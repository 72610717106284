import { useState, useEffect } from "react"
import { Text, TouchableOpacity, View, Button } from "react-native";
import { Feather } from '@expo/vector-icons';
import { Spacer } from "./Spacer";
import { useDispatch, useSelector } from "react-redux";
import {InputField} from './InputField'

import * as reducers from '../../redux_slices/reducers'
import { saveUSPortNames } from "../../Firebase";
import { Btn } from "./Btn";

import ws_logger from '../../logger'

export const UpstreamName = ({ name, port, fontsize=15, style }) => {
  const dispatch = useDispatch()
    return (
      <TouchableOpacity style={[{flex: 1, flexDirection: 'row', alignItems: 'center'}, style]} onPress={() => dispatch(reducers.nav.showNameModal({name: name, port: port}))}>
        <Feather name="edit" color='black' size={15}/>
        <Spacer width={3}/>
      <Text style={{fontSize: fontsize}}>{name ? name : port}</Text>
      </TouchableOpacity>
    )
  }

  export const UpstreamNameModal = ({lincrID}) => {
    const dispatch = useDispatch()
    const usData = useSelector((state) => state.nav.upstreamModalVisible)
    const [field, setField] = useState(usData ? usData.name ? usData.name : usData.port : "")
    const [deviceName, setDeviceName] = useState("")

    const site = (useSelector((state) => state.sites.sites)).filter(s => s.lincrID == lincrID)[0]
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)

    useEffect(() => {
      setDeviceName(JSON.parse(selectedDevice).DisplayName)
      ws_logger.log("Show Modal", {name: "Set Upstream Name Modal", data: usData})
    }, [])

    return (
      <>

        <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.2)' }} onStartShouldSetResponder={() => dispatch(reducers.nav.showNameModal(null))}></View>
        <View style={{ position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }} pointerEvents="box-none">
          <View style={{ backgroundColor: 'white', width: '80%', height: 150, borderRadius: 10 }}>

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 7, borderBottomWidth: 1, borderColor: '#eeeeee', }}>
              <Text style={{ flex: 1, fontSize: 17, fontWeight: '500', textAlign: 'center', marginLeft: 0, paddingVertical: 5 }}>Port {usData ? usData.port : ""}</Text>
              <View style={{ position: 'absolute', right: 5 }}>
                <Btn label="Save" onPress={() => {
                  // Save Changes to DB
                  saveUSPortNames(lincrID, deviceName, usData.port, field, site[deviceName].usPortNames)
                  dispatch(reducers.nav.showNameModal(null)) 
                  ws_logger.log("Save", {name: "Save Upstream Name", data: usData})
                }
                  } />
              </View>
            </View>

            <View style={{padding: 10, flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: -10}}>
              <Text style={{fontSize: 16, width: '75%'}}>Group Name</Text>
              <Spacer height={10}/>
              <InputField placeholder={"Upstream Group Name"} inputState={field} setInputState={setField}/>
            </View>

          </View>
        </View>
      </>
    )
  }