import { useState } from "react"
import { Text, View,} from "react-native"
import ToggleLabel from "../../../atoms/ToggleLabel";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";


export const FanSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)

    
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const settings = sectionData.settings
    const dispatch = useDispatch()
    const [fanAlarm, setFanAlarm] = useState(settings.Temp.FanAlarm);

    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))
            //console.log("Saving UBR10012 Fan Settings - ") 
            let obj = {}
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.Temp.FanAlarm`] = fanAlarm

            UpdateSiteSettings({setObj: obj, id: sectionData.lincrID})
        }
    }, [settingsSaved])

    return (
        <View>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <ToggleLabel title="When a fan has a problem" value={fanAlarm} onValueChange={(val) => setFanAlarm(val)} />
        </View>
    )
}