import React, { useState } from 'react'
import { View, Text, Modal, StyleSheet, SafeAreaView, TouchableOpacity, useWindowDimensions, ScrollView } from 'react-native'
import { DragSortableView, AutoDragSortableView } from 'react-native-drag-sort'
import { Entypo, Feather } from '@expo/vector-icons';
import { Spacer } from '../components/atoms/Spacer';
import { useDispatch } from 'react-redux';
import * as reducers from '../redux_slices/reducers'
import { colors } from '../config/globalStyle';

function uuid() {
    return "order_key_" +Date.now()
}

function Item({ title, width, height }){
    return (
        <>
        <View style={[styles.item, { height, width, flexDirection: 'row', alignItems: 'center', justifyContent:'space-between' }]}>
            <Text style={{ flex: 1 }}>{title}</Text>
            <Entypo name="dots-three-vertical" size={20} color="black" />
        </View>
        <Spacer height={10}/>
        </>
    )
}


export const DragSortCards = ({deviceType, onChange, order}) => {
    const { width } = useWindowDimensions()
    const parentWidth = width * .75
    const childrenWidth = width * .62

    const dispatch = useDispatch()

    function renderItems(item, i){
        return (
            <Item
                title={item}
                width={childrenWidth}
                height={40}
            />
        )
    }

    return <View style={styles.page}>
    <View style={styles.title}>
        <Text style={{ fontSize: 18, paddingVertical: 15, width: '80%', textAlign: 'center' }}>Drag to Reorder Sections</Text>
        <TouchableOpacity style={{position: 'absolute', right: 0}} onPress={() => dispatch(reducers.nav.showReorderModal(null))}>
        <Feather name='x' size={20} color={colors.black}/>
        </TouchableOpacity>
    </View>
    <View style={styles.orderContainer}>
    <AutoDragSortableView
            dataSource={order}
            parentWidth={parentWidth}
            childrenWidth={childrenWidth}
            marginChildrenTop={5}
            marginChildrenBottom={15}
            marginChildrenLeft={20}
            marginChildrenRight={20}
            childrenHeight={30}
            delayLongPress={1}
            keyExtractor={(item) => item}
            renderItem={(item, index) => {
                return renderItems(item, index)
            }}
            onDataChange={(data) => onChange(data)}
        />
    </View>
</View>
}

/*export default function DragSortCards({ visible=false, componentOrder, onChangeOrder, onClose }) {
    const [order,setOrder] = useState(componentOrder)
    const { width } = useWindowDimensions()
    const parentWidth = width * .85
    const childrenWidth = width * .75

    function renderItems(item, i){
        return (
            <Item
                title={ 'n/a'}
                width={childrenWidth}
                height={60}
            />
        )
    }

    function onSaveChanges(){
        if (order.length) onChangeOrder(order)
        onClose()
    }

    return (
        <Modal
            visible={visible}
            animationType="fade"
            transparent={true}
            style={{ backgroundColor:'red'}}
        >
            <SafeAreaView style={styles.page}>
                    <View style={styles.title}>
                        <Text style={{ fontSize: 18, fontWeight: 'bold' }}>Drag and Drop to Reorder</Text>
                    </View>
                    <View style={styles.orderContainer}>
                        <AutoDragSortableView
                            dataSource={componentOrder}
                            parentWidth={parentWidth}
                            childrenWidth= {childrenWidth}
                            marginChildrenTop={10}
                            childrenHeight={60}
                            keyExtractor={(item)=> item.componentId !== 'N/A' ? item.componentId : uuid()}
                            renderItem={(item,index)=>{
                                return renderItems(item, index)
                            }}
                            onDataChange={(data)=>setOrder(data)}
                        />
                    </View>
                <TouchableOpacity style={styles.save}
                    onPress={onSaveChanges}
                >
                    <Text>Save</Text>
                </TouchableOpacity>
            </SafeAreaView>
        </Modal>
    )
}*/

const styles = StyleSheet.create({
    page:{
        backgroundColor: 'rgba(0,0,0,0)',
        overflow: 'hidden',
    },
    orderContainer:{
        width: '100%',
        backgroundColor: 'white',
        marginLeft:5,
        marginRight:'auto',
        padding: 10,
        minHeight: '80%',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
    },
    item:{
        justifyContent:'center',
        padding: 10,
        backgroundColor:'lightgrey',
        height: 60,
        borderRadius: 10,
        marginLeft:'auto',
        marginRight:'auto',
    },
    save:{
        height:0,
        width: '50%',
        backgroundColor:'white',
        marginLeft:'auto',
        marginRight: 'auto',
        marginBottom:'auto',
        justifyContent:'center',
        alignItems: 'center',
        borderRadius: 10,
        marginTop: 20,
    },
    title:{
        backgroundColor:'white',
        // height: 40,
        justifyContent:'center',
        alignItems: 'center',
        marginTop: 'auto',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        flexDirection: 'row',
    }
})
