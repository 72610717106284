import { colors } from "./config/globalStyle";
import { Alert } from "react-native";
import * as Application from 'expo-application';
import { Platform } from 'expo-modules-core';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from 'expo-constants';
import uuid from 'react-native-uuid';


export function secondsToDateTime(seconds) {
    // seconds *= 10.0;
    let addSeconds = false

    // get num of days
    var days = Math.floor(seconds / 86400);

    // subtract days from seconds
    seconds = seconds - (days * 86400);

    // get hours
    var hours = Math.floor(seconds / 3600);

    // subtract hours from seconds
    seconds = seconds - (hours * 3600);

    // get minutes
    var minutes = Math.floor(seconds / 60);

    seconds = Math.round(seconds - (minutes * 60))

    if (hours == 0 && minutes == 0 && seconds !== 0) addSeconds = true

    // return concatenaded string
    var DateTime = days + 'd:' + hours + 'h:' + minutes + 'm' + (addSeconds ? (":" + seconds + "s") : "");

    return DateTime;
}

export const getDeviceId = async () => {
  console.log("getDeviceId");
  if (Platform.OS === 'android') {
    return Application.androidId;
  } else {
    var deviceId = ""
    try {
    deviceId = await AsyncStorage.getItem('duid');
    if (deviceId == null) {
      deviceId = uuid.v4()
      
      await AsyncStorage.setItem('duid', deviceId);
    }
    } catch(e) {
      deviceId = uuid.v4()
      
      await AsyncStorage.setItem('duid', deviceId);
    }

    console.log(deviceId)
    return deviceId;
  }
}

export const repeat = (arr, n) => [].concat(...Array(n).fill(arr));

export const getColorfromDF = (df) => {
    switch (df) {
      case 0:
      case 1:
      case 2:
        return "green"
      case 3:
      case 4:
      case 5:
        return "orange"
      case 6:
      case 7:
      case 8:
        return "orange"
      case 9:
      case 10:
      case 11:
        return "red"
      default:
        return "grey"
    }
  }

  export function objtodotnotation(obj, current) {
    for(var key in obj) {
      var value = obj[key];
      var newKey = (current ? current + "." + key : key);  // joined key with dot
      if(value && typeof value === "object") {
        recurse(value, newKey);  // it's a nested object, so do it again
      } else {
        res[newKey] = value;  // it's not an object, so set the property
      }
    }
  }

  export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }
  
  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
  export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();
  
    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  
    return mergeDeep(target, ...sources);
  }


  export function confirmAction(callback) {
    Alert.alert(
      'Confirm',
      'Are you sure you want to proceed?',
      [
        {
          text: 'Cancel',
          onPress: () => {},
          style: 'cancel',
        },
        {text: 'OK', onPress: () => {callback()}},
      ],
      {cancelable: false},
    );
  }