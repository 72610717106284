import { View, Text, Modal, TouchableOpacity, StyleSheet, ScrollView } from "react-native"
import { Section } from "../../molecules/Section"
import { OverviewSection } from "../../molecules/OverviewSection"

import { ProgressBar } from "../../atoms/ProgressBar"
import { TempCircle } from "../../atoms/TempCircle"
import { useState } from 'react'
import { Spacer } from "../../atoms/Spacer"
import { useSelector } from "react-redux"

import { TapabbleFan } from "../../atoms/TappableFan"

import hardware from "../../../config/hardware"
import { colors } from "../../../config/globalStyle"
import { mergeDeep } from "../../../Utilities"
import { Btn } from "../../atoms/Btn"
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { UpdateSiteSettings } from "../../../Firebase"

import ws_logger from '../../../logger'

const GBERow = ({ percent, color, index }) => {
    return (
        <View style={{ flexDirection: 'row', padding: 2 }}>
            <Text style={{ fontWeight: 'bold', width: 20 }}>{index}</Text>
            <Text style={{ width: 40 }}>{Math.round(percent * 100)}%</Text>
            <ProgressBar percent={percent} disabled={percent == 0} color={color} />
        </View>
    )
}

const GBESection = ({ gbes, settings }) => {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ color: "#c2c2c2", transform: [{ rotate: '270deg' }], width: 40, height: 20 }}>  Port</Text>
            <View style={{ flex: 1 }}>
                <GBERow percent={gbes[0]} index={1} color={gbes[0] >= settings.GBE.Upper ? "red" : gbes[0] >= settings.GBE.Lower ? "yellow" : "green"} />
                <GBERow percent={gbes[1]} index={2} color={gbes[1] >= settings.GBE.Upper ? "red" : gbes[1] >= settings.GBE.Lower ? "yellow" : "green"} />
                <GBERow percent={gbes[2]} index={3} color={gbes[2] >= settings.GBE.Upper ? "red" : gbes[2] >= settings.GBE.Lower ? "yellow" : "green"} />
                <GBERow percent={gbes[3]} index={4} color={gbes[3] >= settings.GBE.Upper ? "red" : gbes[3] >= settings.GBE.Lower ? "yellow" : "green"} />
            </View>
        </View>
    )
}


const QAMItem = ({ card, port, cards, names, showAssoc, sgs }) => {
    const [flipped, flipCard] = useState(false)
    const thisName = names ? names[card + "-" + port] || " - " : " - "
    const readableName = sgs.reduce((acc, curr) => {
        if (curr.sgNames[thisName]) acc = curr.sgNames[thisName]
        return acc
    }, " - ")

    return <TouchableOpacity onLongPress={() => {
        showAssoc(card + "-" + port)
        ws_logger.log("Show Modal", {name: "Set QAM Association", id: card + "-" + port})
    }} onPress={() => {
        flipCard(!flipped)
        ws_logger.log("Tap", {item: "QAM Output Item", id: card + "-" + port})
    }} style={{ flex: 1, alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row', borderRadius: 5, }}>
        <View style={{ backgroundColor: cards[port].sum == 0 ? colors.lightGrey : cards[port].color, flex: 1, position: 'absolute', borderRadius: 5, width: '100%', height: '100%', top: 0, right: 0, bottom: 0 }}>

        </View>
        {
            flipped ? <>
                <View style={{ alignItems: 'center', justifyContent: 'space-around', flexDirection: 'column', padding: 5, flex: 1 }}>
                    <Text style={{ flex: 1, textAlign: 'center' }}>{readableName}</Text>
                    <Text style={{ flex: 1, textAlign: 'center' }}>{thisName}</Text>
                </View>
            </>
                : <>

                    <View style={{ alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row', padding: 5, flex: 1, paddingVertical: 13.5 }}>
                        <Text style={{ flex: 1, textAlign: 'center' }}>{Math.round(cards[port].sum * 100)}%</Text>
                        <Text style={{ flex: 1, textAlign: 'center' }}>{Math.round(cards[port].max * 100)}%</Text>
                        <Text style={{ flex: 1, textAlign: 'center' }}>ch{cards[port].maxI}</Text>
                    </View>
                </>
        }
        <View style={styles.triangleCorner}></View>
    </TouchableOpacity>
}


const QAMRow = ({ cards, i, assoc, showAssoc, sgs }) => {
    return <View style={{ flex: 1, flexDirection: 'row', padding: 2, alignItems: 'flex-start' }}>
        <View style={{ flex: 0.3, alignItems: 'center' }}>
            <Text style={{ fontWeight: 'bold' }}>{i + 1}</Text>
        </View>

        <QAMItem card={i} port={0} cards={cards} names={assoc} showAssoc={showAssoc} sgs={sgs} />
        <Spacer width={4} />
        <QAMItem card={i} port={1} cards={cards} names={assoc} showAssoc={showAssoc} sgs={sgs} />


    </View>
}

const QAMOutputSection = ({ cardBand, cardMaxBand, cardSesh, settings, cardsConfigured, qamInfo, showAssoc, showVal, sgs }) => {
    function sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }


    let cards96 = cardSesh.map((sesh, i) => {
        let perc = cardMaxBand[i] == 0 ? 0 : (cardBand[i] / cardMaxBand[i])
        return {
            perc: perc,
            active: sesh == "1"
        }
    })
    let qamchunks = sliceIntoChunks(sliceIntoChunks(cards96, 8).map(cardport => {
        let reduction = cardport.reduce((acc, curr, index) => {
            let strfloat = isNaN(parseFloat(curr.perc)) ? 0 : parseFloat(curr.perc)
            return {
                sum: (acc.sum ?? 0) + strfloat,
                max: strfloat > acc.max ?? 0 ? strfloat : acc.max ?? 0,
                maxIndex: strfloat > acc.max ?? 0 ? index : acc.maxIndex ?? 0
            }
        })
        reduction.sum = reduction.sum / 8
        return reduction
    }).map(itemgroup => {
        let avgUtil = Math.round(itemgroup.sum * 100) / 100
        return {
            sum: avgUtil,
            color: avgUtil < settings.QAMOutput.Lower ? colors.green : avgUtil < settings.QAMOutput.Upper ? colors.orange : colors.red,
            max: Math.round(itemgroup.max * 100) / 100,
            maxI: itemgroup.maxIndex
        }
    }), 2)
    return <View>
        <Spacer height={10} />
        <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 0.3 }}></View>
            <View style={{ flex: 1, alignItems: 'center' }}><Text style={{ fontWeight: 'bold' }}>Port 1</Text></View>
            <View style={{ flex: 1, alignItems: 'center' }}><Text style={{ fontWeight: 'bold' }}>Port 2</Text></View>
        </View>
        <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 0.3, alignItems: 'center' }}><Text>Card</Text></View>
            <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row', marginBottom: 3 }}>
                <Text style={{ flex: 1, textAlign: 'center', fontSize: 13 }}>avg</Text>
                <Text style={{ flex: 1, textAlign: 'center', fontSize: 13 }}>max</Text>
                <Text style={{ flex: 1, textAlign: 'center', fontSize: 13 }}>max ch</Text>
            </View>
            <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row', marginBottom: 3 }}>
                <Text style={{ flex: 1, textAlign: 'center', fontSize: 13 }}>avg</Text>
                <Text style={{ flex: 1, textAlign: 'center', fontSize: 13 }}>max</Text>
                <Text style={{ flex: 1, textAlign: 'center', fontSize: 13 }}>max ch</Text>
            </View>
        </View>
        {
            qamchunks.map((card, i) => {
                return <QAMRow key={i} cards={card} i={i} assoc={qamInfo} showAssoc={showAssoc} sgs={sgs} />
            })
        }
        <Spacer height={10} />
    </View>
}

const AssociateModal = ({ dismiss, sgs, selected, qamInfo, device, lincrID }) => {
    let [card, port] = selected.split("-") ? selected.split("-") : ["", ""]
    const [selectedSG, selectSG] = useState(qamInfo ? {
        device: sgs[0].DisplayName,
        port: qamInfo[`${card}-${port}`],
        sgname: sgs[0]?.sgNames[qamInfo[`${card}-${port}`]],
    } : {
        device: sgs[0].DisplayName,
        port: `${card}-${port}`,
        sgname: sgs[0]?.sgNames[`${card}-${port}`],
    })
    return <>
        <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.2)' }} onStartShouldSetResponder={() => {
            dismiss()
            ws_logger.log("Navigate", {pageName: "Device Home"})
        }}>

        </View>
        <View behavior="position" elevation={3} style={{ position: 'absolute', bottom: 0, width: '100%' }} shadowOffset={{ width: 0, height: -1 }} shadowOpacity={0.3} shadowColor="black">
            <View style={{ flex: 1, width: '100%', backgroundColor: '#ffffff', minHeight: 150, maxHeight: 500, paddingHorizontal: 10, paddingVertical: 5, paddingBottom: 20, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', borderBottomWidth: 1, borderColor: '#c2c2c2', marginBottom: 10, paddingBottom: 2, marginTop: 3 }} >
                    <Text style={{ flex: 1, fontSize: 17, fontWeight: '500', textAlign: 'center', marginLeft: 0, paddingBottom: 3 }}>Associate QAM</Text>
                    <TouchableOpacity onPress={() => { dismiss();  ws_logger.log("Navigate", {pageName: "Device Home"}) }} style={{ position: 'absolute', right: 7, top: 0 }}>
                        <MaterialCommunityIcons name="window-close" size={21} color="black" />
                    </TouchableOpacity>


                </View>

                <Text style={{ fontSize: 18 }}>Card {parseInt(card) + 1} Port {parseInt(port) + 1} ={'>'} {selectedSG?.port} {selectedSG?.sgname ? "(" + selectedSG?.sgname + ")" : ""}</Text>
                <Spacer height={10} />

                <ScrollView showsVerticalScrollIndicator={false}>
                    {
                        sgs.sort((a, b) => a.DisplayName < b.DisplayName).map(device => {
                            return <>
                                <Text>{device.DisplayName}</Text>
                                <Spacer height={5} />
                                {
                                    Object.keys(device.sgNames).sort((a, b) => a < b).map(sgPort => {
                                        return <TouchableOpacity style={{ marginBottom: 5, padding: 7, borderRadius: 5, borderWidth: 1, borderColor: selectedSG?.port == sgPort ? "white" : "black", flexDirection: 'row', alignItems: 'center', backgroundColor: selectedSG?.port == sgPort ? colors.blue : 'white' }} onPress={() => {
                                            selectedSG?.port !== sgPort ? selectSG({ device: device.DisplayName, port: sgPort, sgname: device.sgNames[sgPort] }) : selectSG(null)
                                        }}>
                                            <Text style={{ flex: 1 }}>{sgPort}</Text>
                                            <Text style={{ flex: 1 }}>{device.sgNames[sgPort]}</Text>
                                        </TouchableOpacity>
                                    })
                                }
                            </>
                        })
                    }
                </ScrollView>
                <Spacer height={10} />
                <Btn label={"Save"} fillWidth={true} onPress={() => {
                    let newQamInfo = Object.assign({}, qamInfo, { [`${card}-${port}`]: selectedSG?.port })
                    if (!selectedSG?.port) {
                        delete newQamInfo[`${card}-${port}`]
                    }
                    let obj = {}
                    obj[`${device.DisplayName}.qamInfo`] = newQamInfo
                    UpdateSiteSettings({ setObj: obj, id: lincrID })
                    dismiss()
                    ws_logger.log("Save", {id: "QAM Association", obj})
                }} />
            </View>
        </View>
    </>
}

export const RFGW1Display = ({ metrics, data, lincrID, selectedOrder, sgs }) => {
    const user = useSelector((state) => state.user.user)
    const settings = metrics[user.uid] ? mergeDeep({}, hardware.RFGW1.defaultSettings, metrics[user.uid]) : hardware.RFGW1.defaultSettings
    const [showQAMAssoc, setShowQAMAssoc] = useState()

    const CtoF = (celsius) => {
        return ((celsius * 9) / 5) + 32
    }

    const Sections = {
        "Hardware Status": <Section title="Hardware Status" settings={settings} lincrID={lincrID}>
            <OverviewSection serial={metrics.serial} uptime={metrics.uptime} status={data.Active} type={data.Type} />
        </Section>,
        "GBE Input": <Section title="GBE Input" settings={settings} lincrID={lincrID}>
            <GBESection gbes={[metrics.gbeP1, metrics.gbeP2, metrics.gbeP3, metrics.gbeP4].map(x => x / 1000000000)} settings={settings} />
        </Section>,
        "QAM Output": <Section title="QAM Output" settings={settings} lincrID={lincrID} extraOpts={metrics.CardsInserted} >
            <QAMOutputSection cardBand={metrics.cardBandwidth} cardMaxBand={metrics.cardMaxBandwidth} cardSesh={metrics.cardSessions} settings={settings} cardsConfigured={metrics.CardsInserted} qamInfo={metrics.qamInfo} showVal={showQAMAssoc} showAssoc={setShowQAMAssoc} sgs={sgs} />
        </Section>,
        "Temperature": <Section title="Temperature" settings={settings} lincrID={lincrID}>
            <View style={{ flexDirection: 'row', paddingVertical: 10 }}>
                <TempCircle small={true} label="QAM 1" tempCels={parseInt(metrics.qam1Temp)} color={metrics.qam1Temp == 0 ? colors.grey : CtoF(metrics.qam1Temp) > settings.QAMTemp.Upper ? colors.red : CtoF(metrics.qam1Temp) > settings.QAMTemp.Lower ? colors.orange : colors.green} />
                <TempCircle small={true} label="QAM 2" tempCels={parseInt(metrics.qam2Temp)} color={metrics.qam2Temp == 0 ? colors.grey : CtoF(metrics.qam2Temp) > settings.QAMTemp.Upper ? colors.red : CtoF(metrics.qam2Temp) > settings.QAMTemp.Lower ? colors.orange : colors.green} />
                <TempCircle small={true} label="QAM 3" tempCels={parseInt(metrics.qam3Temp)} color={metrics.qam3Temp == 0 ? colors.grey : CtoF(metrics.qam3Temp) > settings.QAMTemp.Upper ? colors.red : CtoF(metrics.qam3Temp) > settings.QAMTemp.Lower ? colors.orange : colors.green} />
            </View>

            <View style={{ flexDirection: 'row' }}>
                <TempCircle small={true} label="QAM 4" tempCels={parseInt(metrics.qam4Temp)} color={metrics.qam4Temp == 0 ? colors.grey : CtoF(metrics.qam4Temp) > settings.QAMTemp.Upper ? colors.red : CtoF(metrics.qam4Temp) > settings.QAMTemp.Lower ? colors.orange : colors.green} />
                <TempCircle small={true} label="QAM 5" tempCels={parseInt(metrics.qam5Temp)} color={metrics.qam5Temp == 0 ? colors.grey : CtoF(metrics.qam5Temp) > settings.QAMTemp.Upper ? colors.red : CtoF(metrics.qam5Temp) > settings.QAMTemp.Lower ? colors.orange : colors.green} />
                <TempCircle small={true} label="QAM 6" tempCels={parseInt(metrics.qam6Temp)} color={metrics.qam6Temp == 0 ? colors.grey : CtoF(metrics.qam6Temp) > settings.QAMTemp.Upper ? colors.red : CtoF(metrics.qam6Temp) > settings.QAMTemp.Lower ? colors.orange : colors.green} />
            </View>
        </Section>,
        "Fan Status": <Section title="Fan Status" settings={settings} lincrID={lincrID}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', paddingVertical: 10 }}>
                <TapabbleFan rpm={metrics.fanLeftSpeed} />
                <TapabbleFan rpm={metrics.fanCenterLeftSpeed} />
                <TapabbleFan rpm={metrics.fanCenterRightSpeed} />
                <TapabbleFan rpm={metrics.fanRightSpeed} />
            </View>
        </Section>
    }


    if (Object.keys(metrics).length == 0) return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
    <Text>No data reported by Lincr</Text>
  </View>

    return (
        <View>
            <Modal
                animationType="fade"
                transparent={true}
                visible={showQAMAssoc != null}
                onRequestClose={() => {
                    setShowQAMAssoc(null)
                }}
            >
                <AssociateModal dismiss={() => setShowQAMAssoc()} sgs={sgs} selected={showQAMAssoc} qamInfo={metrics.qamInfo} device={data} lincrID={lincrID} />
            </Modal>


            {
                selectedOrder ? selectedOrder.map((section) => {
                    return Sections[section]
                }) : Object.keys(Sections).map((section) => Sections[section])
            }





        </View>
    )
}

const styles = StyleSheet.create({
    triangleCorner: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 15,
        borderLeftWidth: 15,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: colors.blue,
        borderLeftColor: 'transparent',
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
})