import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import { Btn } from "../../../atoms/Btn"

import { Spacer } from "../../../atoms/Spacer"

import ws_logger from '../../../../logger'

export const PartialServiceSection = ({data, showPartialService}) => {
    const REASON_DEFINITIONS = {
      '0': 'Unknown',
      '1': 'MDD timeout',
      '2': 'FEC lock failure',
      '3': 'Bad tcc',
      '4': 'Bad rcc',
      '5': 'Reg ack',
      '6': 'DBC rsp',
      '7': 'TR power bad',
      '8': 'NCP profile failure',
      '9': 'Impaired channel',
      '10': 'Channel unreachable',
      '11': 'Range timeout',
      '12': 'Ranging failure',
      '13': 'Ds ofdm profile fail',
      '14': 'DPD mismatch'
  }
  
  const hitsByReason = data.hitsByReason
  const totalHits = Object.values(hitsByReason).reduce((partialSum, a) => partialSum + a, 0);

  if (totalHits == 0) return <View style={{alignItems: 'center', width: '100%'}}><Text>No Partial Service Instances</Text></View>
  return <View>
   <View style={{flexDirection: 'row', paddingBottom: 10}}>

                  <Text style={{flex: 1, textAlign: 'center', fontWeight: 'bold'}}>Reason</Text>
                  <Text style={{flex: 2, textAlign: 'center', fontWeight: 'bold'}}>Description</Text>
                  <Text style={{flex: 1.3, textAlign: 'center', fontWeight: 'bold'}}>Instances</Text>
                  <Text style={{flex: 1, textAlign: 'center', fontWeight: 'bold'}}>Percent</Text>
                  
                 </View>
                 {
                  Object.keys(hitsByReason).map(reason => {
                    return <View style={{flexDirection: 'row', paddingBottom: 3, borderBottomWidth: 1, marginBottom: 5, borderColor: '#c2c2c2'}}>
                      <Text style={{flex: 1, textAlign: 'center'}}>{reason}</Text>
                      <Text style={{flex: 2, textAlign: 'center'}}>{REASON_DEFINITIONS[reason]}</Text>
                      <Text style={{flex: 1.3, textAlign: 'center'}}>{hitsByReason[reason]}</Text>
                      <Text style={{flex: 1, textAlign: 'center'}}>{Math.round(100 * hitsByReason[reason] / totalHits)}%</Text>
                      </View>
                  })
                 }
                 <View style={{flexDirection: 'row', padding: 5}}>
                  <Text style={{flex: 3, marginLeft: 3, fontWeight: 'bold'}}>Total</Text>
                  <Text style={{flex: 1.3, textAlign: 'center'}}>{totalHits}</Text>
                  <Text style={{flex: 1, textAlign: 'center'}}>100%</Text>
                  </View>
  
                  <View style={{flexDirection: 'row', paddingVertical: 10, justifyContent: 'center'}}>
                    <Btn label="DS Analysis" fillWidth={Platform.OS == "web"} onPress={() => {showPartialService('ds'); ws_logger.log("Navigate", "Downstream Partial Service")}} style={{width: '100%', flex: 1, alignItems: 'center', backgroundColor: 'white', shadowColor: 'black', shadowOffset: {width: 0, height: 1}, shadowOpacity: 0.3, borderRadius: 5, padding: 5}}/>
                    <Spacer width={10}/>
                    <Btn label="US Analysis" fillWidth={Platform.OS == "web"} onPress={() => {showPartialService('us'); ws_logger.log("Navigate", "Upstream Partial Service")}} style={{flex: 1, alignItems: 'center', backgroundColor: 'white', shadowColor: 'black', shadowOffset: {width: 0, height: 1}, shadowOpacity: 0.3, borderRadius: 5, padding: 5}}/>
                    
                    
                  </View>
  </View>
  }