import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"

import { Section } from "../../molecules/Section"

import { ProgressBar } from "../../atoms/ProgressBar"
import { TempCircle } from "../../atoms/TempCircle"
import { useEffect, useState } from 'react'
import { Spacer } from "../../atoms/Spacer"
import { useSelector, useDispatch } from "react-redux"

import { TapabbleFan } from "../../atoms/TappableFan"

import hardware from "../../../config/hardware"
import { mergeDeep, secondsToDateTime } from "../../../Utilities"

import ws_logger from '../../../logger'


const removeSpectrum = (obj) => {
  let newObj = Object.assign({}, obj)
  if (Object.keys(obj).includes("spectrum")) { delete newObj.spectrum; return newObj }
  else return undefined

}

const getSGModems = (obj) => {
  let newObj = Object.assign({}, obj)
  let sgs = []
  for (let c of Object.keys(newObj)) {
    for (let p of Object.keys(newObj[c])) {
      let rmspecObj = removeSpectrum(newObj[c][p])
      if (rmspecObj) {
        sgs.push({ card: c, port: p, active: rmspecObj.activeModems, total: rmspecObj.totalModems, perc: Math.round((rmspecObj.activeModems / rmspecObj.totalModems) * 100) / 100 })
      }

    }
  }
  return sgs.filter(x => x)
}

const getUtils = (obj) => {
  let newObj = Object.assign({}, obj)
  let ds = []
  let us = []
  for (let c of Object.keys(newObj)) {
    for (let p of Object.keys(newObj[c])) {
      let rmspecObj = removeSpectrum(newObj[c][p])
      if (rmspecObj) {
        us.push(Object.assign(rmspecObj, { card: c, port: p }))
      } else {
        if (c !== "Total") {
          ds.push(Object.assign({}, newObj[c][p], { card: c, port: p }))
        }
      }

    }
  }
  return { ds: ds, us: us }
}


// Sections
import { PEMSection } from "./sections/pems"
import { FanSection } from "./sections/fans"
import { HardwareStatusSection } from "./sections/HardwareStatus"
import { PortUtilSection } from "./sections/portUtil"
import { ActiveModemSection } from "./sections/activeModems"
import { PartialServiceSection } from "./sections/partialService"
import { UpstreamSNRSection } from "./sections/upstreamSNR"
import { BurstNoiseSection } from "./sections/burstNoiseSection"
import { SpectrumNoiseSection } from "./sections/spectrumNoise"
import { ModuleSection } from "./sections/moduleSection"
import { NonBondingModemSummary } from "./sections/nonBondingModems"

//Modals
import { USDSModal } from "./modals/usds"
import { PartialServiceModal } from "./modals/partialservice"
import { ModemBreakdown } from "./modals/ModemBreakdown"



export const C100GDisplay = ({ metrics, data, lincrid, selectedOrder }) => {
  const [utilModalVisible, setUtilModalVisible] = useState("");
  const [partialServiceVisible, showPartialService] = useState("");
  const [breakdownVisible, showBreakdown] = useState(false)
  const user = useSelector((state) => state.user.user)
  const fans = metrics?.fanTray

  const dispatch = useDispatch()

  const settings = metrics ? metrics[user.uid] ? mergeDeep({}, hardware.C100G.defaultSettings, metrics[user.uid]) : hardware.C100G.defaultSettings : hardware.C100G.defaultSettings


  const Sections = {
    "Hardware Status": <Section title="Hardware Status" settings={settings} lincrID={lincrid}>
      <HardwareStatusSection data={data} metrics={metrics} />
    </Section>,
    "Active Modems": <Section title="Active Modems" settings={settings} lincrID={lincrid}>
      <ActiveModemSection cardSummary={metrics.cardSummary} showBreakdown={showBreakdown} settings={settings} />
    </Section>,
    "Module Summary": <Section title="Module Summary" settings={settings} lincrID={lincrid}>
      <ModuleSection modules={metrics.modules} settings={settings} />
    </Section>,
    "Upstream SNR by Service Group": <Section title="Upstream SNR by Service Group" settings={settings} lincrID={lincrid}>
      <UpstreamSNRSection data={getUtils(metrics.cardSummary).us.map(val => {
        return { avgSNR: val.avgSNR, minSNR: val.minSNR, card: val.card, port: val.port }
      })} names={metrics.usPortNames} settings={settings} />
    </Section>,
    "DS and US Port Utilization": <Section title="DS and US Port Utilization" settings={settings} lincrID={lincrid}>
      <PortUtilSection setUtilModalVisible={setUtilModalVisible} />
    </Section>,
    "Burst Noise per US Channel": <Section title="Burst Noise per US Channel" settings={settings} lincrID={lincrid}>
      <BurstNoiseSection burstNoise={metrics.burstNoise} names={metrics.usPortNames} settings={settings} />

    </Section>,
    "Upstream Spectrum Noise": <Section title="Upstream Spectrum Noise" settings={settings} lincrID={lincrid}>
      <SpectrumNoiseSection data={data} metrics={metrics} lincrid={lincrid} settings={settings}/>
    </Section>,

    "PEMS (Power Entry Modules)": <Section title="PEMS (Power Entry Modules)" settings={settings} lincrID={lincrid}>
      <PEMSection pems={[metrics.pemA, metrics.pemB]} settings={settings} />
    </Section>,
    "Fans": <Section title="Fans" settings={settings} lincrID={lincrid}>
      <FanSection fans={fans} settings={settings} />
    </Section>,
    "Non-Bonding Modem Summary": <Section title="Non-Bonding Modem Summary" settings={settings} lincrID={lincrid}>
      <NonBondingModemSummary data={getUtils(metrics.cardSummary).us} names={metrics.usPortNames} settings={settings} />
    </Section>,
    "Analysis of Partial Service": <Section title="Analysis of Partial Service" hideSettings={true}>
      <PartialServiceSection data={metrics['partial-service']} showPartialService={showPartialService} />
    </Section>,
  }

  if (Object.keys(metrics).length == 0) return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
    <Text>No data reported by Lincr</Text>
  </View>

  if (breakdownVisible) {
    return <ModemBreakdown showBreakdown={showBreakdown} cardSummary={getSGModems(metrics.cardSummary)} names={metrics.usPortNames} settings={settings}/>
  } else {
    return (
      <View>
        <Modal
          animationType="fade"
          transparent={true}
          visible={utilModalVisible !== ""}
          onRequestClose={() => {
            setUtilModalVisible("");
          }}
        >
          <USDSModal dismiss={setUtilModalVisible} usds={utilModalVisible} data={getUtils(metrics.cardSummary)} settings={settings} />
        </Modal>

        <Modal
          animationType="fade"
          transparent={true}
          visible={partialServiceVisible !== ""}
          onRequestClose={() => {
            showPartialService("");
          }}
        >
          <PartialServiceModal dismiss={showPartialService} usds={partialServiceVisible} data={metrics['partial-service']} />
        </Modal>

        {
          selectedOrder ? selectedOrder.map((section) => {
            return Sections[section]
          }) : Object.keys(Sections).map((section) => Sections[section])
        }


      </View>
    )
  }
}