import { useState } from "react";
import { View, Text, Button, KeyboardAvoidingView, Platform, TouchableOpacity } from "react-native";
import { Btn } from "../components/atoms/Btn";
import { Spacer } from "../components/atoms/Spacer";
import { TWLogo } from "../components/atoms/TelaWatchLogo";
import { TextEntry } from "../components/atoms/TextEntry";
import {useDispatch} from 'react-redux'
import { authErrors } from "../config/AuthErrors";
import * as reducers from '../redux_slices/reducers'

import { signInUser } from "../Firebase";
import { colors } from "../config/globalStyle";

export function Login({ navigation, setSignedIn }) {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const behavior = Platform.OS == 'android' ? "" : "padding"
  const dispatch = useDispatch()


  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      
      <KeyboardAvoidingView behavior={behavior} style={{flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center'}}>
      <TWLogo size={30} style={{ paddingBottom: 30 }} />

        <View style={{ width: '80%', alignItems: 'center'}}>

        <TextEntry icon="mail" placeholder={"Email"} inputState={email} onChangeText={setEmail}/>
        <Spacer height={5}/>
        <TextEntry icon="lock" placeholder={"Password"} inputState={password} onChangeText={setPassword} passwordEntry={true}/>
        <TouchableOpacity style={{flexDirection: 'row-reverse', width: '100%', padding: 3}} onPress={() => {navigation.navigate("PassReset")}}>
        <Text style={{color: colors.blue}}>Forgot Password?</Text>
        </TouchableOpacity>
        <Spacer height={15}/>
        <Btn label="Login" fillWidth={true} onPress={async () => {
          console.log("logging in...")
          if (email.trim() == "" || password.trim() == "") return setError("Please enter an email and password")
          try {
          let res = await signInUser({email: email.trim(), password: password.trim()})
          dispatch(reducers.user.setUser(res))
          if (res.emailVerified) setSignedIn(true)
          else navigation.navigate('Verify')
          
        } catch (e) {
          let errorID = e.code.split('auth/')[1]

          setError(authErrors[errorID])
        
          
        }
         
          }} />
          <Spacer height={10}/>
        <Btn label="Signup" onPress={() => navigation.navigate('Signup')} fillWidth={true} color={colors.grey} />
        <Spacer height={10}/>
        <Text style={{color: colors.red, textAlign: 'center'}}>{error}</Text>

        </View>
      </KeyboardAvoidingView>
    </View>
  );
  }