import { useState } from "react"
import { Text, View,} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";
import RangeSlider from "../../../atoms/RangeSlider";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";


export const GBESettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [gbeSlider, setGBESlider] = useState()
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    const [gbeAlarmMajor, setGBEAlarmMajor] = useState(settings.GBE.MajorAlarm);
    const [gbeAlarmMinor, setGBEAlarmMinor] = useState(settings.GBE.MinorAlarm);
    
    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            if (gbeSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.GBE.Lower`] = gbeSlider.min / 100
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.GBE.Upper`] = gbeSlider.max / 100
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.GBE.MajorAlarm`] = gbeAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.GBE.MinorAlarm`] = gbeAlarmMinor


            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{paddingBottom: 10}}>
            <RangeSlider min={0} max={100} step={1} onValueChange={setGBESlider} units="%" label="Utilization (port %)" defaultValue={{min: settings.GBE.Lower, max: settings.GBE.Upper}} colors={["green", "orange", "red"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={gbeAlarmMajor} onValueChange={(val) => setGBEAlarmMajor(val)} style={{borderWidth: 1, borderColor: 'orange', backgroundColor: 'white'}}/>
            <ToggleLabel title="Major Alarm" value={gbeAlarmMinor} onValueChange={(val) => setGBEAlarmMinor(val)} style={{borderWidth: 1, borderColor: 'red', backgroundColor: 'white'}}/>
            </View>

            </View>
        </View>
    )
}