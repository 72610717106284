import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { StyleSheet, Image, View, Text, TouchableOpacity, Button, Dimensions} from 'react-native'
import {FontAwesome, Entypo, Octicons, Feather} from '@expo/vector-icons'

import {Section} from  "../../molecules/Section"
import hardware from "../../../config/hardware"
import { mergeDeep } from "../../../Utilities"
import { colors } from "../../../config/globalStyle"
import { uuidv4 } from "@firebase/util"
import { Spacer } from "../../atoms/Spacer"
import * as reducers from '../../../redux_slices/reducers'
const HCColors = ["lightGrey", "green", "yellow", "red"]

const HealthCheckRow = ({check, id}) => {
    return <View style={{flexDirection: 'row', paddingVertical: 5, borderBottomWidth: 1, borderBottomColor: colors.lightGrey, alignItems: 'center'}}>
        <View style={{flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'space-around'}}>
        <Text>{id + 1}</Text>
        <FontAwesome name="circle" size={15} color={colors[HCColors[check.totalColor]]} style={{paddingHorizontal: 5}}/>
        </View>
        <Text style={{flex: 2}}>{check.name}</Text>
        <Text style={{flex: 2}}>{check.ip}</Text>
        <View style={{flexDirection: 'row', flex: 2, justifyContent: 'space-around', alignItems: 'center'}}>
        {
            check.hist.map(entry => {
                return <FontAwesome name={entry == 0 ? "circle-o" : 'circle'} size={15} color={colors[HCColors[entry]]} id={uuidv4()}/>
            })
        }
        </View>
    </View>
}

export const HealthCheckDisplay = ({ metrics, data, lincrID, selectedOrder }) => {
    const [showBreakdown, setShowBreakdown] = useState(false)
    const user = useSelector((state) => state.user.user)
    const settings = metrics[user.uid] ? mergeDeep({}, hardware.UBR10012.defaultSettings, metrics[user.uid]) : hardware.UBR10012.defaultSettings
    const dispatch = useDispatch()
    const Sections = {
        "Ping Panel": <Section title="Ping Panel" hideSettings={true}>
            <View style={{flexDirection: 'row', paddingVertical: 10}}>
            <View style={{flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'space-around'}}>
                <Text style={{color: 'white'}}>0</Text>
                <TouchableOpacity onPress={() => {dispatch(reducers.nav.setShowInfo("pingtarget"))}}>
                <Feather name="info" color={colors.blue} size={17} style={{paddingHorizontal: 5}}/>
                </TouchableOpacity>
            </View>
               
                <Text style={{flex: 2}}>Target</Text>
                <Text style={{flex: 2}}>IP/Host</Text>
                <View style={{flex: 2, flexDirection: 'row'}}>
                <TouchableOpacity onPress={() => {dispatch(reducers.nav.setShowInfo("pinghistory"))}}>
                <Feather name="info" color={colors.blue} size={17} />
                </TouchableOpacity>
                <Spacer width={3}/>
                <Text>History</Text>
                </View>

            </View>
            {
                metrics.checks.map((check, id) => {
                    return <HealthCheckRow check={check} id={id} key={"Ping" + check.name + id}/>
                })
            }
        </Section>
    }
    return (
      <View>
        {
             selectedOrder ? selectedOrder.map((section) => {
              return Sections[section]
          }) : Object.keys(Sections).map((section) => Sections[section])
           
        }
  
      </View>
    )
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: '#000',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 18,
      lineHeight: 23,
      flex: 2
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    },
    appButtonContainer: {
      elevation: 8,
      backgroundColor: "#009688",
      borderRadius: 10,
      paddingVertical: 10,
      paddingHorizontal: 12
    },
    appButtonText: {
      fontSize: 18,
      color: "#fff",
      fontWeight: "bold",
      alignSelf: "center",
      textTransform: "uppercase"
    },
    spaceEvenly: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    bodyText: {
      fontSize: 14,
      color: "grey"
    },
    greenHeader: {
      fontSize: 16,
      color: "green"
    },
    redHeader: {
      fontSize: 16,
      color: "red"
    },
    greyHeader: {
      fontSize: 16,
      color: "grey"
    },
    error: {
      color: 'red',
      fontSize: 16
    },
    activeModems: {
      justifyContent: 'center',
      flexDirection: 'row'
    },
    modemData: {
      justifyContent: 'space-evenly',
      marginLeft: 10
    }
  });
  