import { Feather } from '@expo/vector-icons'
import { StyleSheet, View, Text, TextInput, Button, TouchableOpacity } from 'react-native'
import { Spacer } from './Spacer'


export const TextEntry = ({icon, placeholder, inputState, onChangeText, passwordEntry=false}) => {
    return <View style={{flexDirection: 'row', elevation: 3, borderWidth: 0, padding: 9, marginHorizontal: -3, alignItems: 'center', backgroundColor: 'white', borderRadius: 7, shadowColor: 'black', shadowOpacity: 0.3, shadowOffset: {width: 0, height: 1}}}>
      <Feather name={icon} size={20} color={"black"} style={{marginTop: 0,}}/>
      <TextInput value={inputState} onChangeText={onChangeText} style={styles.textInput} placeholder={placeholder} placeholderTextColor="rgba(0,0,0,0.3)" secureTextEntry={passwordEntry}/>
    </View>
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: 'rgba(0,0,0,1)',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 16,
      height: 30,
      flex: 1
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    }
  });
  