import { TouchableHighlight, StyleSheet, View, TouchableOpacity, Text, SafeAreaView, ScrollView} from 'react-native'
import { FontAwesome, Entypo, Octicons, Foundation, Feather, Ionicons } from '@expo/vector-icons';
import { useState } from 'react';
import { colors } from '../config/globalStyle';
import { Spacer } from '../components/atoms/Spacer';
import { useSelector } from 'react-redux';


  const TicketEntry = ({id, name, entries, time, alarmed=false, onPress}) => {
    const msToRel = (ms) => {
      let ts = ms / 1000
      var days = Math.floor(ts / 86400);
  
      // subtract days from seconds
      ts = ts - (days * 86400);
  
      // get hours
      var hours = Math.floor(ts / 3600);
  
      // subtract hours from seconds
      ts = ts - (hours * 3600);
  
      // get minutes
      var minutes = Math.floor(ts / 60);
  
      var seconds = Math.round(ts - (minutes * 60))
  
      return `${days !== 0 ? days + "d:" : ""}${hours !== 0 ? hours + "h" + (days > 0 ? "" : ":") : ""}${days > 0 ? "" : minutes + "m"}`
    }

    return  <TouchableOpacity onPress={onPress} style={{ borderWidth: 0, borderColor: '#eeeeee', borderRadius: 5, flexDirecton: 'row', alignItems: 'center', marginBottom: 5 }}>
      <View style={{ backgroundColor: 'white', padding: 7, borderRadius: 5, flexDirection: 'row', alignItems: 'center', shadowColor: 'black', shadowOffset: {height: 2, width: 0}, shadowOpacity: 0.1, shadowRadius: 3, elevation: 3 }}>
      <Text style={{ width: 15 }}>{id + 1}</Text>
      <Text style={{flex: 1}}>{name}</Text>
      <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', justifyContent: 'flex-start', borderWidth: 0 }}>
        {
          entries.map((entry, i) => {
            return <FontAwesome key={"icon" + name + Math.random() * 100} style={{ paddingLeft: 3 }} name="circle" size={15} color={entry.level == undefined ? colors.red : entry.level == "Minor" ? colors.orange : entry.level == "Major" ? colors.red : colors.grey} />
          })
        }
      </View>
      <Text style={{flex: 0.5}}>{msToRel(Date.now() - time)}</Text>
      <Spacer width={5}/>
      {alarmed ?
        <View style={{ flexDirection: 'row', alignItems: 'center', width: 15 }}>
          <Octicons name="bell" size={15} color={'black'} style={{}} />
          <Ionicons name="ios-checkmark-sharp" size={10} color={colors.green} style={{ position: 'absolute', top: -5, right: -3, backgroundColor: 'white' }} />
        </View> : <View style={{ width: 15,height: 1 }}></View>
      }

    </View>
    </TouchableOpacity>
  }

  const DeviceTickets = ({ device, tickets, closed }) => {
    return <View>
      {
        tickets.length == 0 ? <></> : <>
          <Text style={{ fontSize: 17, paddingVertical: 7}}>{device}</Text>
          <View>
            {
              tickets.map((ticket, index) => {
                if (ticket.group) return <TicketEntry
                  key={ticket.group + device + Math.random() * 10}
                  name={ticket.group}
                  entries={ticket.entries}
                  time={ticket.opened}
                  closed={closed}
                  id={index}
                  alarmed={ticket.entries.filter(x => x.alarmable).length > 0}
                  onPress={() => {console.log(device)}}

                />
                else return <TicketEntry key={ticket.target + device + Math.random() * 10} name={ticket.target} entries={[ticket]} time={ticket.opened} closed={closed} id={index} alarmed={ticket.alarmable} />
              })
            }

          </View>
        </>
      }
    </View>
  }



export const AlarmLogPage = ({ dismiss }) => {

    const allTickets = useSelector((state) => state.sites.tickets)
    const [showOpen, setShowOpen] = useState(true)
    const [showClosed, setShowClosed] = useState(false)
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: 'white'}}>
  
        <ScrollView style={{ padding: 10 }}>
          
          <View style={styles.horiz}>
          <TouchableOpacity onPress={() => { dismiss(!true) }} style={{paddingVertical: 3, flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#eeeeee', borderRadius: 10 }}>
            <Feather name="x" size={23} color="black" />
          </TouchableOpacity>
          {
            /*
          <Spacer width={10}/>
          <TouchableOpacity onPress={() => { }} style={{paddingVertical: 3, flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(211, 84, 0,0.3)', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}>
            <Ionicons name="ios-documents-outline" size={23} color="black" />
          </TouchableOpacity>
          <Spacer width={0}/>
          <TouchableOpacity onPress={() => {  }} style={{paddingVertical: 3, flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(241, 196, 15,0.5)', borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
            <Octicons name="bell" size={23} color="black" />
          </TouchableOpacity>
          */}
          </View>
  
          <Spacer height={10} />
          <TouchableHighlight underlayColor={'rgba(211, 84, 0,0.01)'} style={{ borderWidth: 0, padding: 5, borderRadius: 5, backgroundColor: 'rgba(211, 84, 0,0.2)' }} onPress={() => setShowOpen(!showOpen)}>
            <>
              <Text style={{ fontSize: 21 }}>Open Tickets</Text>
              <Feather name={showOpen ? "chevron-up" : "chevron-down"} size={23} color="black" style={{ position: 'absolute', right: 10, top: 6 }} />
  
            </>
          </TouchableHighlight>
  
          {showOpen ?
            <View style={{ padding: 5 }}>
              {
                allTickets ? Object.keys(allTickets).sort((a, b) => a > b).map((device, i) => {
                  return <DeviceTickets key={device + i + "active"} device={device} tickets={allTickets[device]} />
  
                }) : <></>
              }
            </View> : <></>
          }

          { /*
          <Spacer height={20} />
          <TouchableHighlight underlayColor={'rgba(127, 140, 141,0.1)'} onPress={() => { setShowClosed(!showClosed) }} style={{ borderWidth: 0, padding: 5, borderRadius: 5, backgroundColor: 'rgba(127, 140, 141,0.1)' }}>
            <>
              <Text style={{ fontSize: 21 }}>Closed Tickets</Text>
              <Feather name={showClosed ? "chevron-up" : "chevron-down"} size={23} color="black" style={{ position: 'absolute', right: 10, top: 6 }} />
  
            </>
          </TouchableHighlight>
        */}
  
          {showClosed ?
            <View style={{ padding: 10, backgroundColor: 'white' }}>
              
            </View> : <></>
          }
        </ScrollView>
      </SafeAreaView>
    )
  }
  

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: '#000',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 18,
      lineHeight: 23,
      flex: 2
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    },
    appButtonContainer: {
      elevation: 8,
      backgroundColor: "#009688",
      borderRadius: 10,
      paddingVertical: 10,
      paddingHorizontal: 12
    },
    appButtonText: {
      fontSize: 18,
      color: "#fff",
      fontWeight: "bold",
      alignSelf: "center",
      textTransform: "uppercase"
    },
    spaceEvenly: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    bodyText: {
      fontSize: 14,
      color: "grey"
    },
    greenHeader: {
      fontSize: 16,
      color: "green"
    },
    redHeader: {
      fontSize: 16,
      color: "red"
    },
    greyHeader: {
      fontSize: 16,
      color: "grey"
    },
    error: {
      color: 'red',
      fontSize: 16
    },
    activeModems: {
      justifyContent: 'center',
      flexDirection: 'row'
    },
    modemData: {
      justifyContent: 'space-evenly',
      marginLeft: 10
    }
  });
  