import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import { colors } from "../../../../config/globalStyle"

import { Spacer } from "../../../atoms/Spacer"
import { UpstreamName } from "../../../atoms/UpstreamName"

export const UpstreamSNRSection = ({data, names = [], settings}) => {
  
  return <View>

    <View style={{flexDirection: 'row', paddingVertical: 7}}>
      <Text style={{flex: 2, fontWeight: 'bold'}}>Service Group</Text>
      <Text style={{flex: 0.6, fontWeight: 'bold'}}>Port</Text>
      <Text style={{flex: 0.8, fontWeight: 'bold', textAlign: 'center'}}>Avg US SNR</Text>
      <Text style={{flex: 0.8, fontWeight: 'bold', textAlign: 'center'}}>Min Ch US SNR</Text>
    </View>
    {
      data.map(card => {
        if (card.port.includes("w") || card.port.includes("undefined")) return <></>
        let avgcolor = Math.round(card.avgSNR) < settings.usSnr.Lower ? colors.red : Math.round(card.avgSNR) < settings.usSnr.Upper ? colors.orange : colors.green
        let mincolor = Math.round(card.minSNR) < settings.usSnr.Lower ? colors.red : Math.round(card.minSNR) < settings.usSnr.Upper ? colors.orange : colors.green
        return <View style={{flexDirection: 'row', padding: 3}}>
          <UpstreamName style={{flex: 2}} name={names[card.card + "/" + card.port] ?? card.card + "/" + card.port} port={card.card + "/" + card.port}/>
          <Text style={{flex: 0.6, padding: 3, paddingLeft: 10, }}>{card.card}/{card.port}</Text>
          <Text style={{flex: 0.8, textAlign: 'center', backgroundColor: avgcolor, padding: 3,borderRadius: 7, overflow: 'hidden'}}>{Math.round(card.avgSNR)}</Text>
          <Spacer width={3}/>
          <Text style={{flex: 0.8, textAlign: 'center', backgroundColor: mincolor, padding: 3, borderRadius: 7, overflow: 'hidden'}}>{Math.round(card.minSNR)}</Text>
          </View>
      })
    }
  </View>
}