import { createSlice } from '@reduxjs/toolkit'
import { RecyclerViewBackedScrollViewComponent } from 'react-native'

const initialState = {
  page: 'loading',
  pageData: null,
  hideAllSections: false,
  showSectionSettings: false,
  sectionSettingData: null,
  settingsSaved: false,
  upstreamModalVisible: null,
  showReorder: null,
  showInfo: null,
}

export const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    showNameModal: (state, action) => {
      state.upstreamModalVisible = action.payload
    },
    showReorderModal: (state, action) => {
      state.showReorder = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setPageData: (state, action) => {
      state.pageData = action.payload
    },
    setShowInfo: (state, action) => {
      state.showInfo = action.payload
    },
    setHideAllSections: (state, action) => {
      state.hideAllSections = action.payload
    },
     setSectionSettings: (state, action) => {
      state.showSectionSettings = action.payload
     },
     setSectionData: (state, action) => {
      state.sectionSettingData = action.payload
     },
     saveSettings: (state, action) => {
      state.settingsSaved = action.payload
     }
  },
})

// Action creators are generated for each case reducer function
export const { setPage, setPageData, setShowInfo, setHideAllSections, setSectionSettings, setSectionData, saveSettings, showNameModal, showReorderModal } = counterSlice.actions