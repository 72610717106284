import {View, SafeAreaView, TouchableOpacity, Text, TextInput, Button, Platform} from 'react-native'
import {Feather} from '@expo/vector-icons'
import { useState } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { DropdownList } from '../components/atoms/Dropdown'
import ToggleLabel from '../components/atoms/ToggleLabel'
import { Spacer } from '../components/atoms/Spacer'
import { navigationRef } from '../RootNavigation'
import { removeSite, UpdateSiteSettings } from '../Firebase'
import * as reducers from '../redux_slices/reducers'
import { confirmAction } from '../Utilities'



export const SiteSettings = ({ dismiss, df, name, refresh=300, lincrid,  lincrVersion, devices, connectivity }) => {
  const refreshNumtoReadable = (rfrsh) => {
    let seconds = parseInt(rfrsh)
    switch (seconds) {
      case 60: return "1 Minute"
      case 120: return "2 Minutes"
      case 300: return "5 Minutes"
      case 900: return "15 Minutes"
      case 1800: return "30 Minutes"
      case 3600: return "60 Minutes"
    }
  }

  const ReadableRefreshtoNum = (rfrsh) => {
    switch (rfrsh) {
      case "1 Minute": return 60
      case "2 Minutes": return 120
      case "5 Minutes": return 300
      case "15 Minutes": return 900
      case "30 Minutes": return 1800
      case "60 Minutes": return 3600
    }
  }
  const [siteName, setSiteName] = useState(name)
  const [nuRefresh, setRefresh] = useState(refreshNumtoReadable(refresh))
  const [dfAlarm, setDfAlarm] = useState(df)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
        <View style={{ padding: 10 }}>
          <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
          <TouchableOpacity onPress={() => { dismiss(!true) }} style={{paddingVertical: 3, flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#eeeeee', borderRadius: 10 }}>
            <Feather name="x" size={23} color="black" />
          </TouchableOpacity>
          <Spacer width={10}/>
          <TouchableOpacity onPress={() => {
            //Save to firebase
            
            let obj = {}

            obj.Name = siteName
            obj.Refresh = ReadableRefreshtoNum(nuRefresh)
            obj[`${user.uid}.DfAlarm`] = dfAlarm
            navigationRef.current?.setParams({name: siteName, lincrID: lincrid, connectivity: connectivity, devices: devices})
           UpdateSiteSettings({id: lincrid, setObj: obj})
            dismiss(!true) 
          
          }
            } style={{paddingVertical: 3, flex: 1, flexDirection: 'row',alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(52, 152, 219,0.5)', borderRadius: 10 }}>
            <Feather name="save" size={23} color="black" />
            <Spacer width={10}/>
            <Text>Save</Text>
          </TouchableOpacity>
          </View>
  
          <Text style={{fontSize: 23, paddingVertical: 9}}>Site Settings</Text>
          <View style={{ borderWidth: 0, borderRadius: 5, padding: 7, borderColor: '#c3c3c3'}}>
          <Text style={{paddingVertical: 5}}>Site Name</Text>
          <TextInput value={siteName} onChangeText={setSiteName} elevation={3} style={{borderWidth: 0, padding: 10, borderRadius: 7, borderColor: '#c2c2c2', backgroundColor: 'white', shadowOffset: {width: 0, height: 1}, shadowColor: 'black', shadowOpacity: 0.2, shadowRadius: 3}}/>
          
          <Spacer height={10}/>
          <Text style={{paddingVertical: 5}}>Refresh Interval</Text>
          <DropdownList items={["1 Minute", "2 Minutes", "5 Minutes", "15 Minutes", "30 Minutes", "60 Minutes"]} selected={nuRefresh} onItemSelect={setRefresh}/>
          
          <Spacer height={10}/>
          <Text >Connectivity</Text>
          <View style={{flexDirection: 'row'}}>
          <ToggleLabel title="Notify me when Lincr isn't reachable" value={dfAlarm} onValueChange={setDfAlarm}/>
          </View>
          <Spacer height={10}/>
          <Text>Lincr Version {lincrVersion}</Text>

          <Button title='Remove Site' color={"red"} onPress={() => {
            confirmAction(() => {
            dismiss(false)
            //dispatch(reducers.nav.setPage('home'));
            dispatch(reducers.sites.removeSite(lincrid))
            removeSite(lincrid)
            }
            )
          }}/>
          </View>
        </View>
      </SafeAreaView>
    )
  }