import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sites: [],
  siteHeader: null,
  sitesLoaded: false,
  selectedDevice: null,
  tickets: null,
  site: null
}

export const counterSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSites: (state, action) => {
      state.sites = action.payload
      state.sitesLoaded = true
    },
    setSite: (state, action) => {
      state.site = action.payload
    },
    addSite: (state, action) => {
      let keys = state.sites ? state.sites.map(site => site.lincrID) : []
      if (keys.includes(action.payload.lincrID)) {
        state.sites = state.sites.map(site => {
          if (site.lincrID === action.payload.lincrID) {
            return action.payload
          } else return site
        })
      } else {
        if (state.sites) {
        state.sites = [action.payload, ...state.sites]
        } else {
          state.sites = [action.payload]
        }
      }
    },
    removeSite: (state, action) => {
      state.sites = state.sites.filter(site => site.lincrID !== action.payload)
    },
    setSitesLoaded: (state, action) => {
      state.sitesLoaded = action.payload
    },
    setSiteHeader: (state, action) => {
        state.siteHeader = action.payload
        state.sitesLoaded = true
    },
    selectDevice: (state, action) => {
      state.selectedDevice = action.payload
    },
    setTickets: (state, action) => {
      state.tickets = action.payload
    },
    finishedLoading: (state, action) => {
      state.sitesLoaded = true
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSites, setSitesLoaded, setSiteHeader, selectDevice, setTickets, addSite, removeSite, setSite, finishedLoading } = counterSlice.actions