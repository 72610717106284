import { useState } from "react"
import { Text, View,} from "react-native"
import { Switch } from 'react-native-paper';
import ToggleLabel from "../../../atoms/ToggleLabel";
import RangeSlider from "../../../atoms/RangeSlider";

import { Spacer } from "../../../atoms/Spacer"
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";
import hardware from "../../../../config/hardware";

import { mergeDeep } from "../../../../Utilities";

export const PortUtilSettings = () => {
   
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [utilSlider, setUtilSlider] = useState()
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
   
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings

    const [utilMajorAlarm, setMajorAlarm] = useState(settings.PortUtil.MajorAlarm);
    const [utilMinorAlarm, setMinorAlarm] = useState(settings.PortUtil.MinorAlarm);


    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            if (utilSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.PortUtil`] = {
                    Lower: utilSlider.min / 100,
                    Upper: utilSlider.max / 100,
                }
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.PortUtil.MajorAlarm`] = utilMajorAlarm
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.PortUtil.MinorAlarm`] = utilMinorAlarm

            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
           
        }
    }, [settingsSaved])

    return (
        <View style={{}}>
            <View style={{paddingBottom: 10}}>
            <RangeSlider min={0} max={100} step={1} onValueChange={setUtilSlider} units="%" label="Port Utilization" defaultValue={{min: settings.PortUtil.Lower * 1, max: settings.PortUtil.Upper * 1}} colors={["green", "orange", "red"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Major Alarm" value={utilMinorAlarm} onValueChange={(val) => setMinorAlarm(val)} style={{ borderColor: 'grey',}} level={2}/>
            <ToggleLabel title="Minor Alarm" value={utilMajorAlarm} onValueChange={(val) => setMajorAlarm(val)} style={{ borderColor: 'grey',}} level={1}/>
            
            </View>
            </View>
        </View>
    )
}