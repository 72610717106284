import { useState } from "react"
import { Text, View} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import { SegmentedButtons } from 'react-native-paper';

import { Spacer } from "../../../atoms/Spacer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";

export const DTCCSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const settings = sectionData.settings
    const [value, setValue] = useState('');
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)

    const [dtccCount, setDtccCount] = useState(sectionData.extra)

    const [dtccAlarm, setDtccAlarm] = useState(settings.DtccAlarm);
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    const dispatch = useDispatch()

    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.DtccAlarm`] = dtccAlarm
            obj[`${JSON.parse(selectedDevice).DisplayName}.DtccCount`] = dtccCount
            UpdateSiteSettings({setObj: obj, id: sectionData.lincrID})
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                <Text style={{fontSize: 16, paddingLeft: 5}}>Cards Inserted</Text>
                <SegmentedControl
                        selectedIndex={parseInt(dtccCount) - 1}
                        values={['1', '2']}
                        onChange={(event) => {
                            setDtccCount((event.nativeEvent.selectedSegmentIndex + 1).toString());
                          }}
                        style={{ flexGrow: 1, maxWidth: 200}}
                        tintColor="rgba(0,0,0,0.4)"
                        backgroundColor="white"
                        />
            </View> 
            <Spacer height={10}/>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <ToggleLabel title="When a timing card goes offline" value={dtccAlarm} onValueChange={(val) => setDtccAlarm(val)} />
        </View>
    )
}