import { useState } from "react"
import { Text, View,} from "react-native"
import { Switch } from 'react-native-paper';
import ToggleLabel from "../../../atoms/ToggleLabel";


import RangeSlider from "../../../atoms/RangeSlider";
import { Spacer } from "../../../atoms/Spacer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";
import hardware from "../../../../config/hardware";
import { mergeDeep } from "../../../../Utilities";


export const BurstNoiseSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [burstSlider, setBurstSlider] = useState()
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
   
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings


    const [majorAlarm, setMajorAlarm] = useState(settings.BurstNoise.MajorAlarm);
    const [minorAlarm, setMinorAlarm] = useState(settings.BurstNoise.MinorAlarm);

    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            if (burstSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.BurstNoise.Lower`] = burstSlider.min
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.BurstNoise.Upper`] = burstSlider.max
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.BurstNoise.MajorAlarm`] = majorAlarm
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.BurstNoise.MinorAlarm`] = minorAlarm

            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{paddingBottom: 10}}>

            <RangeSlider min={0} max={3000} step={10} onValueChange={setBurstSlider} units="" label="Burst Noise" defaultValue={{min: (settings.BurstNoise.Lower - 0) / 3000, max: (settings.BurstNoise.Upper - 0) / 3000}} colors={["green", "orange", "red"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={majorAlarm} onValueChange={(val) => setMajorAlarm(val)} style={{borderColor: 'grey'}} level={2}/>
            <ToggleLabel title="Major Alarm" value={minorAlarm} onValueChange={(val) => setMinorAlarm(val)} style={{borderColor: 'grey'}} level={1}/>
            </View>
            <Spacer height={15}/>
            </View>

        </View>
    )
}
