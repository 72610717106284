// In App.js in a new project

import * as React from 'react';
import { View, Text, Button, Modal, SafeAreaView, StyleSheet, ActivityIndicator, Platform } from 'react-native';
import Constants from 'expo-constants';
import { StatusBar } from 'expo-status-bar';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useState } from 'react';
import { Landing, Login, Signup, HomeScreen, DetailsScreen} from './src/screens/';
import { useEffect } from 'react';
import {GestureHandlerRootView} from 'react-native-gesture-handler'

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, registerUserListener } from './src/Firebase';
import * as reducers from './src/redux_slices/reducers'
import { Provider, useSelector, useDispatch } from 'react-redux'
import {navigationRef} from './src/RootNavigation'
import * as RootNavigator from './src/RootNavigation'
import * as Notifications from 'expo-notifications'

import {store} from './src/store'
import { UserSettingsPage } from './src/screens/UserSettings';
import { VerifyEmail } from './src/screens/VerifyEmail';
import { PasswordReset } from './src/screens/PasswordReset';

import RangeSlider from './src/components/atoms/RangeSlider';
import { Btn } from './src/components/atoms/Btn';
import { useSharedValue } from 'react-native-reanimated';
import ws_logger from './src/logger';

const Stack = createNativeStackNavigator();
const AppTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'white'
  },
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

function AppMain() {
  const [signedIn, setSignedIn] = useState(false)
  const [tempSlider, setTempSlider] = useState()
  const [btn, setbtn] = useState(false)
  const user = useSelector((state) => state.user.user)
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const dispatch = useDispatch()

  useEffect(() => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('--------------------------------')
      if (user) {
        console.log("got user - ", user.uid)
        
        if (user.emailVerified) setSignedIn(true)
        dispatch(reducers.user.setUser(user))
        try{
          ws_logger.login(user.uid);
        } catch(e) {}
      } else {
        console.log("no user")
        dispatch(reducers.user.setUserLoaded(true))
        dispatch(reducers.user.setUser(null))
      }
    })

    return (() => {unsubscribe()})
  }, [])

  

  

  if (!userLoaded) return <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}><ActivityIndicator/></View>
  else
  return (
    <NavigationContainer ref={navigationRef} theme={AppTheme}>
      <Stack.Navigator initialRouteName="Landing" 
      screenOptions={{
        headerShown: false
      }} 
      screenListeners={({ navigation }) => ({
        state: (e) => {
        // Do something with the state
        console.log('state changed', e.data);
        var pageName = e.data.state.routeNames[e.data.state.index];
        const routeParams = e.data.state.routes[e.data.state.index].params;
        ws_logger.log("Navigate", {"pageName": pageName, "routeParams": routeParams});

        // Do something with the `navigation` object
        if (!navigation.canGoBack()) {
            console.log("we're on the initial screen");
        }
        },
    })}>
        {
          //Main App Navigation
          (user && signedIn) ?
          <Stack.Group>

            <Stack.Screen name="Home">
              {props => <HomeScreen {...props} setSignedIn={setSignedIn} />}
            </Stack.Screen>

            <Stack.Screen name="Details" component={DetailsScreen} />

            <Stack.Screen name="UserSettings" component={UserSettingsPage}/>

          </Stack.Group>  : 
          //Login Navigation
          <Stack.Group screenOptions={{ presentation: 'containedModal' }}>

            <Stack.Screen name="Landing">
              {props => <Landing {...props} user={user} />}
            </Stack.Screen>

            <Stack.Screen name="Login">
              {props => <Login {...props} setSignedIn={setSignedIn} />}
            </Stack.Screen>

            <Stack.Screen name="Signup">
              {props => <Signup {...props} />}
            </Stack.Screen>

            <Stack.Screen name="Verify">
              {props => <VerifyEmail {...props} setSignedIn={setSignedIn} />}
            </Stack.Screen>

            <Stack.Screen name="PassReset">
              {props => <PasswordReset {...props} setSignedIn={setSignedIn} />}
            </Stack.Screen>

          </Stack.Group>
        }


      </Stack.Navigator>
    </NavigationContainer>
    
  );
}




function App() {
  useEffect(() => {
    ws_logger.connect('wss://applogs.telawatch.org');
  }, [])
  return <>
  <StatusBar style="dark" translucent={true}/>
  <SafeAreaView style={{flex: 1, paddingTop: Platform.OS === "android" ? Constants.statusBarHeight : 0}}>
    <GestureHandlerRootView style={{flex: 1}}>
      <Provider store={store}>
        <AppMain/>
      </Provider>
      </GestureHandlerRootView>
      </SafeAreaView>
    </>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    paddingTop: 10,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textInput: {
    color: '#000',
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 18,
    lineHeight: 23,
    flex: 2
  },
  horiz: {
    flexDirection: 'row',
    width: '100%'
  },
  appButtonContainer: {
    elevation: 8,
    backgroundColor: "#009688",
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 12
  },
  appButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase"
  },
  spaceEvenly: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  bodyText: {
    fontSize: 14,
    color: "grey"
  },
  greenHeader: {
    fontSize: 16,
    color: "green"
  },
  redHeader: {
    fontSize: 16,
    color: "red"
  },
  greyHeader: {
    fontSize: 16,
    color: "grey"
  },
  error: {
    color: 'red',
    fontSize: 16
  },
  activeModems: {
    justifyContent: 'center',
    flexDirection: 'row'
  },
  modemData: {
    justifyContent: 'space-evenly',
    marginLeft: 10
  },
  AndroidSafeArea: {
    flex: 1,
    backgroundColor: "white",
  }
});


export default App;