import { useState } from "react";
import { View, Text, Button, KeyboardAvoidingView, Platform } from "react-native";
import { Btn } from "../components/atoms/Btn";
import { Spacer } from "../components/atoms/Spacer";
import { TWLogo } from "../components/atoms/TelaWatchLogo";
import { TextEntry } from "../components/atoms/TextEntry";
import { colors } from "../config/globalStyle";
import { signUp } from "../Firebase";

export function Signup({navigation}) {
  const [company, setCompany] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confPass, setConfPass] = useState("")
  const behavior = Platform.OS == 'android' ? "" : "padding"

    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      
      <KeyboardAvoidingView behavior={behavior} style={{flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center'}}>
      <TWLogo size={30} style={{ paddingBottom: 30 }} />

        <View style={{ width: '80%', alignItems: 'center'}}>

        <TextEntry icon="users" placeholder={"Company Name"} inputState={company} onChangeText={setCompany}/>
        <Spacer height={5}/>  

        <TextEntry icon="user" placeholder={"Name"} inputState={name} onChangeText={setName}/>
        <Spacer height={5}/>

        <TextEntry icon="mail" placeholder={"Email"} inputState={email} onChangeText={setEmail}/>
        <Spacer height={10}/>

        <TextEntry icon="lock" placeholder={"Password"} inputState={password} onChangeText={setPassword} passwordEntry={true}/>
        <Spacer height={5}/>

        <TextEntry icon="lock" placeholder={"Confirm Password"} inputState={confPass} onChangeText={setConfPass} passwordEntry={true}/>
        <Spacer height={15}/>

        <Btn label="Signup" fillWidth={true} onPress={() => {
          signUp(company, name, email, password).then(() => {
            navigation.navigate("Verify")
          })
          
        }} />

        <Spacer height={10}/>
        <Btn label="Login" fillWidth={true} color={colors.grey} onPress={() => {
          navigation.navigate("Login")
        }}/>

        </View>
      </KeyboardAvoidingView>
    </View>
    );
  }