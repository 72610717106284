import { useEffect, useState } from "react"
import { Text, View, StyleSheet} from "react-native"
import { Switch, TextInput } from 'react-native-paper';
import { useSelector, useDispatch } from "react-redux";
import ToggleLabel from "../../../atoms/ToggleLabel";
import * as reducers from '../../../../redux_slices/reducers'

import SegmentedControl from '@react-native-segmented-control/segmented-control';

import { UpdateSiteSettings } from "../../../../Firebase";

import { Spacer } from "../../../atoms/Spacer";

  

export const BandwidthSettings = () => {
  const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings


    const [stateAlarm, setStateAlarm] = useState(settings.StateAlarm);
    const [uptimeAlarm, setUptimeAlarm] = useState(settings.UptimeAlarm);

    const BandwidthSelect = [500, 1000, 2000, 3000, 5000]

    //const [maxBandwidthIndex, setMaxBandwidthIndex] = useState(BandwidthSelect.indexOf(settings.Bandwidth.MaxBandwidth));
    const [maxBandwidthIndex, setMaxBandwidthIndex] = useState(0);

    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.StateAlarm`] = stateAlarm
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.UptimeAlarm`] = uptimeAlarm
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.Bandwidth.MaxBandwidth`] = BandwidthSelect[maxBandwidthIndex]

            UpdateSiteSettings({id: sectionData.lincrID, setObj: obj})

        }
    }, [settingsSaved])



    return (
        <View>
            <Text>Max Bandwidth</Text>
            <Spacer height={5}/>
            <SegmentedControl
                        selectedIndex={maxBandwidthIndex}
                        values={BandwidthSelect.map(val => val.toString())}
                        onChange={(event) => {
                            setMaxBandwidthIndex(event.nativeEvent.selectedSegmentIndex)
                          }}
                        style={{ flexGrow: 1}}
                        tintColor="rgba(0,0,0,0.4)"
                        backgroundColor="white"
                    />
                    <Spacer height={15}/>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <ToggleLabel title="When any port reaches 90% of max" value={uptimeAlarm} onValueChange={(val) => setUptimeAlarm(val)} />
        </View>
    )
}



const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: 'rgba(0,0,0,1)',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 16,
      height: 30,
      flex: 1
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    }
  });
  