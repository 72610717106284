import React, { useState, useEffect } from 'react'
import { View, Text, StyleSheet, SafeAreaView, ActivityIndicator, Dimensions, Platform } from 'react-native'
import { WebView } from 'react-native-webview';
function WebPageLoading(){
    return (
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, borderWidth: 1 }}>
            <ActivityIndicator/>
            <Text>Loading...</Text>
        </View>
    )
}

export function UpstreamNoiseGraph({ lincrID, deviceName, slot, port, minY=0, minX=0, maxX=50 }) {
    const [showLoad, setShowLoad] = useState(false);
    const uri = `https://telawatch-next.herokuapp.com/upstream/charts?lincrID=${lincrID}&deviceName=${deviceName}&port=${port}&minY=${minY}&minX=${minX}&maxX=${maxX || 100}`;

    // Conditional component based on platform
    const RenderComponent = Platform.OS === 'web' ? 'iframe' : WebView;

    return (
        <View style={{height: Dimensions.get('window').height * (Platform.OS == "web" ? 0.3 : Platform.isPad ? 0.2 : 0.25), justifyContent: 'center', alignItems: 'center'}}>
            <RenderComponent
                scrollEnabled={false}
                startInLoadingState={true}
                onLoad={() => setShowLoad(true)}
                originWhitelist={['*']}
                source={{ uri }} // For WebView
                src={uri} // For iframe
                style={Platform.OS == "web" ? styles.webviewIframe : styles.webview}
                cacheEnabled={true}
                automaticallyAdjustContentInsets={false}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    webview:{
        borderRadius: 10,
        resizeMode: 'contain',
        width: Dimensions.get('window').width * (Platform.isPad ? 0.5 : 0.8),
        flex: 1
    },
    webviewIframe: {
        height: Dimensions.get('window').height * 0.3,
        width: Dimensions.get('window').width * 0.3,
        borderRadius: 10,
        resizeMode: 'contain',
        overflow: 'hidden',
        flex: 1
    }
})
