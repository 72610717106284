import { useState } from "react"
import { Text, View,} from "react-native"
import { Switch } from 'react-native-paper';
import ToggleLabel from "../../../atoms/ToggleLabel";

import SegmentedControl from '@react-native-segmented-control/segmented-control';
import RangeSlider from "../../../atoms/RangeSlider";
import { Spacer } from "../../../atoms/Spacer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";


export const TemperatureSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [inletSlider, setInletSlider] = useState(false);
    


    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings

    const [tmSliderAlarmMajor, settmSliderAlarmMajor] = useState(settings.QAMTemp.MajorAlarm ?? false);
    const [tmSliderAlarmMinor, settmSliderAlarmMinor] = useState(settings.QAMTemp.MinorAlarm ?? false);
    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))
            //console.log("Saving rfgw temp Settings - ") 

            let obj = {}
            if (inletSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.QAMTemp.Lower`] = inletSlider.min
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.QAMTemp.Upper`] = inletSlider.max
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.QAMTemp.MajorAlarm`] = tmSliderAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.QAMTemp.MinorAlarm`] = tmSliderAlarmMinor


            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View style={{paddingHorizontal: 5}}>
            <RangeSlider min={70} max={200} step={1} onValueChange={setInletSlider} units="˚F" label="QAM Temperature Thresholds" defaultValue={{min: (settings.QAMTemp.Lower - 70) / 130, max: (settings.QAMTemp.Upper - 70) / 130}} colors={["green", "orange", "red"]}/>
            <Spacer height={10}/>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={tmSliderAlarmMajor} onValueChange={(val) => settmSliderAlarmMajor(val)} style={{borderWidth: 1, borderColor: 'orange', backgroundColor: 'white'}}/>
            <ToggleLabel title="Major Alarm" value={tmSliderAlarmMinor} onValueChange={(val) => settmSliderAlarmMinor(val)} style={{borderWidth: 1, borderColor: 'red', backgroundColor: 'white'}}/>
            </View>
        </View>
    )
}