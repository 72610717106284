import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import { colors } from "../../../../config/globalStyle"

import { Spacer } from "../../../atoms/Spacer"

export const UpstreamSNRSection = ({data, names = [], settings}) => {
  
  return <View>

    <View style={{flexDirection: 'row', paddingVertical: 7}}>
      <Text style={{flex: 2, fontWeight: 'bold'}}>Service Group</Text>
      <Text style={{flex: 0.5, fontWeight: 'bold'}}>Port</Text>
      <Text style={{flex: 1, fontWeight: 'bold', textAlign: 'center'}}>Avg US SNR</Text>
      <Text style={{flex: 1, fontWeight: 'bold', textAlign: 'center'}}>Min Ch US SNR</Text>
    </View>
    {
      data.map(card => {
        let avgcolor = Math.round(card.avgSNR) < settings.usSnr.Lower ? colors.red : Math.round(card.avgSNR) < settings.usSnr.Upper ? colors.orange : colors.green
        let mincolor = Math.round(card.minSNR) < settings.usSnr.Lower ? colors.red : Math.round(card.minSNR) < settings.usSnr.Upper ? colors.orange : colors.green
        return <View style={{flexDirection: 'row', padding: 3}}>
          <Text style={{flex: 2, padding: 3}}>{names[card.card + "/" + card.port] ?? card.card + "/" + card.port}</Text>
          <Text style={{flex: 0.5, padding: 3}}>{card.card}/{card.port}</Text>
          <Text style={{flex: 1, textAlign: 'center', backgroundColor: avgcolor, padding: 3, borderRadius: 7, overflow: 'hidden'}}>{Math.round(card.avgSNR)}</Text>
          <Spacer width={3}/>
          <Text style={{flex: 1, textAlign: 'center', backgroundColor: mincolor, padding: 3, borderRadius: 7, overflow: 'hidden'}}>{Math.round(card.minSNR)}</Text>
          </View>
      })
    }
  </View>
}