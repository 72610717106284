import { useEffect, useState } from "react"
import { StyleSheet, View, Text, TouchableOpacity, Alert, Animated} from "react-native"
import {FontAwesome, Ionicons, Octicons, AntDesign} from '@expo/vector-icons'

import { Spacer  } from "../atoms/Spacer"
import { useDispatch, useSelector } from "react-redux"
import * as reducers from '../../redux_slices/reducers'

import ws_logger from '../../logger'

export const Section = (props) => {
    const [showChildren, setShowChildren] = useState(true)
    const dispatch = useDispatch()
    const hideAllSections = useSelector(state => state.nav.hideAllSections)
    

    useEffect(() => {
      if (hideAllSections) setShowChildren(false)
      else setShowChildren(true)
    }, [hideAllSections])
    return (
      <View style={{ paddingHorizontal: 3, width: '100%'}}>
        <View elevation={5} style={{borderWidth: 0, width: '100%', padding: 9, borderColor: '#bbbbbb', borderRadius: 10, backgroundColor: '#ffffff', shadowOffset: {height: 2, width: 0}, shadowColor: 'black', shadowOpacity: 0.13, shadowRadius:3 }} >
          <View style={[ {flexDirection: 'row', flex: 1, width: '100%', maxHeight: 30, alignItems: 'center', paddingBottom: 3 }]}>
            <TouchableOpacity style={{flex: 1, height: 20}} onPress={() => setShowChildren(!showChildren)} >
            <Text style={[{ paddingTop: 3 }]}>{props.title}</Text>
            </TouchableOpacity>
            {
              !props.hideSettings ?
            <TouchableOpacity style={{ position: 'absolute', right: 25 }} onPress={() => {
              dispatch(reducers.nav.setSectionSettings(true)); 
              dispatch(reducers.nav.setSectionData({title: props.title, settings: props.settings, lincrID: props.lincrID, extra: props.extraOpts}))
              ws_logger.log("Open Tile Settings", {tileName: props.title})
              }}>
              <Octicons name="gear" size={17} color="black" style={{ padding: 5 }} />
            </TouchableOpacity> : <></>
            }
            <TouchableOpacity style={{ position: 'absolute', right: 0 }} onPress={() => {
              setShowChildren(!showChildren)
              ws_logger.log("Hide Tile", {state: showChildren ? "hidden" : 'shown', tileName: props.title})
            }} onLongPress={() => {
              dispatch(reducers.nav.setHideAllSections(!hideAllSections));
              ws_logger.log("Hide All Tiles", {state: !hideAllSections ? "hidden" : 'shown'})
            }}>
              <AntDesign name={showChildren ? "minus" : 'plus'} size={20} color="black" style={[{ }]} />
            </TouchableOpacity>
          </View>
          {showChildren ? props.children : <></>}
        </View>
        <Spacer height={10} />
      </View>
    )
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: '#000',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 18,
      lineHeight: 23,
      flex: 2
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    },
  });
  