import { useState } from "react"
import { Text, View,} from "react-native"
import { Switch } from 'react-native-paper';
import ToggleLabel from "../../../atoms/ToggleLabel";

import SegmentedControl from '@react-native-segmented-control/segmented-control';
import RangeSlider from "../../../atoms/RangeSlider";
import { Spacer } from "../../../atoms/Spacer";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";


export const QAMSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [chCount, setChCount] = useState("8")
    const [chCountIndex, setChCountIndex] = useState(1)
    const [qamSlider, setQamSlider] = useState();

    const setCh = (val) => {
        switch (val) {
            case 0:
            case "4":
                setChCount("4");setChCountIndex(0); break
            case 1:
            case "8":
                setChCount("8");setChCountIndex(1); break
            default:
                break;
        
        }
    }

    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    
    const settings = sectionData.settings
    const cardsInserted = sectionData.extra ? sectionData.extra : "8"

    const [qamAlarmMajor, setQAMAlarmMajor] = useState(settings.QAMOutput.MajorAlarm);
    const [qamAlarmMinor, setAlarmMinor] = useState(settings.QAMOutput.MinorAlarm);

    useEffect(() => {
        setCh(cardsInserted)
    }, [cardsInserted])

    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))
            //console.log("Saving rfgw qam output Settings - ") 

            let obj = {}
            if (qamSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.QAMOutput.Upper`] =  qamSlider.max / 100
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.QAMOutput.Lower`] =  qamSlider.min / 100
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.QAMOutput.MajorAlarm`] = qamAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.QAMOutput.MinorAlarm`] = qamAlarmMinor

            obj[`${JSON.parse(selectedDevice).DisplayName}.CardsInserted`]  = chCount
            console.log(obj, sectionData.lincrID)
            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                <Text style={{fontSize: 16, paddingLeft: 5}}>Cards Inserted</Text>
                <SegmentedControl
                        selectedIndex={chCountIndex}
                        values={['4', '8']}
                        onChange={(event) => {
                            setChCount(((event.nativeEvent.selectedSegmentIndex * 4) + 4).toString());
                            setChCountIndex(event.nativeEvent.selectedSegmentIndex)
                          }}
                        style={{ flexGrow: 1, maxWidth: 200 }}
                        tintColor="rgba(0,0,0,0.4)"
                        backgroundColor="white"
                        // tintColor="black"
                    />
            </View> 
            <Spacer height={10}/>
            <RangeSlider min={0} max={100} step={1} onValueChange={setQamSlider} units="%" label="Utilization (Avg Card %)" defaultValue={{min: settings.QAMOutput.Lower * 1, max: settings.QAMOutput.Upper * 1}} colors={["green", "orange", "red"]}/>

            <Spacer height={10}/>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={qamAlarmMajor} onValueChange={(val) => setQAMAlarmMajor(val)} style={{borderWidth: 1, borderColor: 'orange', backgroundColor: 'white'}}/>
            <ToggleLabel title="Major Alarm" value={qamAlarmMinor} onValueChange={(val) => setAlarmMinor(val)} style={{borderWidth: 1, borderColor: 'red', backgroundColor: 'white'}}/>
            </View>
        </View>
    )
}