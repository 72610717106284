import { View, Text, Button } from "react-native";
import { Btn } from "../components/atoms/Btn";
import { TWLogo } from "../components/atoms/TelaWatchLogo";
import {Spacer} from '../components/atoms/Spacer'
import { useEffect } from "react";

export function Landing({ navigation, user }) {
    useEffect(() => {
        if (user) {
            if (!user.emailVerified) navigation.navigate("Verify")
        }
    }, [])
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <TWLogo size={30} style={{paddingBottom: 30}}/>

            <View style={{width: '80%', maxWidth: 400}}>
                <Btn label="Login" onPress={() => navigation.navigate("Login")} fillWidth={true}/>
                <Spacer height={5}/>
                <Btn label="Signup" onPress={() => navigation.navigate("Signup")} fillWidth={true}/>
            </View>

        </View>
    );
  }