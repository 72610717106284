import {FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons'
import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import { colors } from '../../../../config/globalStyle'
import hardware from '../../../../config/hardware'
import { Btn } from '../../../atoms/Btn'
import { Spacer } from '../../../atoms/Spacer'
import { UpstreamName } from '../../../atoms/UpstreamName'
import { ActiveModems } from '../../../molecules/ActiveModems'

const ServiceGroupRow = ({name, group, active, total, settings}) => {
  
    let perc = Math.round((active / total) * 100) / 100
    let color
    if (group == "Total") {
      color = perc < settings.TotalModemCount.Lower ? colors.red : perc < settings.TotalModemCount.Upper ? colors.orange : colors.green
    
    } else {
      color = perc < settings.SGModemCount.Lower ? colors.red : perc < settings.SGModemCount.Upper ? colors.orange : colors.green
    }
    return <>
      <View elevation={3} style={{width: Platform.OS == "web" ? '60%' : '100%', elevation: 3, justifyContent: 'space-around', marginHorizontal: 3, flexDirection: 'row', flex: 1, padding: 7, borderRadius: 7,  backgroundColor: '#ffffff', shadowOffset: {height: 2, width: 0}, shadowColor: 'black', shadowOpacity: 0.13, shadowRadius:3 }}>
          <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', padding: 5}}>
          <FontAwesome name="circle" size={20} color={color}/>
          <Spacer width={9} />
          <View>
          {
            group == "Total" ? <Text>Total</Text> :<UpstreamName name={name} port={group}/>
          }
          
          

          {
            group !== "Total" ? <>
          <Spacer height={5}/>
          <Text>{group}</Text>
          </> : <></>
          }
          </View>
        </View>
        <ActiveModems activeModems={active} totalModems={total} ringColor={color} small={true}/>
        <Spacer width={5}/>
      </View>
      <Spacer height={7} />
      </>
  }
  
export const ModemBreakdown = ({ showBreakdown, cardSummary = [], names, settings}) => {
  
    const getTotal = (cards) => {
      if (!cards) return { id: "Total", active: 0, total: 0 }
      let totalobj = cards.filter(card => card.id == "Total")
      if (totalobj.length == 0) {
        let total = 0
        let active = 0
        for (let card of cards) {
          total += card.total
          active += card.active
        }
        return { id: "Total", active: active, total: total }
      } else {
        return totalobj[0]
      }
    }
  
  
    return (
      <>
        <TouchableOpacity onPress={() => {showBreakdown(false)}} style={{padding: 7, flexDirection: 'row', alignItems: 'center', borderRadius: 7, borderWidth: 1, borderColor: '#eeeeee'}}>
            <FontAwesome name="chevron-left" size={15} color="rgba(0,0,0,0.5)"/>
            <Spacer width={10}/>
            <Text>Back</Text>
          </TouchableOpacity>
          <Spacer height={5}/>
        
        <View style={{justifyContent: 'center', alignItems: 'center', width: '100%'}}>
        <Text style={{fontSize: 18}}>Service Group Breakdown</Text>
        <Spacer height={15}/>
        <ServiceGroupRow name={"Total"} group={"Total"} active={getTotal(cardSummary).active} total={getTotal(cardSummary).total} settings={settings}/>
        <Spacer height={15}/>
  
        {
          cardSummary.map((card, index) => {
            let id = card.card + "/" + card.port
            
            if (card.id == "Total" || id.includes("undefined")) return <></>
            else return <ServiceGroupRow name={names ? names[id] ? names[id] : ""  : ""} group={id} active={card.active} total={card.total} settings={settings} key={id + Math.round(Math.random() * 100)}/>
          })
        }
      </View>
      </>
    )
  }