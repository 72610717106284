import { useState } from "react"
import { Text, View,} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";
import RangeSlider from "../../../atoms/RangeSlider";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";



export const TemperatureSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )
    const [tempSlider, setTempSlider] = useState()
    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    const [tempAlarmMajor, setTempAlarmMajor] = useState(settings.TotalModemCount.MajorAlarm);
    const [tempAlarmMinor, setTempAlarmMinor] = useState(settings.TotalModemCount.MinorAlarm);
    
    useEffect(() => {
        if (settingsSaved){
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))

            let obj = {}
            if (tempSlider) {
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.Temp.InletLower`] = tempSlider.min 
                obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.Temp.InletUpper`] = tempSlider.max
            }

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.TotalModemCount.MajorAlarm`] = tempAlarmMajor
            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.TotalModemCount.MinorAlarm`] = tempAlarmMinor

            if (Object.keys(obj).length > 0) UpdateSiteSettings({ setObj: obj, id: sectionData.lincrID })
        }
    }, [settingsSaved])

    return (
        <View>
            <View style={{paddingBottom: 10}}>
            <RangeSlider min={60} max={140} step={1} onValueChange={setTempSlider} units="˚F" label="Inlet Temperature Threshold" defaultValue={{min: (settings.Temp.InletLower - 60) / 80, max: (settings.Temp.InletUpper - 60) / 80}} colors={["green", "orange", "red"]}/>

            <View style={{flexDirection: 'row'}}>
            <ToggleLabel title="Minor Alarm" value={tempAlarmMajor} onValueChange={(val) => setTempAlarmMajor(val)} style={{borderWidth: 1, borderColor: 'orange', backgroundColor: 'white'}}/>
            <ToggleLabel title="Major Alarm" value={tempAlarmMinor} onValueChange={(val) => setTempAlarmMinor(val)} style={{borderWidth: 1, borderColor: 'red', backgroundColor: 'white'}}/>
            </View>

            </View>
        </View>
    )
}