const LOGIN_TO_LOG = "LOGIN";
const SAVELOG = "SAVELOG";
const EXIT_LOG = "DISCONNECT";
const AUTH = "AUTHENTICATE";

const APIKEY = "HjND7inkaa0s3JnxYDHZwDQ0eYg="

class WebSocketLogger {
  constructor() {
    this.socket = null;
    this.isConnected = false;
    this.reconnectAttempts = 0;
    this.userid = null
  }

  connect(url) {
    console.log(`Connecting to log server...`);
    try{
    this.socket = new WebSocket(url);
    this.socket.onopen = () => {
      console.log('Logging system connected!');
      this.socket.send(JSON.stringify({
        type: AUTH,
        apikey: APIKEY
      }))
      this.isConnected = true;
      this.reconnectAttempts = 0;
    };

    this.socket.onclose = () => {
      console.log('Logging system disconnected!');
      this.isConnected = false;
      this.reconnect(url);
    };

    this.socket.onerror = (error) => {
      console.log(`WebSocket error: ${error}`);
    };

    this.socket.onmessage = (event) => {
      console.log(`Received message: ${event.data}`);
    };
    
  } catch(e) {
      console.log(e)
    }
  }

  reconnect(url) {
    const reconnectDelay = Math.pow(2, this.reconnectAttempts) * 1000;
    console.log(`Reconnecting in ${reconnectDelay} ms...`);
    setTimeout(() => {
      this.connect(url);
      this.reconnectAttempts += 1;
    }, reconnectDelay);
  }

  login(userId) {
    this.userid = userId
    if (this.isConnected) {
      this.socket.send(JSON.stringify({
        type: LOGIN_TO_LOG,
        userId: userId
      }));
    } else {
      console.log('WebSocket is not connected!');
    }
  }

  log(action, details) {
    if (this.isConnected) {
      this.socket.send(JSON.stringify({
        type: SAVELOG,
        action: action,
        details: JSON.stringify(details),
        uid: this.userid
      }));
    } else {
      console.log('WebSocket is not connected!');
    }
  }

  exit() {
    if (this.isConnected) {
      this.socket.send(JSON.stringify({
        type: EXIT_LOG
      }));
      this.socket.close();
      this.socket = null;
    } else {
      console.log('WebSocket is not connected!');
    }
  }
}

const logger = new WebSocketLogger();

export default logger;
