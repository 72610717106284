import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"

import { Spacer } from "../../../atoms/Spacer"
import { ActiveModems } from "../../../molecules/ActiveModems"
import { TapabbleFan } from "../../../atoms/TappableFan"
import {FontAwesome} from '@expo/vector-icons'
import { Btn } from "../../../atoms/Btn"

import ws_logger from '../../../../logger'

export const PortUtilSection = ({setUtilModalVisible}) => {
    return <View style={{ flexDirection: 'row', justifyContent: 'center', padding: 7}}>
    <Btn label={"Downstream"} onPress={() => { setUtilModalVisible("DS"); ws_logger.log("Navigate", {pageName: "Downstream Port Util"}) }}/>
    <Spacer width={15} />
    <Btn label={"Upstream"} onPress={() => { setUtilModalVisible("US"); ws_logger.log("Navigate", {pageName: "Upstream Port Util"}) }}/>
  </View>
  }