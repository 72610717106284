import { HardwareStatusSettings } from "./HardwareStatus";
import { GBESettings } from "./GBEInput";
import { TemperatureSettings } from "./Temperature";
import { QAMSettings } from "./QamOutput";
import { FanSettings } from "./Fan";

const router =  {
    "Hardware Status": <HardwareStatusSettings/>,
    "GBE Input": <GBESettings/>,
    "Temperature": <TemperatureSettings/>,
    "QAM Output": <QAMSettings/>,
    "Fan Status": <FanSettings/>,
};

export default router