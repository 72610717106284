import { configureStore } from '@reduxjs/toolkit'
import * as reducers from './redux_slices/reducers'

export const store = configureStore({
  reducer: {
    user: reducers.user.counterSlice.reducer,
    sites: reducers.sites.counterSlice.reducer,
    nav: reducers.nav.counterSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  })
})