import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import { useState } from "react"
import { Spacer } from "../../../atoms/Spacer"
import { colors } from "../../../../config/globalStyle"
import { Feather } from '@expo/vector-icons'
import { useDispatch } from "react-redux"
import * as reducers from '../../../../redux_slices/reducers'

import ws_logger from '../../../../logger'

export const ModuleSection = ({ modules, settings }) => {

  const [showSpecs, setShowSpecs] = useState(false)
  const FontSize = 12.5
  const dispatch = useDispatch()

  return <View>
    <View style={{ flexDirection: 'row', paddingVertical: 10 }}>
      <TouchableOpacity style={{ flex: 1, borderWidth: showSpecs ? 1 : 0, padding: 5, borderRadius: 7, alignItems: 'center', backgroundColor: showSpecs ? 'rgba(0,0,0,0)' : colors.blue }} onPress={() => {setShowSpecs(false); ws_logger.log("Switch Tile View", {tileName: "Module Summary", id: "Status"})}}>
        <Text  style={{color: !showSpecs ? 'white' : 'black'}}>Status</Text>
      </TouchableOpacity>
      <Spacer width={15} />
      <TouchableOpacity style={{ flex: 1, borderWidth: showSpecs ? 0 : 1, padding: 5, borderRadius: 7, alignItems: 'center', backgroundColor: showSpecs ? colors.blue : 'rgba(0,0,0,0)' }} onPress={() => {setShowSpecs(true); ws_logger.log("Switch Tile View", {tileName: "Module Summary", id: "Specs"})}}>
        <Text style={{color: showSpecs ? 'white' : 'black'}}>Specs</Text>
      </TouchableOpacity>
    </View>
    <View style={{flexDirection: 'row'}}>
      <View style={{flex: 2.3}}/>
    <TouchableOpacity onPress={() => {dispatch(reducers.nav.setShowInfo("ModuleSummary"))}}>
                <Feather name="info" color={colors.blue} size={17} style={{paddingHorizontal: 5}}/>
                </TouchableOpacity>
                <View style={{flex: 4}}/>
    </View>
    <View style={{flexDirection: 'row'}}>
      <Text style={{flex: 0.3, fontSize: 11}}>Slot</Text>
      <Text style={{flex: 0.75, fontSize: 11}}>Type</Text>

      {
        showSpecs ? <>
      <Text style={{flex: 0.5, fontSize: 11}}>CFEV</Text>
      <Text  style={{flex: 0.5, fontSize: 11}}>HWV</Text>
      <Text  style={{flex: 0.9, fontSize: 11}}>Serial</Text>
      </> : <>
      <Text style={{fontSize: 10, flex: 0.25, flexDirection: 'row'}}>ST</Text>
      
      <Text  style={{flex: 0.25, fontSize: 11}}>Red</Text>
      <Text  style={{flex: 0.5, fontSize: 11}}>Temp</Text>
      <Text  style={{flex: 0.9, fontSize: 11}}>Uptime</Text>
      </>
      }
      <Text></Text>
    </View>
    {
      modules.map((card, i) => {
        
        let tempColor = colors.green


        tempColor = card.temp >= settings.ModuleTemp.Upper ? colors.red : card.temp >= settings.ModuleTemp.Lower ? colors.orange : tempColor
        
        let cardTypecolor = tempColor
        if (card.state[0] == "S") cardTypecolor = colors.blue
        return <View style={{ borderBottomWidth: i == modules.length - 1 ? 1 : 0, borderTopWidth: 1, flexDirection: 'row', paddingHorizontal: 5, borderColor: '#eeeeee' }}>
          <Text style={{ fontSize: FontSize, flex: 0.2, padding: 3 }}>{card.slot}</Text>
          <Text style={{ fontSize: FontSize, flex: 0.85, backgroundColor: cardTypecolor, padding: 3 }}>{card.type}</Text>

          {
            showSpecs ? <>
          <Text style={{ fontSize: FontSize, flex: 0.5, padding: 3 }}>{card.cfev}</Text>
          <Text style={{ fontSize: FontSize, flex: 0.4, padding: 3 }}>{card.hwv}</Text>
          <Text style={{ fontSize: FontSize, flex: 1.1, padding: 3 }}>{card.serial}</Text>
          </> : <>
          <Text style={{ fontSize: FontSize, flex: 0.25, padding: 3 }}>{card.state[0]}</Text>
          <Text style={{ fontSize: FontSize, flex: 0.25, padding: 3 }}>{card.red}</Text>
          <Text style={{ fontSize: FontSize, flex: 0.5, backgroundColor: tempColor, padding: 3 }}>{card.temp}</Text>
          <Text style={{ fontSize: FontSize, flex: 1, padding: 3, }}>{card.uptime}</Text>
          </>
          }
        </View>
      })
    }
  </View>
}