import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"

import { Spacer } from "../../../atoms/Spacer"
import { TapabbleFan } from "../../../atoms/TappableFan"


export const FanSection = ({ fans }) => {
    return <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'space-around', paddingVertical: 10 }}>
        {

            fans ? [fans.f1Rpm, fans.f2Rpm, fans.f3Rpm, fans.f4Rpm].map((rpm, i) => {
                return <TapabbleFan rpm={parseInt(rpm)} key={"c40fan" + i} />
            }) : <></>
        }
    </View>
}