import { View, Text, TouchableOpacity, KeyboardAvoidingView } from "react-native";
import { useState } from "react";
import { useSelector } from 'react-redux'
import { Spacer } from "../components/atoms/Spacer";
import { InputField } from "../components/atoms/InputField";

import { addSite } from "../Firebase";

export const AddSitePage = ({dismiss}) => {
    const behavior = Platform.OS == 'android' ? "" : "padding"
    const [lincrid, setlincrid] = useState("")
    const [lincrpass, setLincrPass] = useState("")
    const [errormsg, seterrormsg] = useState("")
  
    return <>
        <View style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.2)'}} onStartShouldSetResponder={() => dismiss(false)}>
        
        </View>
        <KeyboardAvoidingView  behavior={behavior} style={{ position: 'absolute', bottom: 0, width: '100%' }} shadowOffset={{width: 0, height: -1}} shadowOpacity={0.3} shadowColor="black">
            <View style={{flex: 1, width: '100%', backgroundColor: '#ffffff', minHeight: 150, paddingHorizontal: 10, paddingVertical: 5, paddingBottom:40, borderTopLeftRadius: 10, borderTopRightRadius: 10, paddingTop: 10, alignItems: 'center'}}>
                <Spacer height={20}/>
                <Text style={{fontSize: 23}}>Add a Site</Text>
                <Spacer height={20}/>
                <InputField icon='server' placeholder={"Lincr ID"} inputState={lincrid} setInputState={setlincrid} keyboardType="numeric"/>
                <Spacer height={10}/>
                <InputField icon='lock' placeholder={"Password"} passwordEntry={true} inputState={lincrpass} setInputState={setLincrPass}/>
                <Spacer height={10}/>
                <TouchableOpacity style={{width: '80%', borderWidth: 0, alignItems: 'center', borderRadius: 7, backgroundColor: 'blue'}} onPress={async () => {
                  let res = await addSite(lincrid, lincrpass)
                  if (res.message) seterrormsg(res.message)
                  else {
                    dismiss(!true)
                  }
                }}>
                  <Text style={{padding: 10, color: 'white'}}>Add Site</Text>
                </TouchableOpacity>
                <Text style={{padding: 10, color: 'red'}}>{errormsg}</Text>
  
                <Text></Text>
            </View>
        </KeyboardAvoidingView>
        </>
  }