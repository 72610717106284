
import { Text, TouchableOpacity, StyleSheet } from "react-native"
import { colors } from "../../config/globalStyle"

export const Btn = ({label, color=colors.blue, fontColor=colors.white, fillWidth=false, onPress}) => {
    return <TouchableOpacity onPress={onPress} style={[, styles.touchableStyle, { backgroundColor: color}]}>
        <Text style={[
            { flex: fillWidth ? 1 : 0, color: fontColor },
            styles.textStyle
        ]}>
            {label}
        </Text>
    </TouchableOpacity>
}

const styles = StyleSheet.create({
    touchableStyle: {
        flexDirection: 'row', 
        paddingVertical: 7, 
        paddingHorizontal: 15, 
        borderRadius: 5,
        margin: 2,
        elevation: 4,
        shadowColor: 'black',
        shadowOffset: {width: 0, height: 1},
        shadowOpacity: 0.3,
        shadowRadius: 2
    },
    textStyle: {
       
        textAlign: 'center', 
        fontSize: 16
    }
})