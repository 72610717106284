import { useEffect, useState } from "react";
import { View, Text, Button, ScrollView, Modal, TouchableOpacity, Platform } from "react-native";
import { fetchSites, registerUserListener, registerLincrListener, registerPushToken } from "../Firebase";
import { AndroidImportance, getPermissionsAsync, requestPermissionsAsync, setNotificationChannelAsync, setNotificationHandler } from 'expo-notifications';
import { useDispatch, useSelector } from 'react-redux'
import { LoadingIndicator } from "../components/atoms/LoadingIndicator";
import { getColorfromDF } from '../Utilities'
import { FontAwesome, Octicons, Entypo } from '@expo/vector-icons'
import { Spacer } from "../components/atoms/Spacer";
import { TWLogo } from "../components/atoms/TelaWatchLogo";
import { Btn } from "../components/atoms/Btn";
import { colors } from "../config/globalStyle";
import { InputField } from "../components/atoms/InputField";
import { AddSitePage } from "./AddSite";
import { UserSettingsPage } from "./UserSettings";
import * as reducers from '../redux_slices/reducers'

const HomeHeader = ({showUserSettings}) => {
  return <View style={{ overflow: 'hidden' }}>
    <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: 'white', }} shadowOffset={{ width: 0, height: 1 }} shadowColor={'rgba(0,0,0,0.3)'} shadowRadius={3} shadowOpacity={1}>
      {
        //Header
      }
      <TWLogo size={25} />

      <TouchableOpacity style={{ position: 'absolute', right: 15 }} onPress={() => {showUserSettings(true)}}>
        <Octicons name="gear" size={21} color="black" />
      </TouchableOpacity>

    </View>
  </View>
}

const SiteRow = ({ name, id, devices, connectivity, navigation }) => {

  return <TouchableOpacity
      style={{ padding: 5, paddingLeft: 20, borderBottomWidth: 1, borderColor: '#eeeeee', flexDirection: 'row', alignItems: 'center' }}
      onPress={async() => { 
        navigation.navigate("Details", {lincrID: id, devices: devices, connectivity: connectivity, name: name})
      }}
    >
    <View>
      <Text style={{fontSize: 16}}>{name}</Text>
      <View style={[{ flexDirection: 'row', alignItems: 'center' }]}>
        <FontAwesome name="square" size={21} color={colors[getColorfromDF(connectivity)]} />
        <Spacer width={5} />
        {
          devices.filter(device => device.DisplayName !== "Health Checks").map((device, i) => {
            return <FontAwesome name="circle" size={19} color={colors[device.color]} key={`${i} ${id} ${Math.random() * 100}`} style={{ paddingRight: 3 }} />
          })
        }
      </View>

    </View>

    <FontAwesome name="chevron-right" size={18} color={"#dddddd"} style={{ position: 'absolute', right: 10 }} />
  </TouchableOpacity>
}

function ModalScreen({ dismiss }) {
  return (
    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-end' }}>
      <View style={{ height: "50%", width: '100%', backgroundColor: "#fff", justifyContent: "center" }}>
        <Text>Testing a modal with transparent background</Text>
        <Button onPress={() => dismiss(false)} title="Dismiss" />
      </View>
    </View>
  );
}

export function HomeScreen({ navigation, setSignedIn }) {
 
  const [header, setHeader] = useState([])
  const sites = useSelector((state) => state.sites.sites)
  const user = useSelector((state) => state.user.user)
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const sitesLoaded = useSelector((state) => state.sites.sitesLoaded)
  const [addSiteVisible, showAddSite] = useState(false)
  const [userSettingsVisible, showUserSettings] = useState(false)

  /*useEffect(() => {
    console.log("fetching sites")
    const fetchHeader = async () => {
      const header = await getSiteHeader()
      setHeader(header)
    }

    let unsubscribe = setInterval(() => fetchHeader(), 60000)
    return (() => unsubscribe())
  }, [])*/

  useEffect(() => {
    let unsub = registerLincrListener()
    return (() => unsub())
  }, [])

  useEffect(() => {
    if (user && userLoaded) {
      // Pull Users Document
      //console.log("Pulling User Doc")
      var userListener = registerUserListener()
    }
    return (() => userListener)
  }, [userLoaded])

  useEffect(() => {
    //{ lincrID: doc.id, connectivity: siteData.Connectivity, devices: colr, name: siteData.Name }
    if (sites) {
      if (sites.length > 0) {
    setHeader(sites.reduce((acc, curr) => {
      acc.push({lincrID: curr.lincrID, name: curr.Name, connectivity: curr.Connectivity, devices: curr.DeviceList})
      return acc
    }, []).sort((a,b) => a.Name < b.Name))
  } else {
    setHeader([])
  }
    }
  }, [sites])

  useEffect(() => {
    registerPushToken()
  }, [])

  useEffect(() => {
    const registerForPushNotificationsAsync = async () => {
        if (Platform.OS === 'web') { return; }
            const { status: existingStatus } = await getPermissionsAsync();
            let finalStatus = existingStatus;
            console.log(finalStatus)
        if (existingStatus !== 'granted') {
          try {
            const { status } = await requestPermissionsAsync();
            finalStatus = status;
          } catch (e) {
              console.log(e)
            }
        }
        if (finalStatus !== 'granted') {
            alert("Failed to get push token for push notification! You can allow notifications from your device's settings page");
            
            return;
        }

        if (Platform.OS === 'android') {
          setNotificationChannelAsync('default', {
              name: 'default',
              importance: AndroidImportance.MAX,
              vibrationPattern: [0, 250, 250, 250],
              lightColor: '#FF231F7C',
          });
      }
       

        setNotificationHandler({
            handleNotification: async () => ({
                shouldShowAlert: true,
                shouldPlaySound: true,
                shouldSetBadge: false,
            }),
        });
        };
        registerForPushNotificationsAsync()
}, [])

  return (
    <View style={{ flex: 1 }}>
      <HomeHeader showUserSettings={showUserSettings}/>

      <Modal
        animationType="fade"
        transparent={true}
        visible={addSiteVisible}
        onRequestClose={() => {
          showAddSite(!addSiteVisible);
        }}>
        <AddSitePage dismiss={showAddSite} />
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={userSettingsVisible}
        onRequestClose={() => {
          showUserSettings(!userSettingsVisible);
        }}>
        <UserSettingsPage dismiss={showUserSettings} />
      </Modal>

      {
        sitesLoaded ? header.length > 0 ? <ScrollView showsVerticalScrollIndicator={false}>
          {
             (header?.sort((a,b) => {return (a.name >= b.name) ? 1 : -1})).map(site => {
              return <SiteRow name={site.name} id={site.lincrID} devices={site.devices} connectivity={site.connectivity} key={site.lincrID + Math.random() * 10} navigation={navigation}/>
            }) 
          }
        </ScrollView>: <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <Text style={{textAlign: 'center', width: '60%', fontSize: 16}}>To get started monitoring your equipment add a site below</Text>
              </View> : <LoadingIndicator />
      }

      <TouchableOpacity style={{ position: 'absolute', bottom: 20, right: 20, borderWidth: 0, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }} onPress={() => { showAddSite(true) }}>
        <Entypo name="squared-plus" size={55} color={colors.blue} />
      </TouchableOpacity>

    </View>
  );
}
