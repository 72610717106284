import { StyleSheet, View, Text } from 'react-native'
import {CircularProgress} from '../atoms/CircularProgress'

import { Spacer } from '../atoms/Spacer';

export function ActiveModems({ activeModems, totalModems, ringColor, error, small=false }) {
    let bgRingColor = ringColor;
    let Inactive = totalModems - activeModems
    let percent = Math.round(100 * (activeModems / totalModems))
  
    if (isNaN(percent)) {
      percent = 0
      ringColor = 'lightgrey'
      bgRingColor = 'lightgrey'
    }
    if (isNaN(Inactive)) Inactive = 0;
  
    return (
      <View style={styles.spaceEvenly}>
        {error && <Text style={styles.error}>There was an error loading this data. Please check your LINCR.</Text>}
        {!error && (<View style={styles.activeModems}>
          <CircularProgress
            percent={percent}
            ringColor={ringColor}
            textFontSize={small ? 14 : 21}
            radius={small ? 35 : 60}
            progressRingWidth={small ? 3 : 6}
            bgRingWidth={small ? 3 : 6}
            ringBgColor={bgRingColor}
          />
          <Spacer width={15} />
          <View style={[styles.modemData, {width: small ? 80 : 90}]}>
            <View style={{flexDirection: 'row', alignItems: 'center', width: small ? 80 : 90}}>
            <Text style={[styles.bodyText, {flex: 15, fontSize: small ? 12 : 14 }]}>Active</Text>
            <Text style={[styles.greenHeader, {textAlign: 'right',flex: 10, fontSize: small ? 12 : 14 }]}>{activeModems}</Text>
            </View>
  

            <View style={{flexDirection: 'row', alignItems: 'center', width: small ? 80 : 90}}>
            <Text style={[styles.bodyText, {flex: 15, fontSize: small ? 12 : 14 }]}>Total</Text>
            <Text style={[styles.greyHeader, {textAlign: 'right', flex: 10, fontSize: small ? 12 : 14 }]}>{totalModems}</Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center', width: small ? 80 : 90}}>
            <Text style={[styles.bodyText, {flex: 15, fontSize: small ? 12 : 14 }]}>Inactive</Text>
            <Text style={[styles.redHeader, {textAlign: 'right', flex: 10, fontSize: small ? 12 : 14 }]}>{totalModems - activeModems}</Text>
            </View>
  
          </View>
        </View>
        )}
      </View>
    );
  }
  

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textInput: {
      color: '#000',
      paddingRight: 5,
      paddingLeft: 5,
      fontSize: 18,
      lineHeight: 23,
      flex: 2
    },
    horiz: {
      flexDirection: 'row',
      width: '100%'
    },
    appButtonContainer: {
      elevation: 8,
      backgroundColor: "#009688",
      borderRadius: 10,
      paddingVertical: 10,
      paddingHorizontal: 12
    },
    appButtonText: {
      fontSize: 18,
      color: "#fff",
      fontWeight: "bold",
      alignSelf: "center",
      textTransform: "uppercase"
    },
    spaceEvenly: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    bodyText: {
      fontSize: 14,
      color: "grey"
    },
    greenHeader: {
      fontSize: 16,
      color: "green"
    },
    redHeader: {
      fontSize: 16,
      color: "red"
    },
    greyHeader: {
      fontSize: 16,
      color: "grey"
    },
    error: {
      color: 'red',
      fontSize: 16
    },
    activeModems: {
      justifyContent: 'center',
      flexDirection: 'row',
    },
    modemData: {
      justifyContent: 'space-around',
      alignItems: 'center',
      width: 90
    }
  });
  