import {View, Text, Image, Platform} from 'react-native'
import { Spacer } from '../atoms/Spacer'
import {FontAwesome} from '@expo/vector-icons'
import hardware from '../../config/hardware'
import { secondsToDateTime } from '../../Utilities'
import { colors } from '../../config/globalStyle'

export const OverviewSection = ({ serial, uptime, status, type }) => {
    return (
      <View style={{ justifyContent: 'center', padding: 0, width: '100%', alignItems: 'center', flex: 1 }}>
        <View style={{ flexDirection: Platform.OS == 'web' ? 'row' : Platform.isPad ? 'row' : 'column', alignItems: 'center' }}>
          <View style={[{ padding: 10, justifyContent: 'center', alignItems: 'center', flex: 1 }]}>
            <View style={{ width: '100%', borderWidth: 1, flexDirection: 'row', justifyContent: 'center', padding: 13, paddingHorizontal: 30, borderRadius: 10, borderColor: status ? colors.green : colors.red }}>
              <View style={{flex: 1, flexDirection: 'row'}}>
              <Image source={hardware[type].image} resizeMode="contain" style={{ maxHeight: 80, maxWidth: 80, width: 60, height: 60 }} />
              </View>
              <Spacer width={Platform.OS == "web" ? 40 : 10} />
              <View style={{ justifyContent: 'space-around', flex: 1, alignItems: 'center' }}>
                <Text style={{textAlign: 'center'}}>{hardware[type].fullDeviceName}</Text>
                <View style={{ flexDirection: 'row' }}>
                  <FontAwesome name="circle" size={16} color={status ? colors.green : colors.red} />
                  <Spacer width={5} />
                  <Text>{status ? "Active" : "Not Reachable"}</Text>
                </View>
              </View>
            </View>
          </View>
  
          <View style={[{ flexDirection: (Platform.isPad  || Platform.OS == "web")? 'column' : 'row', justifyContent: "space-around", flex: 1,}]}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ color: "#5e5e5e" }}>SN:</Text>
  
              <Text>{serial}</Text>
            </View>
            <Spacer width={21} />
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ color: "#5e5e5e" }}>UP:</Text>
  
              <Text>{secondsToDateTime(uptime / 100)}</Text>
            </View>
  
          </View>
        </View>
      </View>
    )
  }
  