import { HardwareStatusSettings } from "./HardwareStatus";
import { ActiveModemSettings } from "./ActiveModems";
import { DTCCSettings } from "./Dtcc";
import { TemperatureSettings } from "./Temperature";
import { FanSettings } from "./Fan";
import { SigQSettings } from "./upstreamSigQ";

const router =  {
    "Hardware Status": <HardwareStatusSettings/>,
    "Active Modems": <ActiveModemSettings/>,
    "DTCC / TCC+-T1 Status": <DTCCSettings/>,
    "Chassis Temperature": <TemperatureSettings/>,
    "Fan Status": <FanSettings/>,
    "Upstream Signal Quality MER & CNR": <SigQSettings/>
};

export default router