import { View, Text, TouchableOpacity } from "react-native";
import { Btn } from "../components/atoms/Btn";
import { TWLogo } from "../components/atoms/TelaWatchLogo";
import {Spacer} from '../components/atoms/Spacer'
import { useSelector } from "react-redux";
import { ReloadUser, SendVerificationEmail } from "../Firebase";
import { useState } from "react";
import { colors } from "../config/globalStyle";
import {FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons'

export function VerifyEmail({ navigation, setSignedIn }) {
    const user = useSelector((state) => state.user.user)
    const [message, setMessage] = useState("")
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <TouchableOpacity onPress={() => { navigation.navigate("Landing") }} style={{ position: 'absolute', top: 10, left: 10, padding: 7, flexDirection: 'row', alignItems: 'center', borderRadius: 7, borderWidth: 1, borderColor: '#eeeeee' }}>
                <FontAwesome name="chevron-left" size={15} color="rgba(0,0,0,0.5)" />
                <Spacer width={10} />
                <Text>Back</Text>
            </TouchableOpacity>
            <TWLogo size={30} style={{paddingBottom: 70}}/>

            <Text style={{fontSize: 20}}>Verify Email</Text>
            <Text style={{color: colors.grey}}>{user.email}</Text>

            <Spacer height={70}/>

            <Btn label="Resend Verification Email" onPress={async () => {
                await SendVerificationEmail().catch(err => {

                }).then(() => {

                })
            }}/>

            <Btn label="Continue" onPress={async () => {
                await ReloadUser()
                if (user.emailVerified) {
                    setSignedIn(true)
                } else {
                    console.log("Email not verified")
                }
            }} />
        </View>
    );
  }