import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"

import { Spacer } from "../../../atoms/Spacer"
import hardware from "../../../../config/hardware"
import { secondsToDateTime } from "../../../../Utilities"

import {FontAwesome} from '@expo/vector-icons'
import { colors } from "../../../../config/globalStyle"


export const HardwareStatusSection = ({ data, metrics,  }) => {
    return  <View style={{ justifyContent: 'center', padding: 0, width: '100%', alignItems: 'center', flex: 1 }}>
    <View style={{ flexDirection: Platform.isPad ? 'row' : 'column', alignItems: 'center', maxWidth: 500}}>
      <View style={[{ padding: 10, justifyContent: 'center', alignItems: 'center', flex: 1 }]}>
        <View style={{ borderWidth: 1, flexDirection: 'row', justifyContent: 'center', padding: 13, paddingHorizontal: 30, borderRadius: 10, borderColor: data.Active ? colors.green : colors.red }}>
          <Image source={hardware[data.Type].image} resizeMode="contain" style={{ maxHeight: 80, maxWidth: 80 }} />
          <Spacer width={10} />
          <View style={{ justifyContent: 'space-around' }}>
            <Text>{hardware[data.Type].fullDeviceName}</Text>
            <View style={{ flexDirection: 'row' }}>
              <FontAwesome name="circle" size={16} color={data.Active ? colors.green : colors.red} />
              <Spacer width={5} />
              <Text>{data.Active ? "Active" : "Not Reachable"}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={{ flex: 1, }}>
        <View style={[{ flexDirection: Platform.isPad ? 'column' : 'row', justifyContent: "space-around" }]}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ color: "#5e5e5e" }}>UP:</Text>

            <Text>{secondsToDateTime(metrics.uptime / 1000)}</Text>
          </View>
          <Spacer width={21} />
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ color: "#5e5e5e" }}>Boot Device:</Text>

            <Text>{metrics.systemInfo.boot}</Text>
          </View>

        </View>

        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', paddingTop: 5 }}>
          <Text style={{ color: "#5e5e5e" }}>Image:</Text>

          <Text>{metrics.systemInfo.image}</Text>
        </View>
        <Spacer width={21} />
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Text style={{ color: "#5e5e5e" }}>Bootloader:</Text>

          <Text>{metrics.systemInfo.bootloader}</Text>
        </View>
      </View>
    </View>
  </View>
}