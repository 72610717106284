import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, StyleSheet } from "react-native"
import { useState } from "react"
import { Spacer } from "../../../atoms/Spacer"
import {FontAwesome, Feather} from '@expo/vector-icons'

import { UpstreamNoiseGraph } from "../../../../screens/UpstreamNoiseGraph"
import { colors } from "../../../../config/globalStyle"

import { UpstreamName } from "../../../atoms/UpstreamName"

const removeSpectrum = (obj) => {
  let newObj = Object.assign({}, obj)
  if (Object.keys(obj).includes("spectrum")) { delete newObj.spectrum; return newObj}
  else return undefined
  
  }

const getSGModems = (obj) => {
  let newObj = Object.assign({}, obj)
  let sgs = []
  for (let c of Object.keys(newObj)) {
    for (let p of Object.keys(newObj[c])) {
      let rmspecObj = removeSpectrum(newObj[c][p])
      if (rmspecObj) {
      sgs.push({card: c, port: p, active: rmspecObj.activeModems, total: rmspecObj.totalModems, perc: Math.round((rmspecObj.activeModems / rmspecObj.totalModems) * 100) / 100} )
      }
      
    }
  }
  return sgs.filter(x => x)
  }
  
const UpstreamSpectrumRow = ({usPortNames, entry, lincrid, DisplayName, settings}) => {
  const [showDetail, setShowDetail] = useState(false)
  const [query, setQuery] = useState({ minX: 0, maxX: 55, minY: 0 })

  return <><View>
  
  <View elevation={3} style={{ shadowColor: 'black', shadowOpacity: 0.3, shadowOffset: {width: 0, height: -1}, shadowRadius: 3, backgroundColor: 'white', borderRadius: 7 }}>
    <TouchableOpacity style={{ flexDirection: 'row', flex: 1, padding: 10}} onPress={() => setShowDetail(!showDetail)}>
<UpstreamName name={usPortNames ? usPortNames[entry.card + "/" + entry.port] : ""} port={entry.card + "/" + entry.port} />
<Text style={{ flex: 1 }}>Port {entry.card}/{entry.port}</Text>
</TouchableOpacity>

    {showDetail ? <>
      <UpstreamNoiseGraph
        lincrID={lincrid}
        deviceName={DisplayName}
        port={entry.card + "/" + entry.port}
        minX={settings.Lower}
        maxX={settings.Upper}
        minY={0}
      />
      </>
      : <></>
    }
  </View>
 
  <View style={styles.triangleCorner} pointerEvents="none"></View>
  </View>
   <Spacer height={7}/>
   </>
}

export const SpectrumNoiseSection = ({data, metrics, lincrid, settings}) => {
  
  return  <View style={{paddingVertical: 7}}>
  {
    getSGModems(metrics.cardSummary).map(entry => {
      if (entry.port.includes("w") || entry.port.includes("undefined")) return <></>

      return <UpstreamSpectrumRow usPortNames={metrics.usPortNames} entry={entry} lincrid={lincrid} DisplayName={data.DisplayName} settings={settings.SpectrumNoise}/>
    })
  } 
  </View>
}

const styles = StyleSheet.create({
  triangleCorner: {
    width: 0,
   height: 0,
   backgroundColor: 'transparent',
   borderStyle: 'solid',
   borderTopWidth: 0,
   borderRightWidth: 0,
   borderBottomWidth: 15,
   borderLeftWidth: 15,
   borderTopColor: 'transparent',
   borderRightColor: 'transparent',
   borderBottomColor: colors.blue,
   borderLeftColor: 'transparent',
     position: 'absolute',
     right: 0,
     bottom: 0,
  },
})