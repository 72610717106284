import { initializeApp } from "firebase/app";
import { getAuth, updateProfile, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { getExpoPushTokenAsync, getDevicePushTokenAsync } from "expo-notifications";
import { doc, onSnapshot, getFirestore, updateDoc, arrayRemove, collectionGroup, query, where, addDoc, collection, setDoc } from "firebase/firestore";
import * as Crypto from 'expo-crypto';
import { Dimensions, Platform } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { initializeAuth, getReactNativePersistence } from 'firebase/auth/react-native';
import { navigate } from "./RootNavigation";
import {store} from './store'
import * as reducers from './redux_slices/reducers'
import hardware from "./config/hardware";
import axios from 'axios'
import { authErrors } from "./config/AuthErrors";
import * as Device from 'expo-device';
import packageJSON from '../package.json'
import { getDeviceId } from "./Utilities";

const firebaseConfig = {
    apiKey: "AIzaSyAITb-wpzPd7iEebqUUcHy2hl4O3ZEtWQs",
    authDomain: "telawatch.Firebase.com",
    projectId: "telawatch",
    storageBucket: "telawatch.appspot.com",
    messagingSenderId: "74298200554",
    appId: "1:74298200554:web:5ae051d3075a8e19666293",
    measurementId: "G-LVCELC18HD"
};
const baseUrl = "https://us-central1-telawatch.cloudfunctions.net/api";


const app = initializeApp(firebaseConfig);

const auth = initializeAuth(app, {
    persistence: getReactNativePersistence(AsyncStorage)
});

const db = getFirestore(app)


const signInUser = ({email, password}) => {
    return new Promise( async ( resolve, reject ) => { 
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            resolve(userCredential.user)
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          reject(error)
        });
    })
    
}

const signOutUser = () => {
   return signOut(auth)
}

const registerUserListener = () => {
    var userDocRef = doc(db, "Accounts", "Users", "UserList", auth.currentUser.uid)
    return onSnapshot(userDocRef, async (doc) => {
        if (doc.data()) {
          if (doc.data().LoginCounter) {
            await updateDoc(userDocRef, {
              LoginCounter: deleteField()
            })
          }

          if (!doc.data().twVer) {
            await updateDoc(userDocRef, {
              twVer: "2.0.0"
            })
          } else {
          }

          store.dispatch(reducers.user.setUser(Object.assign({}, doc.data(), auth.currentUser)))
        } else {
        }
      });
}

const registerLincrListener = () => {
    let siteCollection = collection(db, 'Lincrs')
    const qry = query(siteCollection, where('AuthUsers', "array-contains", auth.currentUser.uid))
      //const qry = query(siteCollection)

      var siteListener = onSnapshot(qry, (querySnapshot) => {
        let alldocs = {}
        querySnapshot.forEach((doc) => {
          let obj = JSON.parse(JSON.stringify(doc.data()))

          obj.lincrID = doc.id

          alldocs[doc.id] = obj

        })

        let formatDocs = alldocs



        ////console.log(formatDocs["256146748570"].C40G.cardSummary)
        let dataHead = Object.keys(formatDocs)
        ////console.log(formatDocs)
        dataHead.forEach(docid => {
          //if (docid == "256146748570") {
          //if (docid == "513856291607") {
          let docu = formatDocs[docid]
          if (!docu.DeviceList) {return;}
          docu.DeviceList = docu.DeviceList.map(device => {
            if (device == "Health Checks") return undefined
            if (device !== "OsInfo") {
              let deviceColor = hardware[device.Type].getColor(docu[device.DisplayName], auth.currentUser.uid, device)
              device.color = deviceColor
              return device
            } else {
              return device
            }
          }).filter(x => x)
          store.dispatch(reducers.sites.addSite(docu))
          //} else return docid
        })

        store.dispatch(reducers.sites.finishedLoading())



      })
      
      return siteListener
    
}

const getSiteHeader = async () => {
    const response = await fetch(baseUrl + "/sites", {
        method: "GET",
        mode: "cors",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth.currentUser.stsTokenManager.accessToken
        }
    });
    if (response.status === 400) return { message: "Invalid Lincr ID/password, please try again" };

    let res = await response.json()
    return res.sort((a,b) => a.name > b.name)

    
}

const updateUserProfile = async (displayName, email)=>{
    return new Promise( async ( resolve, reject ) => { 
        try {
        await updateDoc(doc(db, "Accounts", "Users", "UserList", auth.currentUser.uid), {DisplayName: displayName, Email: email})
        await updateProfile(auth.currentUser, {displayName}).catch((err) => {
            console.log("error updating profile", err)
        })
        
        resolve("updated")
    } catch (e) {
        console.log(e)
        resolve("updated")
    }
    })
   
}

const registerPushToken = async () => {
    console.log("Registering push token...")
    if (Platform.OS === "web") return;
    const expoToken = (await getExpoPushTokenAsync()).data;

    let userRef = doc(db, "Accounts", "Users", "UserList", auth.currentUser.uid)
    {
        // Update user doc + device info
    }
    let obj = {}
    let did = await getDeviceId()
    obj[`devices.${did}`] = {
        brand: Device.brand,
        deviceName: Device.deviceName,
        deviceYear: Device.deviceYearClass,
        manufacturer: Device.manufacturer,
        modelId: Device.modelId,
        modelName: Device.modelName,
        osName: Device.osName,
        totalMemory: Device.totalMemory,
        screenWidth: Dimensions.get('screen').width,
        screenHeight: Dimensions.get('screen').height,
        appVersion: packageJSON.version,
        timestamp: Date.now()
    }
    return updateDoc(userRef, Object.assign( {
        expoToken: expoToken,
        tokenUpdated: Date.now(),
    }, obj))
};

async function sha256(message) {
    return Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, message);
}

const addSite = async (lincrId, password) => {
    const encoded = await sha256(password);
    const idToken = auth.currentUser.stsTokenManager.accessToken
    const response = await fetch(baseUrl + "/addLincr", {
        method: "POST",
        mode: "cors",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + idToken
        },
        body: JSON.stringify({
            id: lincrId,
            pass: encoded
        })
    });
    if (response.status === 400) return { message: "Invalid Lincr ID/password, please try again" };

    let json;
    try {
        json = await response.json();
        return json;
    } catch (e) {
        return {};
    }
};


const removeSite = (lincrid, navigation) => {
    let siteRef = doc(db, "Lincrs", lincrid)
    updateDoc(siteRef, {
        AuthUsers: arrayRemove(auth.currentUser.uid)
    }).then(() => {
        navigate("Home")
    })
}

const UpdateSiteSettings = async ({setObj, id}) => {
    //console.log()
    const settingRef = doc(db, "Lincrs", id)
    await updateDoc(settingRef, setObj)
}

const getSiteTickets = ({lincrid}) => {
    //console.log(lincrid, uid)
    let userDocRef = doc(db, "Lincrs", lincrid, "Tickets", auth.currentUser.uid)
    return onSnapshot(userDocRef, (doc) => {
        if (doc.exists){
        store.dispatch(reducers.sites.setTickets(doc.data()))
        } else {
            //console.log("Doc doesnt exist")
        }
    })
}

const getSiteData = ({lincrid}) => {
    let userDocRef = doc(db, "Lincrs", lincrid)
    return onSnapshot(userDocRef, async (doc) => {
        if (doc.exists){
        store.dispatch(reducers.sites.setSite(Object.assign(doc.data(), {lincrID: doc.id})))
        store.dispatch(reducers.nav.setPageData({id: doc.id}))
        } else {
            //console.log("Doc doesnt exist")
        }
    })
}

const saveUSPortNames = async (lincrID, deviceName, port, newName, usNames={})=>{
    const nameRef = doc(db, "Lincrs", lincrID)
    let obj = {}
    let j = Object.assign({},usNames)
    j[port] = newName
    obj[`${deviceName}.usPortNames`] =  j

    await updateDoc(nameRef, obj)
}

const postFeedback = async (feedback) => {
    let token = await auth.currentUser.getIdToken()

    const response = await fetch(baseUrl + "/submitFeedback", {
        method: "POST",
        mode: "cors",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
        },
        body: JSON.stringify({
            feedback: feedback
        })
    });
    if (response.status == 200) console.log("Submitted Feedback")
}

const signUp = async (companyName, name, email, password) => {
    return new Promise(async (resolve, reject) => {

        try {
            let res = await axios.post(baseUrl + '/createUser', {
                email,
                pass: password,
                name
            }).catch((err) => {
                console.log(err)
            })
            if (res.status == 200) {
                console.log("User account created ")
                console.log("User Doc Set")
                await signInWithEmailAndPassword(auth, email, password)

                await sendEmailVerification(auth.currentUser).catch((err) =>
                    console.log(err)
                );
                console.log("Verification email sent")
                await updateProfile(auth.currentUser, { displayName: name }).catch(
                    (err) => console.log(err)
                );
                console.log("Profile Updated")

                await setDoc(doc(db, "Accounts", "Users", "UserList", auth.currentUser.uid),{
                    DisplayName: name,
                    Email: email,
                    CompanyName: companyName
                })

            } else {
                console.log(response)
            }

        } catch (err) {
            console.log("Error creating profile: ", err);
        }

        console.log("navigating")
        resolve()
    })

}

const DeleteAccount = async () => {
    let token = await auth.currentUser.getIdToken()
    const url = baseUrl + '/users'
    await axios.delete(url,{
        headers: {
            fbtoken: token
        }
    })
}

const ReloadUser = async () => {
    return auth.currentUser.reload()
}

const SendVerificationEmail = async () => {
    return new Promise( async ( resolve, reject ) => { 
        await sendEmailVerification(auth.currentUser).catch((err) =>
        reject(false)
        );
        resolve()
    })
}

const SendPasswordReset = async (email) => {
    return new Promise( async ( resolve, reject ) => { 
        try {
        await sendPasswordResetEmail(auth, email)
            console.log("Verify setn")
            resolve("Email Verification Sent! Please check your inbox to continue")
    } catch (err) {
        console.log(authErrors[err.code.split('/')[1]])
        resolve(authErrors[err.code.split('/')[1]])
    }
       
    })
}

export {auth, postFeedback, SendPasswordReset, SendVerificationEmail, ReloadUser, signInUser, signOutUser, getSiteHeader, updateUserProfile, addSite, removeSite, UpdateSiteSettings, getSiteTickets, getSiteData, saveUSPortNames, registerPushToken, signUp, registerLincrListener, DeleteAccount, registerUserListener}