import { View, Text, Switch as RNSwitch, StyleSheet } from 'react-native'
import { Switch } from 'react-native-paper';
import { Spacer } from './Spacer';
import { colors } from '../../config/globalStyle';

export default function ToggleLabel({ title, value, onValueChange, style, level }) {
    return (
        <View style={[styles.switch, style]} shadowOpacity={0.2} shadowColor={"black"} shadowOffset={{width: 0, height: 1}} elevation={3}>
            <View style={{backgroundColor: level == 2 ? 'red' : 'orange', height: 30, width: level ? 3 : 0}}></View>
            <Spacer width={5}/>
            <Text style={[styles.text]}>{title}</Text>
            <Switch onValueChange={onValueChange} value={value} color={colors.green} style={{ transform: [{ scaleX: .75 }, { scaleY: .75 }] }}/>
        </View>
    )
}

const styles = StyleSheet.create({
    switch: {
       flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 7,
        backgroundColor: 'white',
        margin: 5,
        borderRadius: 7
    },
    text:{
        flex: 1,
        fontSize: 14
    },
})
