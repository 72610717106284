import { View, Text, Button, Modal, TouchableOpacity, ScrollView, Platform } from "react-native";
import { useState, useEffect } from "react";
import { FontAwesome, Octicons, Entypo, Foundation, Ionicons } from '@expo/vector-icons'

import { getColorfromDF } from "../Utilities";
import { Dropdown } from "../components/atoms/Dropdown";
import { UpstreamNameModal } from "../components/atoms/UpstreamName";
import { Spacer } from "../components/atoms/Spacer";
import { SiteSettings } from "./SiteSettings";
import { SectionSettings } from "./SectionSettings";
import { AlarmLogPage } from "./AlarmPage";
import { LastUpdatedBottomView } from "../components/molecules/LastUpdated";
import {useSelector} from 'react-redux'
import {getSiteTickets, getSiteData} from '../Firebase'
import { useDispatch } from 'react-redux'
import * as reducers from '../redux_slices/reducers'

import {UBR10012Display} from '../components/Devices/UBR10012/UBR10012Display'
import { MikrotikDisplay } from "../components/Devices/Mikrotik/MikrotikDisplay"
import { C40GDisplay } from "../components/Devices/C40G/C40GDisplay";
import { UBR7246Display } from "../components/Devices/UBR7246VXR/UBR7246Display"
import { C100GDisplay } from "../components/Devices/C100G/C100GDisplay"
import { TC1000Display } from "../components/Devices/TC1000/TC1000Display"
import { RFGW1Display } from "../components/Devices/RFGW1/RFGW1Display"
import { LoadingIndicator } from "../components/atoms/LoadingIndicator";
import { DragSortCards } from "./DragSortCards";
import { colors } from "../config/globalStyle";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { HealthCheckDisplay } from "../components/Devices/Health Checks/HealthChecksDisplay";
import { LincrDisplay } from "../components/Devices/Lincr/LincrEnvDisplay";

import ws_logger from '../logger'
import { Btn } from "../components/atoms/Btn";


const DetailHeader = ({navigation, lincrid, connectivity, name, ticketCount=0, showSiteModal, showAlarms, selectDevice, devices, selectedDevice, disabledTickets}) => {
  const dispatch = useDispatch()

  return <View style={{ width: '100%', alignItems: 'center', paddingTop: Platform.OS == "web" ? 10 : 5, paddingHorizontal: 7,}}>
    <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', paddingBottom: 5 }}>
  
  <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', maxWidth: Platform.OS == "web" ? 400 : 200, marginTop: -3}}>
    <FontAwesome name="square" size={21} color={getColorfromDF(connectivity)} />
    <Spacer width={5} />
    <Text style={[{ padding: 5, textAlign: 'center', fontSize: 18 }]}>{name}</Text>
  </View>

  <TouchableOpacity onPress={() => { navigation.navigate('Home') }} style={{ padding: 0, position: 'absolute', left: 5 }}>
    <View style={{padding: 5}}>
    <Entypo name="chevron-thin-left" size={21} color="black" style={{ marginBottom: 9 }} />
    </View>
  </TouchableOpacity>

  <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 9, position: 'absolute', right: 40 }}>
    <TouchableOpacity onPress={() => {
      showAlarms(true)
      ws_logger.log("Open Modal", {name: "Alarm Log"})
    }} style={{ padding: 5 }} disabled={disabledTickets}>
      <Octicons name="history" size={21} color="black" style={{ marginBottom: 9 }} />
    </TouchableOpacity>
    <Text style={{ position: 'absolute', backgroundColor: 'white', left: 19, top: -3, paddingLeft: 3, color: ticketCount == 0 ? 'green' :'red' }}>{ticketCount}</Text>
  </View>

  <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 9, position: 'absolute', right: 9 }}>
    <TouchableOpacity onPress={() => {
      showSiteModal(true)
      ws_logger.log("Open Modal", {name: "Site Settings"})
    }} style={{ padding: 5 }}>
      <Foundation name="wrench" size={25} color="black" style={{ marginBottom: 9 }} />
    </TouchableOpacity>
  </View>
</View>
<View style={{width: '95%'}}>

<View style={{flexDirection: 'row', width:  Platform.OS == "web" ? '50%' : '100%', left: Platform.OS == "web" ? '25%' : 0}}>
<Dropdown items={devices} selected={selectedDevice} onItemSelect={(item) => {;selectDevice(item);  ws_logger.log("Device Change", {selectedDevice: item}) }} />
<Spacer width={7}/>
              <TouchableOpacity onPress={() => {
                dispatch(reducers.nav.showReorderModal(selectedDevice["Type"]))
                ws_logger.log("Open Modal", {name: "Reorder Tiles"})
              }} style={{width: 33, height: 35, alignItems: 'center', justifyContent: 'center', borderRadius: 5, borderWidth: 1, borderColor: '#cccccc'}}> 
              <Ionicons name="md-reorder-three" size={25} color="black"/>
              </TouchableOpacity>
              </View>
              </View>

</View>
}


const DisplayList = {
  "UBR10012": [
      "Hardware Status",
      "Active Modems",
      "DTCC / TCC+-T1 Status",
      "Chassis Temperature",
      "Fan Status",
      "Upstream Signal Quality MER & CNR",
      "Inventory"
  ],
  "C40G": [
    "Hardware Status",
    "Active Modems",
    "PEMS (Power Entry Modules)",
    "DS and US Port Utilization",
    "Analysis of Partial Service",
    "Upstream SNR by Service Group",
    "Burst Noise per US Channel",
    "Upstream Spectrum Noise",
    "Fans",
    "Module Summary",
    "Non-Bonding Modem Summary"
  ],
  "C100G": [
    "Hardware Status",
    "Active Modems",
    "PEMS (Power Entry Modules)",
    "DS and US Port Utilization",
    "Analysis of Partial Service",
    "Upstream SNR by Service Group",
    "Burst Noise per US Channel",
    "Upstream Spectrum Noise",
    "Fans",
    "Module Summary",
    "Non-Bonding Modem Summary"
  ],
  "C10G": [
    "Hardware Status",
    "Active Modems",
    "PEMS (Power Entry Modules)",
    "DS and US Port Utilization",
    "Analysis of Partial Service",
    "Upstream SNR by Service Group",
    "Burst Noise per US Channel",
    "Upstream Spectrum Noise",
    "Fans",
    "Module Summary",
    "Non-Bonding Modem Summary"
  ],
  "UBR7246VXR": [
    "Hardware Status",
    "Active Modems",
    "Chassis Temperature",
    "Fan Status",
    "Upstream Signal Quality MER & CNR",
    "Inventory"
],
  "Mikrotik": [
      "Hardware Status",
      "Temperature",
      "Device Info & Statistics",
      "Bandwidth in Mbps [5 min avg]"
  ],
  "TC1000": [
    "Hardware Status",
    "TOD Source"
  ],
  "RFGW1": [
    "GBE Input",
    "Hardware Status",
    "QAM Output",
    "Temperature",
    "Fan Status"
  ],
  "HealthCheck": [
    "Ping Panel"
  ],
  "Lincr": [
    "Status",
    "Usage",
    "Environment"
  ]
}


const InfoModel = ({info, dismiss}) => {
  switch (info) {
    case "MERCNR": 
    return  <View style={{padding: 10, maxWidth: '80%'}}>
    <Text>The Upstream Signal Quality CNR status colors are calculated based on your threshold</Text>
    <Spacer height={10}/>
    <View style={{flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, paddingBottom: 10}}>
      <Text style={{color: colors.green, width: 50}}>Green</Text>
      <Text>indicates CNR is good</Text>
    </View>
    <View style={{flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, paddingBottom: 10}}>
      <Text style={{color: colors.yellow, width: 50}}>Yellow</Text>
      <Text>indicates CNR warning</Text>
    </View>

    <View style={{flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10, paddingBottom: 10}}>
      <Text style={{color: colors.red, width: 50}}>Red</Text>
      <Text>indicates CNR is bad</Text>
    </View>

    <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10, paddingBottom: 10}}>
      <Text style={{color: colors.grey, width: 50}}>Grey</Text>
      <Text style={{flex: 1}}>indicates The CMTS is not returning a value for this port. for uBR10012 users, CNR information isn't supported by the PRE2, only the PRE4 & PRE5 engines</Text>
    </View>

    <Btn label={"Close"} color="grey" fillWidth={true} onPress={dismiss}/>
  </View>; break;
  case "pingtarget": return <View style={{padding: 10, width: '70%', marginRight: 5}}>
    <Spacer height={10}/>
     <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10, paddingBottom: 10}}>
      <Text style={{color: colors.green, width: 50}}>Green</Text>
      <Text style={{marginRight: 20}}>indicates target device has a good ping status</Text>
    </View>
    <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10, paddingBottom: 10}}>
      <Text style={{color: colors.yellow, width: 50}}>Yellow</Text>
      <Text style={{marginRight: 20}}>indicates a ping history with some dropped packets</Text>
    </View>

    <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10, paddingBottom: 10}}>
      <Text style={{color: colors.red, width: 50}}>Red</Text>
      <Text style={{marginRight: 20}}>indicates the target is unreachable by ping</Text>
    </View>

    <Btn label={"Close"} color="grey" fillWidth={true} onPress={dismiss}/>

  </View>
  case "pinghistory": return <View style={{padding: 10, width: '80%'}}>
  <Spacer height={10}/>
    <Text>The ping history for each target is presented from the right (newest) to the left (oldest)</Text>
    <Spacer height={10}/>
   <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10, paddingBottom: 10}}>
    <Text style={{color: colors.green, width: 50}}>Green</Text>
    <Text style={{marginRight: 20}}>4 of 4 pings were successfull</Text>
  </View>
  <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10, paddingBottom: 10}}>
    <Text style={{color: colors.yellow, width: 50}}>Yellow</Text>
    <Text style={{marginRight: 20}}>1-3 of 4 pings were successfull</Text>
  </View>

  <View style={{flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10, paddingBottom: 10}}>
    <Text style={{color: colors.red, width: 50}}>Red</Text>
    <Text style={{marginRight: 20}}>0 of 4 pings were successfull</Text>
  </View>

  <Btn label={"Close"} color="grey" fillWidth={true} onPress={dismiss}/>

</View>
case "ModuleSummary": return <View style={{padding: 10, width: '80%'}}>
<Spacer height={10}/>
  <Text><Text style={{fontWeight: 'bold'}}>ST</Text> shows the Status of each installed card.
The two normal states are "A" if
the card is Active and "S" if the card is in
Standby mode. Standby cards
are denoted with a blue coloring.{"\n\n"}
<Text style={{fontWeight: 'bold'}}>RED</Text> stands for Redundancy and if there is a
value, that denotes the
slot/card which is backing up and providing
redundancy for a given card.</Text>
<Spacer height={10}/>


<Btn label={"Close"} color="grey" fillWidth={true} onPress={dismiss}/>

</View>
    default: return <></>; break;
  }
}

export function DetailsScreen({route, navigation}) {
    const { lincrID, name, connectivity } = route.params;
    
    const [siteSettingsVisible, showSiteSettings] = useState(false)
    const [alarmsVisible, showAlarms] = useState(false)
    
    const user = useSelector((state) => state.user.user)
    const tickets = useSelector((state) => state.sites.tickets)
    const site = (useSelector((state) => state.sites.sites)).filter(s => s.lincrID == lincrID)[0]
    const devices = site?.DeviceList ?? [{}]
    const [selectedDevice, selectDevice] = useState(devices[0])
    const showSectionSettings = useSelector((state) => state.nav.showSectionSettings)
    const upstreamModalVisible = useSelector((state) => state.nav.upstreamModalVisible)
    const reorderModalVisible = useSelector((state) => state.nav.showReorder)
    const infoModelVisible = useSelector((state) => state.nav.showInfo)

    const [selectedOrder, setSelectedOrder] = useState(DisplayList[selectedDevice["Type"]])

    const dispatch = useDispatch()

    useEffect(() => {
      //console.log("attaching ticket listener")
      
      let unsubTickets = getSiteTickets({lincrid: lincrID})
      return function Cleanup() {
        //console.log("cleaning ticket listener")
        unsubTickets()
      }
    }, [])

    useEffect(() => {
      /*console.log("gettings Site Data")
      let unsubTickets = getSiteData({lincrid: lincrID})
      return function Cleanup() {
        //console.log("cleaning Site listener")
        unsubTickets()
      }*/
      //setSite(sites.filter(s => s.lincrID === id)[0])
      //console.log(sites.filter(site => site.lincrID === lincrID)[0])
    }, [])

    useEffect(() => {
      const updateSelectedDevice = async () => {
        console.log(selectedDevice)
      dispatch(reducers.sites.selectDevice(JSON.stringify(selectedDevice)))
      let allkeys = await AsyncStorage.getAllKeys()
      allkeys.map(async key => {
        let value = await AsyncStorage.getItem(key)
      })
      try {
        let storedOrder = await AsyncStorage.getItem(`${lincrID}-${selectedDevice.DisplayName}`)
        let order = JSON.parse(storedOrder)
        DisplayList[selectedDevice.Type].map((sectionName) => {
          if (order.includes(sectionName)) {
            return
          } else {
            return order.push(sectionName)
          }
        })
        setSelectedOrder()
      } catch (e) {
        setSelectedOrder(DisplayList[selectedDevice.Type])
      }
    }
    updateSelectedDevice()
    }, [selectedDevice])

    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Modal
          animationType="fade"
          transparent={true}
          visible={siteSettingsVisible}
          onRequestClose={() => {
            showSiteSettings(!siteSettingsVisible);
          }}>
            
            <SiteSettings name={name} dismiss={showSiteSettings} lincrid={lincrID} df={site ? site[user.uid] ? site[user.uid].DfAlarm : true : true} refresh={site ? site.Refresh : "300"} connectivity={connectivity} devices={devices}/> 
            {
              //df is user defined df setting on or off for site connectivity
            }
            </Modal>

            <Modal
          animationType="fade"
          transparent={true}
          visible={alarmsVisible}
          onRequestClose={() => {
            showAlarms(!alarmsVisible);
          }}>
<AlarmLogPage dismiss={showAlarms}/> 
          </Modal>

          <Modal
        animationType="fade"
        transparent={true}
        visible={showSectionSettings}
        onRequestClose={() => {
          dispatch(reducers.nav.setSectionSettings(false));
        }}
      >
        <SectionSettings device={selectedDevice}/>
      </Modal>

      <Modal 
      animationType="fade" 
      transparent={true} 
      visible={upstreamModalVisible !== null} 
      onRequestClose={() => {
        dispatch(reducers.nav.showNameModal(null))
      }}>
        <UpstreamNameModal lincrID={lincrID}/>
      </Modal>

      <Modal 
      animationType="fade" 
      transparent={true} 
      visible={reorderModalVisible !== null} 
      onRequestClose={() => {
        dispatch(reducers.nav.showReorderModal(null))
      }}>
        <>
        <View style={{backgroundColor: 'rgba(0,0,0,0.2)', flex: 1, justifyContent: 'center', alignItems: 'center'}} onStartShouldSetResponder={() => dispatch(reducers.nav.showReorderModal(null))}></View>
        <View style={{height: '100%', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center'}} pointerEvents={'box-none'}>
          <View style={{maxHeight: '60%', backgroundColor: 'white', borderWidth: 0,borderRadius: 10, shadowColor: 'black', shadowOffset: {width: 0, height: 1}, shadowOpacity: 0.3}}>
            <DragSortCards deviceType={selectedDevice["Type"]} order={selectedOrder ? selectedOrder : DisplayList[selectedDevice.Type]} onChange={async (tileOrder) => {
              setSelectedOrder(tileOrder)
              ws_logger.log("Reorder Tiles", {newOrder: tileOrder})
              await AsyncStorage.setItem(`${lincrID}-${selectedDevice.DisplayName}`, JSON.stringify(tileOrder)).catch((err) => {
                console.log(err)
              })
            }}/>
          </View>
        </View>
          </>
      </Modal>


      <Modal 
      animationType="fade" 
      transparent={true} 
      visible={infoModelVisible !== null} 
      onRequestClose={() => {
        dispatch(reducers.nav.setShowInfo(null))
      }}>
        <>
        <View style={{backgroundColor: 'rgba(0,0,0,0.2)', flex: 1, justifyContent: 'center', alignItems: 'center'}} onStartShouldSetResponder={() => dispatch(reducers.nav.setShowInfo(null))}></View>
        <View style={{height: '100%', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center'}} pointerEvents={'box-none'}>
          <View style={{maxHeight: '60%', backgroundColor: 'white', borderWidth: 0,borderRadius: 10, shadowColor: 'black', shadowOffset: {width: 0, height: 1}, shadowOpacity: 0.3}}>
            <InfoModel info={infoModelVisible} dismiss={() => dispatch(reducers.nav.setShowInfo(null))}/>
          </View>
        </View>
          </>
      </Modal>



      <DetailHeader lincrid={lincrID} name={name} navigation={navigation} connectivity={connectivity} showSiteModal={showSiteSettings} devices={[...devices, {
              Active: site?.Connectivity == 0,
              DisplayName: "Lincr",
              Type: "Lincr",
              color: getColorfromDF(site?.Connectivity)
            }]} showAlarms={showAlarms} selectDevice={selectDevice} selectedDevice={selectedDevice} ticketCount={tickets ? Object.keys(tickets).map(x => tickets[x].length).reduce((partialSum, a) => partialSum + a, 0): 0} disabledTickets={tickets == null}/>
            <View style={{ paddingHorizontal: 10, flex: 1, width: Platform.OS == "web" ? '75%' : '100%' }}>
              {site ? 
            <ScrollView style={{ flex: 1, paddingHorizontal: 10,}} contentOffset={{x: 0, y: 0}} showsVerticalScrollIndicator={false}>
            <Spacer height={5} />
            <Text style={{ width: '100%', textAlign: 'center', color: '#ababab', paddingVertical: 7, marginTop: -5 }}>Lincr ID: {lincrID.match(/.{1,3}/g).join(' ')}</Text>
            <Spacer height={5} />
            {
              site[selectedDevice.DisplayName] ? selectedDevice["Type"] == "UBR10012" ? <UBR10012Display metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrID={lincrID} selectedOrder={selectedOrder}/> : <></> : <></>
            }

            {
              site[selectedDevice.DisplayName] ? selectedDevice["Type"] == "UBR7246VXR" ? <UBR7246Display metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrID={lincrID} selectedOrder={selectedOrder}/> : <></> : <></>
            }

{
              site[selectedDevice.DisplayName] ? selectedDevice["Type"] == "C40G" ? <C40GDisplay metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrID={lincrID} selectedOrder={selectedOrder} lincrid={lincrID}/> : <></> : <></>
            }

{
              selectedDevice["Type"] == "C100G" ? <C100GDisplay metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrID={lincrID} selectedOrder={selectedOrder} lincrid={lincrID}/> : <></>
}

{
              selectedDevice["Type"] == "C10G" ? <C100GDisplay metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrID={lincrID} selectedOrder={selectedOrder} lincrid={lincrID}/> : <></>
}
            

            {
              site[selectedDevice.DisplayName] ? selectedDevice["Type"] == "TC1000" ? <TC1000Display metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrID={lincrID} selectedOrder={selectedOrder}/> : <></> : <></>
            }
            {
              site[selectedDevice.DisplayName] ? selectedDevice["Type"] == "RFGW1" ? <RFGW1Display metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrID={lincrID} selectedOrder={selectedOrder} sgs={
                devices.map(dvc => {
                  if  (dvc["Type"] == "UBR10012" ) {
                    let res = Object.assign({}, dvc)
                    let names = site[dvc.DisplayName]?.ModemsByCardNames || []
                    res.sgNames = names
                    delete res.Active
                    delete res.color
                    return res
                  } else return undefined
                }).filter(x => x)
              }/> : <></> : <></>
            }
            {
              site[selectedDevice.DisplayName] ? selectedDevice["Type"] == "Mikrotik" ? <MikrotikDisplay metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrid={lincrID} lincrID={lincrID} selectedOrder={selectedOrder}/> : <></> : <></>
            }

            {
              site[selectedDevice.DisplayName] ? selectedDevice["Type"] == "HealthCheck" ? <HealthCheckDisplay metrics={site[selectedDevice.DisplayName]} data={selectedDevice} lincrid={lincrID}     selectedOrder={selectedOrder}/> : <></> : <></>
            }

            {
              site.Environment ? selectedDevice["Type"] == "Lincr" ? <LincrDisplay metrics={Object.assign({}, site.Environment, {lincrversion: site.Version, df: site.Connectivity})} data={selectedDevice} lincrid={lincrID}     selectedOrder={selectedOrder}/> : <></> : <></>
            }
            
          
          </ScrollView> : <LoadingIndicator/>
            }
          <LastUpdatedBottomView lastUpdated={site ? site.lastUpdated : Date.now()} />
          </View>
            

      </View>
    );
  }
  