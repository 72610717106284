import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"

import { Spacer } from "../../../atoms/Spacer"
import { ActiveModems } from "../../../molecules/ActiveModems"
import { TapabbleFan } from "../../../atoms/TappableFan"
import {FontAwesome} from '@expo/vector-icons'
import { Btn } from "../../../atoms/Btn"
import { colors } from "../../../../config/globalStyle"

const removeSpectrum = (obj) => {
    let newObj = Object.assign({}, obj)
    if (Object.keys(obj).includes("spectrum")) { delete newObj.spectrum; return newObj}
    else return undefined
    
    }

const getSGModems = (obj) => {
    let newObj = Object.assign({}, obj)
    let sgs = []
    for (let c of Object.keys(newObj)) {
      for (let p of Object.keys(newObj[c])) {
        let rmspecObj = removeSpectrum(newObj[c][p])
        if (rmspecObj) {
        sgs.push({card: c, port: p, active: rmspecObj.activeModems, total: rmspecObj.totalModems, perc: Math.round((rmspecObj.activeModems / rmspecObj.totalModems) * 100) / 100} )
        }
        
      }
    }
    return sgs.filter(x => x)
    }

export const ActiveModemSection = ({cardSummary, showBreakdown, settings}) => {
    const perc = Math.round(100 * cardSummary.Total.activeModems / cardSummary.Total.totalModems) / 100
    return <View style={{ alignItems: 'center', paddingTop: 15 }}>
      <ActiveModems activeModems={cardSummary.Total.activeModems} totalModems={cardSummary.Total.totalModems} ringColor={perc < settings.TotalModemCount.Lower ? colors.red : perc < settings.TotalModemCount.Upper ? colors.orange : colors.green} />
  <Spacer height={7} />
    <Text style={{ fontSize: 16 }}>Service Group Summary</Text>
    <Spacer height={7} />
    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
      {
         getSGModems(cardSummary).map((card, i) => {
          return <>
          <FontAwesome name="circle" size={20} color={card.perc < settings.SGModemCount.Lower ? colors.red : card.perc < settings.SGModemCount.Upper ? colors.orange : colors.green} key={"SGC40" + i}/>
          <Spacer width={5} key={"SGC40" + i + 'spacer'}/>
          </>
        })

      }
    </View>
    <Spacer height={7} />
    <Btn label="Breakdown" onPress={() => { showBreakdown(true) }} />
  </View>
    
  }