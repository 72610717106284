import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"
import { useState } from "react"
import { Spacer } from "../../../atoms/Spacer"
import { colors } from "../../../../config/globalStyle"
import { UpstreamName } from "../../../atoms/UpstreamName"

export const NonBondingModemSummary =({data, names = [], settings}) => {
  return <View style={{paddingVertical: 7}}>
   <View style={{flexDirection: 'row', paddingBottom: 5}}>
            <Text style={{flex: 2, fontSize: 12}}>Service Group</Text>
            <Text style={{flex: 1, fontSize: 12}}>Total Modems</Text>
            <Text style={{flex: 1.5, fontSize: 12}}>Non-Bonding Modems</Text>
            <Text style={{flex: 0.5, fontSize: 12}}>Perc</Text>
          </View>
    {
      data.map((card, i) => {
        let perc = Math.round(100 * card.nonbondingModems / card.totalModems)
        let color = perc / 100 >= settings.NonBondingModems.Upper ? colors.red : perc / 100 >= settings.NonBondingModems.Lower ? colors.orange : colors.green
        return <View style={{flexDirection: 'row', alignItems: 'center', paddingVertical: 3, borderBottomWidth: 1, borderColor: colors.lightGrey}} key={card.card + "/" + card.port}>
            <UpstreamName style={{flex: 2}} name={names[card.card + "/" + card.port] ?? card.card + "/" + card.port} port={card.card + "/" + card.port} />
            <Text style={{flex: 0.9, paddingLeft: 10}}>{card.totalModems}</Text>
            <Text style={{flex: 1.5, padding: 3, backgroundColor: color, paddingLeft: 7, borderRadius: 7, overflow: 'hidden', marginRight: 3, textAlign: 'center'}}>{card.nonbondingModems}</Text>
            <Text style={{flex: 0.5, padding: 3, backgroundColor: color, borderRadius: 7, overflow: 'hidden', textAlign: 'center'}}>{perc}%</Text>
          </View>
      })
    }
  </View>
}