import { TemperatureSettings } from "./Temperature";
import { HardwareStatusSettings } from "./HardwareStatus";
import { BandwidthSettings } from "./Bandwidth";

const router =  {
    "Temperature": <TemperatureSettings/>,
    "Hardware Status": <HardwareStatusSettings/>,
    "Bandwidth in Mbps [5 min avg]": <BandwidthSettings/>
};

export default router