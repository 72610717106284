import { useState } from "react"
import { Text, View} from "react-native"

import ToggleLabel from "../../../atoms/ToggleLabel";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as reducers from '../../../../redux_slices/reducers'
import { UpdateSiteSettings } from "../../../../Firebase";

export const HardwareStatusSettings = () => {
    const sectionData = useSelector((state) => state.nav.sectionSettingData )

    const settingsSaved = useSelector((state) => state.nav.settingsSaved)
    const selectedDevice = useSelector((state) => state.sites.selectedDevice)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    const settings = sectionData.settings
    const [deviceReachable, setDeviceReachable] = useState(settings?.StateAlarm);
    const [uptimeReset, setUptimeReset] = useState(settings?.UptimeAlarm);

    useEffect(() => {
        if (settingsSaved){
            console.log("saving settings")
            dispatch(reducers.nav.setSectionSettings(false))
            dispatch(reducers.nav.saveSettings(false))
            //console.log("Saving rfgw Hardware Status Settings - ") 

            let obj = {}

            obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.StateAlarm`] = deviceReachable
             //obj[`${JSON.parse(selectedDevice).DisplayName}.${user.uid}.UptimeAlarm`] = uptimeReset
             //console.log(sectionData.lincrID, obj)
            if (Object.keys(obj).length > 0) UpdateSiteSettings({id: sectionData.lincrID, setObj: obj})
        }
    }, [settingsSaved])
    return (
        <View>
            <Text style={{fontSize: 16, paddingBottom: 5}}>Notify Me</Text>
            <ToggleLabel title="When this device is not reachable" value={deviceReachable} onValueChange={(val) => setDeviceReachable(val)} />
            {
            //<ToggleLabel title="When the uptime resets" value={uptimeReset} onValueChange={(val) => setUptimeReset(val)} />
            }
        </View>
    )
}