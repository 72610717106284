import { View, Text, Image, TouchableOpacity, Platform, Modal, Button, ScrollView } from "react-native"

import { Spacer } from "../../../atoms/Spacer"
import { colors } from "../../../../config/globalStyle"


const SinglePem = ({ pem }) => {
    let message = pem.present == "YES" ? pem.status : "Not Present"
    return (
        <View style={{ flex: 1, borderWidth: 1, borderRadius: 7, marginTop: 7, borderColor: message == "Good" ? colors.green : colors.red }}>
            <Text style={{ position: 'absolute', top: -9, left: 5, backgroundColor: 'white', paddingHorizontal: 5 }}>Pem {pem.psuNum == "1" ? "A" : "B"}</Text>
            <Text style={{ flex: 1, textAlign: 'center', paddingVertical: 7 }}>{message}</Text>
        </View>
    )
}


export const PEMSection = ({ pems }) => {

    return (
        <View style={{ flexDirection: 'row' }}>
            <SinglePem pem={pems[0]} />
            <Spacer width={15} />
            <SinglePem pem={pems[1]} />
        </View>
    )
}